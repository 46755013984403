import React from "react";
import { Card, Col, Row, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

const DashboardCard = ({ contacts, tasks, calls, deals, leads, projectId }) => {
  const projectContacts = contacts ? contacts.filter(contact => contact.project._id === projectId) : [];
  const projectTasks = tasks ? tasks.filter(task => task.project._id === projectId) : [];
  const projectCalls = calls ? calls.filter(call => call.project._id === projectId) : [];
  const projectDeals = deals ? deals.filter(deal => deal.project === projectId) : [];

   // Ensure the project field in leads is correctly matched with the projectId
   const projectLeads = leads ? leads.filter(lead => lead.project && lead.project._id === projectId) : [];

   console.log("Project Leads:", projectLeads); // Debugging line

  const totalContacts = projectContacts.length;
  const totalTasks = projectTasks.length;
  const totalCalls = projectCalls.length;
  const totalDeals = projectDeals.length;
  const totalLeads = projectLeads.length;

  const cardStyle = {
    border: "1px solid #b3cdf6",
    boxShadow: "2px 2px 5px  #b3cdf6",
    backgroundColor: "#ffffff",
  };

  const StatsTitleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#595959",
  };



  return (
    <>
      <Row gutter={[6, 6]}>
        <Col span={6}>
          <Card style={cardStyle}>
            <Statistic
              title="Total Contacts"
              value={totalContacts}
              precision={0}
              valueStyle={{ color: totalContacts < 5 ? "#cf1322" : "#3f8600" }}
              prefix={totalContacts < 5 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
              titleStyle={StatsTitleStyle}
            />
          </Card>
        </Col>

        <Col span={6}>
          <Card style={cardStyle}>
            <Statistic
              title="Total Tasks"
              value={totalTasks}
              precision={0}
              valueStyle={{ color: totalTasks < 5 ? "#cf1322" : "#3f8600" }}
              prefix={totalTasks < 5 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
              titleStyle={StatsTitleStyle}
            />
          </Card>
        </Col>
      </Row>

      <div className="mt-3 mb-3">
        <Row gutter={[6, 6]}>
          <Col span={6}>
            <Card style={cardStyle}>
              <Statistic
                title="Total Calls"
                value={totalCalls}
                precision={0}
                valueStyle={{ color: totalCalls < 5 ? "#cf1322" : "#3f8600" }}
                prefix={totalCalls < 5 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                titleStyle={StatsTitleStyle}
              />
            </Card>
          </Col>

          <Col span={6}>
            <Card style={cardStyle}>
              <Statistic
                title="Total Deals"
                value={totalDeals}
                precision={0}
                valueStyle={{ color: totalDeals < 5 ? "#cf1322" : "#3f8600" }}
                prefix={totalDeals < 5 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                titleStyle={StatsTitleStyle}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card style={cardStyle}>
              <Statistic
                title="Total Leads"
                value={totalLeads}
                precision={0}
                valueStyle={{ color: totalLeads < 5 ? "#cf1322" : "#3f8600" }}
                prefix={totalLeads < 5 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                titleStyle={StatsTitleStyle}
              />
            </Card>
          </Col>

          
        </Row>
      </div>
    </>
  );
};

export default DashboardCard;
 