import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgetPassword from "./pages/auth/ForgetPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Dashboard from "./pages/admin/Dashboard";
import Contact from "./pages/admin/Contact";
import Call from "./pages/admin/Call";
import Task from "./pages/admin/Task";
import ProtectedRoutes from "./components/protectedroutes/ProtectedRoutes";
import Account from "./pages/admin/Account";
import Deal from "./pages/admin/Deal";
import Product from "./pages/admin/Product";
import DealsDetails from "./pages/admin/DealsDetails";
import ContactDetails from "./pages/admin/ContactDetails";
import CallDetails from "./pages/admin/CallDetails";
import TaskDetails from "./pages/admin/TaskDetails";
import ProductDetails from "./pages/admin/ProductDetails";
import SyncBotsSetup from "./pages/admin/SyncBotsSetup";
import Lead from "./pages/admin/Lead";
import LeadDetails from "./pages/admin/LeadDetails";
import { useSelector } from "react-redux";
import Spinner from "./components/spinner/Spinner";
import Setting from "./pages/admin/Setting";
import FixedReviewBox from "./components/fixedReviewBox/FixedReviewBox";
import ErrorBoundary from "./utils/errorBoundary"



const App = () => {
  const { loading } = useSelector((state) => state.loaders);

  const saveMessages = (messages, HTMLString) => {
    localStorage.setItem("chat_messages", JSON.stringify(messages));
  };

  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem("chat_messages"));
    return messages;
  };

  const resetMessages = () => {
    localStorage.removeItem("chat_messages");
    window.location.reload();
  };

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);
  return (
    <Router>
       <ErrorBoundary>
      <>
        <Toaster position="top-right" />

        <FixedReviewBox />
      

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget/password" element={<ForgetPassword />} />
          <Route path="/reset/password/:token" element={<ResetPassword />} />

          <Route
            path="/dashboard/project/:projectId"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contact/project/:projectId"
            element={
              <ProtectedRoutes>
                <Contact />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/call/project/:projectId"
            element={
              <ProtectedRoutes>
                <Call />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/task/project/:projectId"
            element={
              <ProtectedRoutes>
                <Task />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/account"
            element={
              <ProtectedRoutes>
                <Account />
              </ProtectedRoutes>
            }
          />

       

          <Route
            path="/deal/project/:projectId"
            element={
              <ProtectedRoutes>
                <Deal />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/lead/project/:projectId"
            element={
              <ProtectedRoutes>
                <Lead />
              </ProtectedRoutes>
            }
          />

<Route
            path="/syncbot/project/:projectId"
            element={
              <ProtectedRoutes>
                <SyncBotsSetup />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/product/project/:projectId"
            element={
              <ProtectedRoutes>
                <Product />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deal/detail/:projectId/:dealId"
            element={
              <ProtectedRoutes>
                <DealsDetails />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contact/detail/:projectId/:contactId"
            element={
              <ProtectedRoutes>
                <ContactDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/call/detail/:projectId/:callId"
            element={
              <ProtectedRoutes>
                <CallDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/task/detail/:projectId/:taskId"
            element={
              <ProtectedRoutes>
                <TaskDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/lead/detail/:projectId/:leadId"
            element={
              <ProtectedRoutes>
                <LeadDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/product/detail/:projectId/:productId"
            element={
              <ProtectedRoutes>
                <ProductDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/setting/project/:projectId"
            element={
              <ProtectedRoutes>
                <Setting />
              </ProtectedRoutes>
            }
          />
        </Routes>
        {loading && <Spinner />}
       
        {/* <Footer /> */}
      </>
      </ErrorBoundary>
     
    </Router>
  );
};

export default App;
