import React, { useState, useEffect } from "react";
import { Drawer, Form, Button, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const SyncBotsDrawer = ({ visible, onClose, onSave, initialConfig, setInitialMessages }) => {
  const [config, setConfig] = useState(Array.isArray(initialConfig) ? initialConfig : []);
  const [initialMessage, setInitialMessage] = useState("");

  useEffect(() => {
    setConfig(Array.isArray(initialConfig) ? initialConfig : []);
  }, [initialConfig]);

  const handleAddWidget = () => {
    const newWidget = { type: "default", header: "", response: "text", config: {} };
    setConfig([...config, newWidget]);
  };

  const handleWidgetChange = (index, field, value) => {
    const updatedConfig = config.map((widget, i) =>
      i === index ? { ...widget, [field]: value, config: { ...widget.config, [field]: value } } : widget
    );
    setConfig(updatedConfig);
  };

  const handleSave = () => {
    onSave(config);
    setInitialMessages([{ content: initialMessage }]);
    onClose();
  };

  return (
    <Drawer
      title="Customize Chatbot"
      width={360}
      onClose={onClose}
      visible={visible}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} type="primary">
            Save
          </Button>
        </div>
      }
    >
      <Form.Item label="Initial Message">
        <Input
          value={initialMessage}
          onChange={(e) => setInitialMessage(e.target.value)}
        />
      </Form.Item>
      {config.map((widget, index) => (
        <div key={index} style={{ marginBottom: 16 }}>
          <Form.Item label={`Widget ${index + 1} Type`}>
            <Select
              value={widget.type}
              onChange={(value) => handleWidgetChange(index, "type", value)}
            >
              <Option value="default">Default</Option>
              <Option value="options">Options</Option>
              <Option value="name">Name</Option>
              <Option value="email">Email</Option>
              <Option value="phone">Phone</Option>
              <Option value="image">Image</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Header">
            <Input
              value={widget.header}
              onChange={(e) => handleWidgetChange(index, "header", e.target.value)}
            />
          </Form.Item>
          {widget.type === "options" && (
            <>
              <Form.Item label="Options">
                <Input
                  value={widget.config.options}
                  onChange={(e) => handleWidgetChange(index, "options", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Response Type">
                <Select
                  value={widget.response}
                  onChange={(value) => handleWidgetChange(index, "response", value)}
                >
                  <Option value="text">Text</Option>
                  <Option value="link">Link</Option>
                  <Option value="image">Image</Option>
                  <Option value="message">Message</Option>
                </Select>
              </Form.Item>
              {widget.response === "text" && (
                <Form.Item label="Text Response">
                  <Input
                    value={widget.config.text}
                    onChange={(e) => handleWidgetChange(index, "text", e.target.value)}
                  />
                </Form.Item>
              )}
              {widget.response === "link" && (
                <Form.Item label="Link URL">
                  <Input
                    value={widget.config.link}
                    onChange={(e) => handleWidgetChange(index, "link", e.target.value)}
                  />
                </Form.Item>
              )}
              {widget.response === "image" && (
                <Form.Item label="Image URL">
                  <Input
                    value={widget.config.image}
                    onChange={(e) => handleWidgetChange(index, "image", e.target.value)}
                  />
                </Form.Item>
              )}
              {widget.response === "message" && (
                <Form.Item label="Message">
                  <Input
                    value={widget.config.message}
                    onChange={(e) => handleWidgetChange(index, "message", e.target.value)}
                  />
                </Form.Item>
              )}
            </>
          )}
          {(widget.type === "name" || widget.type === "email" || widget.type === "phone") && (
            <Form.Item label="Input Label">
              <Input
                value={widget.config.label}
                onChange={(e) => handleWidgetChange(index, "label", e.target.value)}
              />
            </Form.Item>
          )}
          {widget.type === "image" && (
            <Form.Item label="Image URL">
              <Input
                value={widget.config.url}
                onChange={(e) => handleWidgetChange(index, "url", e.target.value)}
              />
            </Form.Item>
          )}
        </div>
      ))}
      <Button type="dashed" onClick={handleAddWidget} style={{ width: "100%" }}>
        <PlusOutlined /> Add Widget
      </Button>
    </Drawer>
  );
};

export default SyncBotsDrawer;
