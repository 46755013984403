import { apiRequest } from ".";

export const CreateDeal = async (deal) =>
  apiRequest("post", "/api/deals/create-deal", deal);

export const GetAllDeals = async (filters) =>
  apiRequest("post", "/api/deals/get-all-deals", filters);

export const GetDealById = async (dealId) =>
  apiRequest("get", `/api/deals/get-deal-by-id/${dealId}`);

  export const UpdateDeal = async (dealId, deal) =>
  apiRequest("put", `/api/deals/update-deal/${dealId}`, deal);

export const DeleteDeal = async (id) =>
  apiRequest("post", "/api/deals/delete-deal", { _id: id });

  export const AddDealComment = async (comment) =>
  apiRequest("post", "/api/deals/add-comment", comment);

export const GetCommentsByDealId = async (projectId,dealId) =>
  apiRequest("get", `/api/deals/get-comments/${projectId}/${dealId}`);

  export const DeleteDealImage = async (dealId, imageUrl) =>
  apiRequest("post", "/api/deals/delete-image", { dealId, imageUrl });

   