import { apiRequest } from ".";

export const CreateTask = async (task) =>
  apiRequest("post", "/api/tasks/create-task", task);

export const GetAllTasks = async (filters) =>
  apiRequest("post", "/api/tasks/get-all-tasks", filters);

export const GetTaskById = async (taskId) =>
  apiRequest("get", `/api/tasks/get-task-by-id/${taskId}`);

export const UpdateTask = async (task) =>
  apiRequest("post", "/api/tasks/update-task", task);

export const DeleteTask = async (id) =>
  apiRequest("post", "/api/tasks/delete-task", { _id: id });

export const DeleteImage = async (taskId, imageUrl) =>
  apiRequest("post", "/api/tasks/delete-image", { taskId, imageUrl });


  export const AddComment = async (comment) =>
  apiRequest("post", "/api/tasks/add-comment", comment);

export const GetCommentsByTaskId = async (projectId,taskId) =>
  apiRequest("get", `/api/tasks/get-comments/${projectId}/${taskId}`);

  