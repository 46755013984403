import { apiRequest } from ".";

export const CreateProduct = async (product) =>
  apiRequest("post", "/api/products/create-product", product);

export const GetAllProducts = async (filters) =>
  apiRequest("post", "/api/products/get-all-products", filters);

export const GetProductById = async (productId) =>
  apiRequest("get", `/api/products/get-product-by-id/${productId}`);

  export const UpdateProduct = async (productId, product) =>
  apiRequest("put", `/api/products/update-product/${productId}`, product);

export const DeleteProduct = async (id) =>
  apiRequest("post", "/api/products/delete-product", { _id: id });

  export const AddProductComment = async (comment) =>
  apiRequest("post", "/api/products/add-comment", comment);

export const GetCommentsByProductId = async (projectId,productId) =>
  apiRequest("get", `/api/products/get-comments/${projectId}/${productId}`);

  export const DeleteProductImage = async (productId, imageUrl) =>
  apiRequest("post", "/api/products/delete-image", { productId, imageUrl });

   