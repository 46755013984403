import React, { useEffect } from "react";
import "./auth.css";
import { NavLink, useNavigate } from "react-router-dom";
import { message, Form, Input, Button } from "antd";
import toast from "react-hot-toast";
import { LoginUser } from "../../apicalls/users";
import { useDispatch, useSelector } from "react-redux";
import { setButtonLoading } from "../../redux/loadersSlice";
import { getAntdFormInputRules } from "../../utils/helpers";

const Login = () => {
  const { buttonLoading } = useSelector((state) => state.loaders);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));
      const response = await LoginUser(values);
      dispatch(setButtonLoading(false));
      if (response.success) {
        localStorage.setItem("token", response.data);
        toast.success(response.message);
        navigate("/account");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/account");
    }
  }, []);

  return (
    <div>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="ath-header-text mb-3">Welcome back!!🎉</h1>
            <p className="col-lg-10 ath-desc-text">
              Login to access your account.
              <br />
              Your team is waiting for you.
            </p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <Form
              className="p-4 p-md-5 border rounded-3 bg-body-transparent bg-transparent shadow"
              onFinish={onFinish}
            >
              <div className="form-floating mb-3">
                <Form.Item
                  name="email"
                  label="Email address"
                  rules={getAntdFormInputRules}
                >
                  <Input placeholder="name@example.com" />
                </Form.Item>
              </div>
              <div className="form-floating mb-3">
                <Form.Item
                  name="password"
                  label="Password"
                  rules={getAntdFormInputRules}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </div>
              <div className="d-flex justify-content-center ">
                <Button
                  type="primary"
                  className="btn-lg ath-auth-button shadow"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  {buttonLoading ? "loading" : "Login"}
                </Button>
              </div>
              <hr className="my-4" />
              <div className="text-center mx-auto">
                <small className="text-body-secondary">
                  <span>
                    <NavLink to="/forget/password">Forget Password</NavLink>
                  </span>
                </small>
              </div>
              <div className="text-center mx-auto">
                <small className="text-body-secondary">
                  Not Registered?{" "}
                  <span>
                    <NavLink to="/register">Register</NavLink>
                  </span>
                </small>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
