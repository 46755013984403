import React, { useEffect } from "react";
import { Badge, Button, Card, Col, Divider, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/loadersSlice";
import { getDateFormat } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { GetProjectsByRole } from "../../apicalls/projects";
import toast from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import "./usercomponents.css";

const AccountCard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [projects, setProjects] = React.useState([]);

  // Define breakpoints
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMdOrLarger = useMediaQuery({ minWidth: 769 });

  const getData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetProjectsByRole();
      dispatch(setLoading(false));
      if (response.success) {
        setProjects(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleNav = (projectId) => {
    navigate(`/dashboard/project/${projectId}`);
  };

  const getColSpan = () => {
    if (isXs) return 24; // 1 card per row on small screens
    return 12; // 2 cards per row on medium and larger screens
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {projects.map((project) => (
          <Col span={getColSpan()} key={project._id}>
            <Card className="card shadow border-warning" style={{ width: "100%" }}>
              <h5 className="card-title">{project.name}</h5>
              <div className="card-body">
                <Row className="mb-3">
                  <Col span={24}>
                    <p className="mb-0">
                      <strong>Created At:</strong> {getDateFormat(project.createdAt)}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col span={24}>
                    <p className="mb-0">
                      <strong>Project Owner:</strong> {project.owner.firstName} {project.owner.lastName}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col span={24}>
                    <p className="mb-0">
                      <strong>Company Phone Number:</strong> {project.phone}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col span={24}>
                    <p className="mb-0">
                      <strong>Status:</strong> 
                      <Badge
                        style={{ backgroundColor: "#52c41a" }}
                        className="site-badge-count-109"
                        count={project?.status} // Pass the status as the count prop
                      />
                    </p>
                  </Col>
                </Row>
                <Divider />
                <Row justify="end">
                  <Col>
                    <Button
                    className="save-button-ath-color"
                      size="small"
                      onClick={() => handleNav(project._id)}
                    >
                      Access Project
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <div>
        {projects.length === 0 && (
          <div className="flex text-center">
            <h1 className="text-primary text-xl">You have no projects yet</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountCard;
