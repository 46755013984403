// Import necessary components and functions
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Popconfirm, Space, Table as AntTable, Popover } from "antd";
import MembersForm from "./MembersForm.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice.js";
import {
  RemoveMemberFromProject,
  GetProjectById,
} from "../../apicalls/projects";
import toast from "react-hot-toast";
import {
  DeleteOutlined,
  FilterOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import UserDetailsCard from "./UserDetailsCard.jsx";
import { useMediaQuery } from "react-responsive";

// Define MembersList component
const MembersList = ({ project, reloadData }) => {
  // Define necessary states and variables
  const { user } = useSelector((state) => state.users);
  const [currentUserRole, setCurrentUserRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [role, setRole] = React.useState("");
  const dispatch = useDispatch();
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const { projectId } = useParams();
  const [totalMembers, setTotalMembers] = useState(0); // State to hold total members count

  const isOwner = project && project.owner && project.owner._id === user._id;
  const isAdmin = project && project.members && project.members.some(
    (member) => member.role === "Admin" && member.user._id === user._id
  );

  // Function to delete a member
  const deleteMember = async (memberId) => {
    try {
      dispatch(setLoading(true));
      const response = await RemoveMemberFromProject({
        projectId: project._id,
        memberId,
      });
      if (response.success) {
        reloadData();
        toast.success(response.message);
      } else {
        throw new Error(response.message);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  // Effect hook to fetch project data
  // Fetch project data using projectId
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const response = await GetProjectById(projectId); // Use projectId directly
        if (response.success) {
          const currentUser = response.data.members.find(
            (member) => member.user._id === user._id
          );
          setCurrentUserRole(currentUser.role);
          setTotalMembers(response.data.members.length); // Set total members count
        } else {
          throw new Error(response.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, user, projectId]);

  // Define table columns
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      ellipsis: true,
      render: (text, record) => record.user.firstName,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      ellipsis: true,
      render: (text, record) => record.user.lastName,
    },
    {
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
      render: (text, record) => record.user.email,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      ellipsis: true,
      render: (text, record) => record.user.phoneNumber,
    },
    {
      title: "Role",
      dataIndex: "role",
      ellipsis: true,
      render: (text, record) => record.role.toUpperCase(),
    },
    {
      title: "Delete Member",
      key: "action",
      render: (text, record) => (
        <Popconfirm
          title="Remove member"
          description="Are you sure to remove member?"
          onConfirm={() => deleteMember(record._id)}
          okText="Yes"
          cancelText="No"
        >
          <Popover title="Remove Member">
            <Button
              type="default"
              style={getButtonStyle()}
              danger
              icon={<DeleteOutlined />}
            />
          </Popover>
        </Popconfirm>
      ),
    },
  ];

  // Remove action column if not an owner or admin
  if (!isOwner && !isAdmin) {
    columns.pop();
  }

  // Define row selection
  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  // Define breakpoints
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px" };
    }
  };

  // Render component
  return (
    <div>
      {/* Buttons and Controls */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Members Page</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          {(isOwner || isAdmin) && ( // Check if owner or admin
            <Popover
              title="Add Members"
              content={
                totalMembers >= 10 ? ( // Check if total members reached limit
                  <span>
                    You cannot add more members. Please upgrade your plan.
                  </span>
                ) : (
                  <Button
                    type="default"
                    style={getButtonStyle()}
                    onClick={handleShowModal}
                    icon={<PlusOutlined />}
                  >
                    Add Members
                  </Button>
                )
              }
              trigger="click"
              placement="bottom"
              disabled={totalMembers >= 10}
            >
              <Button
                type="default"
                style={getButtonStyle()}
                icon={<PlusOutlined />}
              >
                Add Members
              </Button>
            </Popover>
          )}
          <Popover title="Filter members">
            <Button
              type="default"
              style={getButtonStyle()}
              icon={<FilterOutlined />}
            />
          </Popover>
          <div className="btn-group me-3">
            <Popover title="Share Report">
              <Button
                type="default"
                style={getAdditionalButtonStyle()}
                icon={<ShareAltOutlined />}
                disabled
              />
            </Popover>
          </div>
        </div>
      </div>

      {/* Members Form */}
      <MembersForm
        showModal={showModal}
        handleClose={handleCloseModal}
        reloadData={reloadData}
        project={project}
        setRole={setRole} // Pass setRole as a prop
      />

      {/* User Details Card */}
      {project && (
        <UserDetailsCard project={project} currentUserRole={currentUserRole} />
      )}

      {/* Team Members Table */}
      <h2 className="mt-5">Team Members</h2>
      <div className="table-responsive">
        {project && project.members ? (
          <AntTable
            columns={columns}
            dataSource={project.members.map((member, index) => ({
              ...member,
              key: index.toString(),
            }))}
            rowSelection={rowSelection}
          />
        ) : (
          <>
            {project.members.length === 0 && (
              <div className="flex text-center">
                <h1 className="text-primary text-xl">
                  You have no team members yet
                </h1>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

// Export MembersList component
export default MembersList;
