import React, { useEffect, useState } from "react";
import "./admin.css";
import Sidebar from "../../components/user/Sidebar";
import DashboardCard from "../../components/user/DashboardCard";
import DataChart from "../../components/user/DataChart";
import { useParams } from "react-router-dom";
import { GetAllContacts } from "../../apicalls/contact";
import { GetAllTasks } from "../../apicalls/task";
import { GetAllLeads } from "../../apicalls/lead";
import { GetAllCalls } from "../../apicalls/call";
import { GetAllDeals } from "../../apicalls/deal";
import { useMediaQuery } from "react-responsive";
import { FilterOutlined, ShareAltOutlined, ToolOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";

const Dashboard = () => {
  const [contacts, setContacts] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [calls, setCalls] = useState([]);
  const [leads, setLeads] = useState([]);
  const [deals, setDeals] = useState([]);
  const { projectId } = useParams();

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await GetAllContacts({ project: projectId });
        if (response.success) {
          setContacts(response.data);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, [projectId]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await GetAllTasks({ project: projectId });
        if (response.success) {
          setTasks(response.data);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, [projectId]);

  useEffect(() => {
    const fetchCalls = async () => {
      try {
        const response = await GetAllCalls({ project: projectId });
        if (response.success) {
          setCalls(response.data);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching calls:", error);
      }
    };

    fetchCalls();
  }, [projectId]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await GetAllLeads({ project: projectId });
        if (response.success) {
          console.log("Fetched leads:", response.data); // Debugging line
          setLeads(response.data);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchLeads();
  }, [projectId]);


  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await GetAllDeals({ project: projectId });
        if (response.success) {
          setDeals(response.data);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchDeals();
  }, [projectId]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px" };
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
          <Sidebar projectId={projectId} />
        </div>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Dashboard</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group me-2">
                <Popover title="Filter Products">
                  <Button
                    className="topbar-button-ath-color"
                    style={getButtonStyle()}
                    icon={<FilterOutlined />}
                    disabled
                  />
                </Popover>
                <Popover title="Share Report">
                  <Button
                    className="topbar-button-ath-color"
                    style={getAdditionalButtonStyle()}
                    icon={<ShareAltOutlined />}
                    disabled
                  />
                </Popover>
              </div>
              <Popover title="Calls Settings">
                <Button
                  className="topbar-button-ath-color"
                  style={getAdditionalButtonStyle()}
                  icon={<ToolOutlined />}
                  disabled
                />
              </Popover>
            </div>
          </div>

          <div className="mb-5 mt-5">
            <DashboardCard
              contacts={contacts}
              tasks={tasks}
              calls={calls}
              deals={deals}
              leads={leads}
              projectId={projectId}
            />
          </div>

          <div className="mb-5 mt-5">
            <DataChart />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
 