import React from "react";
import Navbar from "../../components/navbar/Navbar";
import { NavLink } from "react-router-dom";
import "./home.css";
import Hero from "../../components/hero/Hero";
import Features from "../../components/features/Features";
// import Integrations from "../../components/integrations/Integrations";
import Footer from "../../components/footer/Footer";
import Reviews from "../../components/reviews/Reviews";

const Home = () => {
  return (
    <>
      <Navbar />

      <Hero />

      <Features />

      <Reviews />

      {/* <Integrations /> */}

      <Footer />
    </>
  );
};

export default Home;
