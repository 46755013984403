import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Drawer,
  Row,
  Col,
  Card,
  Popover,
  InputNumber,
  DatePicker,
} from "antd";
import { DeleteOutlined, EyeOutlined, SaveOutlined } from "@ant-design/icons";
import { setLoading,setButtonLoading  } from "../../redux/loadersSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { GetProjectById } from "../../apicalls/projects";
import {
  GetAllDiscounts,
  CreateDiscount,
  DeleteDiscount,
} from "../../apicalls/discount";
import "./adddiscountdrawer.css";
import moment from "moment";
import { getDateFormat } from "../../utils/helpers";

const AddDiscountDrawer = ({ open, onClose, initialDiscount, reloadData }) => {
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users) || {};
  const { buttonLoading } = useSelector((state) => state.loaders);
  const [project, setProject] = useState(null);
  const [discounts, setDiscounts] = useState([]);
  const [projectMembers, setProjectMembers] = useState(null);

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  useEffect(() => {
    if (initialDiscount) {
      form.setFieldsValue(initialDiscount);
    }
  }, [initialDiscount, form]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
          setProjectMembers(projectResponse.data.members);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getDiscounts();
    }
  }, [project]);

  const getDiscounts = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetAllDiscounts({
        project: project?._id,
      });
      dispatch(setLoading(false));
      if (response.success) {
        setDiscounts(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const onFinish = async (values) => {
    try {
      console.log("Form values:", values);
      dispatch(setButtonLoading(true));

      const { discountCode, discountPercentage, expiryDate } = values;

      let discountId = null;
      if (initialDiscount) {
        discountId = initialDiscount._id;
      }

      const data = {
        project: project._id,
        discountCode,
        discountPercentage,
        expiryDate,
        createdBy: user._id,
        updatedBy: user._id,
        discountId,
      };

      const response = await CreateDiscount(data);
      console.log("Response:", response); 

      if (response.success) {
        form.resetFields(); // Clear the form input
        getDiscounts(); // Refresh the discounts list
        toast.success(response.message);
        showChildrenDrawer();
      } else {
        toast.error(response.message); // Display the error message from the response
      }

      dispatch(setButtonLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(setButtonLoading(false));

      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message); // Display the specific error message from the backend
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const confirmDelete = async (id) => {
    try {
      const response = await DeleteDiscount(id);
      if (response.success) {
        toast.success(response.message);
        // Refresh the discounts list after deletion
        getDiscounts();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Drawer
        title="Create Discount"
        className="drawer-display shadow-warning"
        width={520}
        closable={true}
        onClose={onClose}
        open={open}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Popover title={buttonLoading ? "loading" : "Save Category"}>
              <Button
                key="submit"
                onClick={() => form.submit()}
                icon={<SaveOutlined />}
                loading={buttonLoading}
              />
            </Popover>
            <Popover title="View all discounts">
              <Button
                type="primary"
                onClick={showChildrenDrawer}
                icon={<EyeOutlined />}
              />
            </Popover>
          </div>
        }
      >
        <Form form={form} onFinish={onFinish} className="form-centered">
          <Row justify="center" align="middle" className="form-container">
            <Col span={24}>
              <Form.Item
                label={<span className="form-label">Discount code</span>}
                name="discountCode"
                className="form-item-centered"
                rules={[
                  {
                    required: true,
                    message: "Please enter a unique discount code!",
                  },
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={<span className="form-label">Discount percentage</span>}
                name="discountPercentage"
                className="form-item-centered"
                rules={[
                  {
                    required: true,
                    message: "Please set discount percentage!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={<span className="form-label">Expiring Date</span>}
                name="expiryDate"
                className="form-item-centered"
                rules={[
                  {
                    required: true,
                    message: "Please set discount expiring date!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Drawer
          title="All Discounts"
          width={320}
          closable={true}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
        >
          <Row gutter={[16, 16]}>
            {discounts.length > 0 ? (
              discounts.map((discount) => (
                <Col span={24} key={discount._id}>
                  <Card
                    className="shadow border-warning"
                    actions={[
                      <DeleteOutlined
                        key="delete"
                        onClick={() => confirmDelete(discount._id)}
                      />,
                    ]}
                  >
                    <Card.Meta
                      title={<h3>Code: {discount.discountCode}</h3>}
                      description={
                        <>
                          <p>
                            Expiring Date: {getDateFormat(discount.expiryDate)}
                          </p>
                          <p>Percentage: {discount.discountPercentage} % </p>
                          <p>
                            Created by:{" "}
                            {discount.createdBy?.firstName || "Unknown"}{" "}
                            {discount.createdBy?.lastName || "Unknown"}
                          </p>
                          <p>
                            Created on:{" "}
                            {moment(discount.createdAt).format(
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </p>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))
            ) : (
              <div className="flex text-center">
                <h1 className="text-primary text-xl">
                  You have no discounts
                </h1>
              </div>
            )}
          </Row>
        </Drawer>
      </Drawer>
    </>
  );
};

export default AddDiscountDrawer;
