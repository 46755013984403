import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/user/Sidebar";
import ProjectSettingTab from "../../components/user/ProjectSettingTab";
import ProjectList from "../../components/user/ProjectList";
import MembersList from "../../components/user/MembersList";
import { useDispatch, useSelector } from "react-redux";
import { GetProjectById } from "../../apicalls/projects"; // Import GetProjectById
import { setLoading } from "../../redux/loadersSlice";

const Setting = () => {
  const { projectId } = useParams();
  const [activeTab, setActiveTab] = useState("projectsList");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.users.user,
  }));
  const [project, setProject] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const response = await GetProjectById(projectId);
        // console.log('Response:', response);
        if (response.success && response.data && response.data.members) {
          setProject(response.data);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  if (!user || !user._id) {
    return <div>Loading...</div>;
  }

  const reloadData = async () => {
    // Implement your data reload logic here
    // For example:
    // Refetch the project data
    const response = await GetProjectById(projectId);
    if (response.success) {
      setProject(response.data);
    } else {
      console.error("Error reloading data:", response.message);
    }
  };

  return (
    project && (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <Sidebar projectId={projectId} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <ProjectSettingTab
              activeTab={activeTab}
              onChange={handleTabChange}
            />
            {activeTab === "projectsList" ? (
              <>
                <ProjectList project={project} />
              </>
            ) : (
              <MembersList project={project} reloadData={reloadData} />
            )}
          </main>
        </div>
      </div>
    )
  );
};

export default Setting;
