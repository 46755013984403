const { apiRequest } = require(".");

export const AddNotification = async (notification) => apiRequest("post", "/api/notifications/add-notification", notification);

export const GetAllNotifications = async () => apiRequest("get", "/api/notifications/get-all-notifications");

export const MarkAllNotificationsAsRead = async () => apiRequest("post", "/api/notifications/mark-as-read");

export const MarkNotificationAsRead = async (id) => apiRequest("post", `/api/notifications/mark-as-read/${id}`);

export const DeleteNotificationById = async (id) => apiRequest("delete", `/api/notifications/delete-notification/${id}`);

export const DeleteAllNotifications = async () => apiRequest("delete", "/api/notifications/delete-all-notifications");
