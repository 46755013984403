import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Space, 
  Row,
  Col,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetProjectById } from "../../apicalls/projects";
import { CreateLead } from "../../apicalls/lead";
import { setButtonLoading, setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import { AddNotification } from "../../apicalls/notifications";

const { Option } = Select;

const AddLeadModal = ({ showModal, handleClose, reloadData, initialLead }) => {
  const [form] = Form.useForm();
  const { buttonLoading } = useSelector((state) => state.loaders);
  const [projectMembers, setProjectMembers] = useState(null);
  const [assignedToId, setAssignedToId] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users) || {};
  const [project, setProject] = useState(null);
  const { projectId, leadId } = useParams();

  useEffect(() => {}, [initialLead]);

  const validateAssignedTo = () => {
    if (projectMembers && assignedToId) {
      return projectMembers.some(
        (member) =>
          member.user._id === assignedToId &&
          (member.role === "owner" ||
            member.role === "Admin" ||
            member.role === "Employee" ||
            member.role === "Finance" ||
            member.role === "Credit recorvery" ||
            member.role === "Projects" ||
            member.role === "Logistics" ||
            member.role === "Customer Service" ||
            member.role === "Sales" ||
            member.role === "IOT" ||
            member.role === "Software Developer" ||
            member.role === "Human Reasources" ||
            member.role === "Digital Marketing" ||
            member.role === "Engineer" ||
            member.role === "Operations" ||
            member.role === "Visuals" ||
            member.role === "Advertising" ||
            member.role === "Intern")
      );
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
          setProjectMembers(projectResponse.data.members);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };
    fetchData();
  }, [dispatch, projectId]);

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));

      const {
        leadCustomerName,
        leadSubject,
        leadEmail,
        leadSource,
        leadPhoneNumber,
        leadDescription,
        leadStatus,
        assignedTo,
      } = values;

      let leadId = null;
      if (initialLead) {
        leadId = initialLead._id;
      }

      const leadData = {
        leadCustomerName, 
        leadSubject,
        project: project._id,
        leadEmail,
        leadSource,
        leadPhoneNumber,
        leadDescription,
        leadStatus,
        assignedTo,
        assignedBy: user._id,
        updatedBy: user._id,
        leadId,
      };

      const response = await CreateLead(leadData);

      if (response.success) {
        await AddNotification({
          title: `You have been assigned a new Lead "${leadSubject}" in ${project.name}`,
          user: assignedTo,
          onClick: `/lead/detail/${projectId}/${response.data._id}`,
          description: leadDescription,
          leadSubject: leadSubject,
        });

        reloadData();
        toast.success(response.message);
        form.resetFields();
        handleClose();
      } else {
        toast.error(response.message);
      }

      dispatch(setButtonLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  return (
    <Modal
      title="Create Lead"
      open={showModal}
      onCancel={handleClose}
      footer={[
        <Button
          key="back"
          onClick={handleClose}
          className="cancel-button-ath-color"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          className="save-button-ath-color"
          onClick={() => form.submit()}
          loading={buttonLoading}
        >
          {buttonLoading ? "loading" : "Save Changes"}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Lead Subject"
              name="leadSubject"
              rules={[
                { required: true, message: "Please enter Lead subject!" },
              ]}
            >
              <Input placeholder="Lead subject" style={{ width: "170px" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Customer Name"
              name="leadCustomerName"
              rules={[
                { required: true, message: "Please enter customer name!" },
              ]}
            >
              <Input placeholder="Customer Name" style={{ width: "170px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={<span style={{ fontSize: "12px" }}>Email Address</span>}
              name="leadEmail"
              className="form-col"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
                {
                  required: true,
                  message: "Please enter customer email!",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Lead Email"
                style={{ width: "170px" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="leadPhoneNumber"
              rules={[
                { required: true, message: "Please enter phone number!" },
              ]}
            >
              <Input placeholder="Phone Number" style={{ width: "170px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Lead Source"
              name="leadSource"
              // rules={[
              //   { required: true, message: "Please select Lead source!" },
              // ]}
            >
              <Select
                placeholder="Select a lead source"
                style={{ width: "170px" }}
              >
                <Option value="facebook">Facebook</Option>
                <Option value="x">X</Option>
                <Option value="instagram">Instagram</Option>
                <Option value="Thread">Thread</Option>
                <Option value="Website">Website</Option>
                <Option value="WhatsApp">WhatsApp</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assign To"
              name="assignedTo"
              rules={[
                // { required: true, message: "Please select a user!" },
                {
                  validator: (_, value) =>
                    validateAssignedTo()
                      ? Promise.resolve()
                      : Promise.reject(
                          "Invalid user or not a project member with owner, admin, or employee role"
                        ),
                },
              ]}
            >
              <Select
                placeholder="Assign Lead"
                style={{ width: "170px" }}
                onChange={(value) => setAssignedToId(value)}
              >
                {projectMembers?.map((member) => (
                  <Option key={member.user._id} value={member.user._id}>
                    {`${member.user.firstName} ${member.user.lastName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Lead Status"
              name="leadStatus"
              // rules={[
              //   { required: true, message: "Please select a lead status!" },
              // ]}
            >
              <Select
                placeholder="Select a lead status"
                style={{ width: "170px" }}
              >
                <Option value="open">Open</Option>
                <Option value="inview">In view</Option>
                <Option value="Interested">Converted</Option>
                <Option value="not interested">Not interested</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Lead Description" name="leadDescription">
              <Input.TextArea rows={4} placeholder="Enter your text here" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddLeadModal;
