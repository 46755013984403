import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Upload, Row, Col } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { setLoading, setButtonLoading } from "../../redux/loadersSlice";
import { CreateContact } from "../../apicalls/contact";
import { useDispatch, useSelector } from "react-redux";
import { AddNotification } from "../../apicalls/notifications";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { GetProjectById } from "../../apicalls/projects";
// import { getAntdFormInputRules } from "../../utils/helpers";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import "./addcontact.css";

const { Option } = Select;

const AddContactModal = ({
  showModal,
  reloadData,
  initialContact,
  handleClose,
}) => { 
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users) || {};
  const [project, setProject] = useState(null);
  const [projectMembers, setProjectMembers] = useState(null);
  const [assignedToId, setAssignedToId] = useState(null);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const { buttonLoading } = useSelector((state) => state.loaders);

  useEffect(() => {
    if (initialContact) {
      setImages(initialContact.attachments || []);
    }
  }, [initialContact]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
          setProjectMembers(projectResponse.data.members);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  const validateAssignedTo = () => {
    if (projectMembers && assignedToId) {
      return projectMembers.some(
        (member) =>
          member.user._id === assignedToId &&
          (member.role === "owner" ||
            member.role === "Admin" ||
            member.role === "Employee" || member.role === "Finance"
            || member.role === "Credit recorvery" || member.role ===  "Projects"
            || member.role === "Logistics" || member.role === "Customer Service" ||
            member.role === "Sales" || member.role === "IOT" || member.role === "Software Developer" ||
            member.role === "Human Reasources" || member.role === "Digital Marketing" || member.role ===  "Engineer" ||
            member.role === "Operations" || member.role === "Visuals" || member.role ===  "Advertising" || member.role === "Intern"
            
            )
      );
    }
    return false;
  };

  const deleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));

      const {
        contactFirstName,
        contactLastName,
        contactEmail,
        contactAddress,
        contactSource,
        occupation,
        phoneNumbers,
        additionalPhoneNumbers,
        assignedTo,
      } = values;

      const selectedGender = values.gender[0];
      const genderMap = {
        male: "male",
        female: "female",
        others: "others",
      };

      let contactId = null;
      if (initialContact) {
        contactId = initialContact._id;
      }

      const formData = new FormData();
      formData.append("contactFirstName", contactFirstName);
      formData.append("contactLastName", contactLastName);
      formData.append("contactSource", contactSource);
      formData.append("gender", genderMap[selectedGender]);
      formData.append("contactEmail", contactEmail);
      formData.append("contactAddress", contactAddress);
      formData.append("project", project._id);
      formData.append("occupation", occupation);
      formData.append("location[state]", state);
      formData.append("location[country]", country);
      formData.append("updatedBy", user._id);
      formData.append("phoneNumbers", phoneNumbers);
      formData.append("phoneNumbers", additionalPhoneNumbers);
      formData.append("assignedTo", assignedTo);
      formData.append("createdBy", user._id);
      formData.append("contactId", contactId);

      files.forEach((file, index) => {
        formData.append(`files`, file.originFileObj);
      });

      const response = await CreateContact(formData);

      if (response.success) {
        AddNotification({
          title: `You have been assigned a new contact "${contactFirstName} ${contactLastName}" in ${project.name}`,
          user: assignedTo,
          onClick: `/contact/detail/${projectId}/${response.data._id}`,
          description: `Please reach out to the contact via ${phoneNumbers}`,
          contactFirstName: contactFirstName,
        });

        reloadData();
        toast.success(response.message);
        handleClose();
      } else {
        toast.error(response.message);
      }
      dispatch(setButtonLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  return (
    <Modal
      title="Add Contact"
      open={showModal}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
          Cancel
        </Button>,
         <Button
         key="submit"
        className="save-button-ath-color"
         onClick={() => form.submit()}
         loading={buttonLoading}
       >
         {buttonLoading ? "loading" : "Save Changes"}
       </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
        <>
          <Row>
            <Col span={8}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>First Name</span>}
                name="contactFirstName"
                className="form-col"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input style={{ width: "130px" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Last Name</span>}
                name="contactLastName"
                className="form-col"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input style={{ width: "130px" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Source</span>}
                name="contactSource"
                className="form-col"
              >
                <Input style={{ width: "130px" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item label="Gender" name="gender">
                <Select
                  style={{ width: "130px" }}
                  placeholder="Gender"
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                    { value: "others", label: "Others" },
                  ]}
                  onChange={(value) => form.setFieldsValue({ gender: [value] })}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Email Address</span>}
                name="contactEmail"
                className="form-col"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                  {
                    required: true,
                    message: "Please enter customer email!",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Email"
                  style={{ width: "130px" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Phone number</span>}
                name="phoneNumbers"
                className="form-col"
                rules={[
                  { required: true, message: "Please enter phone number!" },
                ]}
              >
                <Input placeholder="Phone Number" style={{ width: "130px" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Work Phone</span>}
                name="additionalPhoneNumbers"
                className="form-col"
              >
                <Input placeholder="Work Phone" style={{ width: "130px" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Occupation</span>}
                name="occupation"
                className="form-col"
              >
                <Input
                  placeholder="Enter contact occupation"
                  style={{ width: "130px" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Assign To"
                name="assignedTo"
                rules={[
                  { required: true, message: "Please select a user!" },
                  {
                    validator: (_, value) =>
                      validateAssignedTo()
                        ? Promise.resolve()
                        : Promise.reject(
                            "Invalid user or not a project member with owner, admin, or employee role"
                          ),
                  },
                ]}
              >
                <Select
                  placeholder="Assign to"
                  style={{ width: "130px" }}
                  onChange={(value) => setAssignedToId(value)}
                >
                  {projectMembers?.map((member) => (
                    <Option key={member.user._id} value={member.user._id}>
                      {`${member.user.firstName} ${member.user.lastName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Country</span>}
                name={["location", "country"]}
                className="form-col"
              >
                <CountryDropdown
                  value={country}
                  onChange={(val) => setCountry(val)}
                  style={{ width: "170px", fontSize: 15 }}
                  tabIndex={1000}
                  disabled={false}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>State</span>}
                name={["location", "state"]}
                className="form-col"
              >
                <RegionDropdown
                  country={country}
                  value={state}
                  onChange={(val) => setState(val)}
                  style={{ width: "170px", fontSize: 15 }}
                  tabIndex={1000}
                  disabled={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Address</span>}
                name="contactAddress"
              >
                <Input.TextArea rows={4} placeholder="Enter contact address" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Images</span>}
              >
                <div>
                  {images.map((image, index) => (
                    <span key={index}>
                      <img
                        src={image}
                        alt="Selected"
                        style={{ maxWidth: "50%", marginTop: "10px" }}
                      />
                      <DeleteOutlined onClick={() => deleteImage(index)} />
                    </span>
                  ))}
                </div>
                <Upload
                  name="files"
                  showUploadList={false}
                  onChange={(info) => {
                    const fileList = info.fileList;
                    setFiles(fileList);
                    setImages(
                      fileList.map((file) =>
                        URL.createObjectURL(file.originFileObj)
                      )
                    );
                  }}
                  multiple={true}
                >
                  <Button icon={<PlusOutlined />} />
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};

export default AddContactModal;
