import React from "react";
import { Card, Row, Col, Badge } from "antd";
import { getDateFormat } from "../../utils/helpers";

const UserDetailsCard = ({ project, currentUserRole }) => {
  // console.log("Project object:", project);
  return (
    <Card className="shadow border-warning">
      <div>
        <h1 className="mb-3 text-uppercase font-weight-bold fs-4">
          {project?.name}
        </h1>
        <p className="mb-4 fs-6 text-secondary">{project?.description}</p>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="d-flex align-items-center">
              <span className="fw-bold me-2">Role:</span>
              <span>{currentUserRole}</span>
            </div>
          </Col>
          <Col span={12}>
            <div className="d-flex align-items-center">
              <span className="fw-bold me-2">Status:</span>
              <span>
                <Badge
                  style={{
                    backgroundColor: "#52c41a",
                  }}
                  className="site-badge-count-109"
                  count={project?.status} // Pass the status as the count prop
                />
              </span>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="d-flex align-items-center">
              <span className="fw-bold me-2">Created At:</span>
              <span>{getDateFormat(project?.createdAt)}</span>
            </div>
          </Col>
          <Col span={12}>
            <div className="d-flex align-items-center">
              <span className="fw-bold me-2">Created by:</span>
              <span>
                {project?.owner?.firstName} {project?.owner?.lastName}
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Row className="mb-3">
            <Col span={24}>
              <p className="mb-0">
                <strong>Company Phone Number:</strong> {project.phone}
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    </Card>
  );
};

export default UserDetailsCard;
