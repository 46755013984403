import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Upload, Row, Col } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { setButtonLoading, setLoading } from "../../redux/loadersSlice";
import { CreateTask, DeleteImage } from "../../apicalls/task";
import { useDispatch, useSelector } from "react-redux";
import { AddNotification } from "../../apicalls/notifications";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { GetProjectById } from "../../apicalls/projects";
import { getAntdFormInputRules } from "../../utils/helpers";
import { GetAllContacts } from "../../apicalls/contact";
import "./addtask.css"

const { Option } = Select;

const AddTaskModal = ({ handleClose, reloadData, initialTask, showModal }) => {
  const { projectId, taskId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users) || {};
  const [project, setProject] = useState(null);
  const [projectMembers, setProjectMembers] = useState(null);
  const [projectContacts, setProjectContacts] = useState(null);
  const [assignedToId, setAssignedToId] = useState(null); 
  const [contactId, setContactId] = useState(null);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const { buttonLoading } = useSelector((state) => state.loaders);
  

  useEffect(() => {
    if (initialTask) {
      setImages(initialTask.attachments || []);
    }
  }, [initialTask]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
          setProjectMembers(projectResponse.data.members);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getContacts();
    }
  }, [project]);

  const getContacts = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetAllContacts({
        project: project?._id,
      });
      dispatch(setLoading(false));
      if (response.success) {
        setProjectContacts(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const validateAssignedTo = () => {
    if (projectMembers && assignedToId) {
      return projectMembers.some(
        (member) =>
          member.user._id === assignedToId &&
          (member.role === "owner" ||
          member.role === "Admin" ||
          member.role === "Employee" || member.role === "Finance"
          || member.role === "Credit recorvery" || member.role ===  "Projects"
          || member.role === "Logistics" || member.role === "Customer Service" ||
          member.role === "Sales" || member.role === "IOT" || member.role === "Software Developer" ||
          member.role === "Human Reasources" || member.role === "Digital Marketing" || member.role ===  "Engineer" ||
          member.role === "Operations" || member.role === "Visuals" || member.role ===  "Advertising" || member.role === "Intern"
          )
      );
    }
    return false;
  };

  // const validateContact = () => {
  //   if (projectContacts && contactId) {
  //     return projectContacts.some(
  //       (contact) =>
  //         contact._id === contactId && contact.contactEmail === "contactEmail"
  //     );
  //   }
  //   return false;
  // };

  const deleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    // Update the files state to exclude the deleted image
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));
  
      const { taskName, description, assignedTo, status } = values;
  
      let taskId = null;
      if (initialTask) {
        taskId = initialTask._id;
      }
  
      const formData = new FormData();
      formData.append("taskName", taskName);
      formData.append("description", description);
      formData.append("project", project._id ); // Add conditional check here
      formData.append("contact", contactId);
      formData.append("assignedTo", assignedTo);
      formData.append("assignedBy", user._id);
      formData.append("updatedBy", user._id);
      formData.append("taskId", taskId);
  
      files.forEach((file, index) => {
        formData.append(`files`, file.originFileObj); // Use originFileObj to append File object
      });
  
      const response = await CreateTask(formData);
  
      if (response.success) {
        AddNotification({
          title: `You have been assigned a new task "${taskName}" in ${project.name}`,
          user: assignedTo,
          onClick: `/task/detail/${projectId}/${response.data._id}`,
          description: description,
          taskName: taskName,
        });
  
        reloadData();
        toast.success(response.message);
        handleClose();
      } else {
        toast.error(response.message);
      }
  
      dispatch(setButtonLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };
  

  return (
    <Modal
      title="Add Task"
      open={showModal}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
          Cancel
        </Button>,
        <Button
        key="submit"
      className="save-button-ath-color"
        onClick={() => form.submit()}
        loading={buttonLoading}
      >
        {buttonLoading ? "loading" : "Save Changes"}
      </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 24 }} // Set labelCol to occupy the full width
      >
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Task Name</span>}
                name="taskName"
                className="form-col"
                rules={[{ required: true, message: "Please enter task name!" }]}
              >
                <Input placeholder="Task Name" style={{ width: "170px" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Assign To"
                name="assignedTo"
                rules={[
                  { required: true, message: "Please select a user!" },
                  {
                    validator: (_, value) =>
                      validateAssignedTo()
                        ? Promise.resolve()
                        : Promise.reject(
                            "Invalid user or not a project member with owner, admin, or employee role"
                          ),
                  },
                ]}
              >
                <Select
                  placeholder="Assign to"
                  onChange={(value) => setAssignedToId(value)}
                >
                  {projectMembers?.map((member) => (
                    <Option key={member.user._id} value={member.user._id}>
                      {`${member.user.firstName} ${member.user.lastName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Task Status</span>}
                name="status"
                className="form-col"
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Select a task status"
                >
                   <Option value="Pending">Pending</Option>
                  <Option value="Following up">Following up</Option>
                  <Option value="In progress">In progress</Option>
                  <Option value="Task Canceled">Task Cancelled</Option>
                  <Option value="Closed Task">Task Completed</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
            <Form.Item
  label="Contact"
  name="contact"
  rules={[
    { required: true, message: "Please select a contact!" },
  ]}
>
  <Select
    placeholder="Select contact"
    onChange={(value) => setContactId(value)}
  >
    {projectContacts?.map((contact) => (
      <Option key={contact._id} value={contact._id}>
        {`${contact.contactFirstName} ${contact.contactLastName} ${contact.contactEmail}`}
      </Option>
    ))}
  </Select>
</Form.Item>
            </Col>
          </Row>


          <Row>
          <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px" }}>Task Description</span>
                }
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter task description!",
                  },
                  ...getAntdFormInputRules,
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter your text here" />
              </Form.Item>
            </Col>

          </Row>

          <Row>
          

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Images</span>}
              >
                <div>
                  {images.map((image, index) => (
                    <span key={index}>
                      <img
                        src={image}
                        alt="Selected"
                        style={{ maxWidth: "50%", marginTop: "10px" }}
                      />
                      <DeleteOutlined onClick={() => deleteImage(index)} />
                    </span>
                  ))}
                </div>
                <Upload
                  name="files" // Ensure this matches the field name expected by the backend
                  showUploadList={false}
                  onChange={(info) => {
                    const fileList = info.fileList;
                    setFiles(fileList); // Update the files state
                    setImages(
                      fileList.map((file) =>
                        URL.createObjectURL(file.originFileObj)
                      )
                    ); // Use originFileObj for preview URL
                  }}
                  multiple={true} // Allow multiple file uploads
                >
                  <Button icon={<PlusOutlined />} />
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};

export default AddTaskModal;
