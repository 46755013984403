import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import Sidebar from "../../components/user/Sidebar";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import { GetProjectById } from "../../apicalls/projects";
import { PlusOutlined, WechatOutlined } from "@ant-design/icons";
import { SaveChatbotConfig, GetChatbotConfig } from "../../apicalls/chatbot";
import ReactFlow, { addEdge, Background, Controls, MiniMap, useEdgesState, useNodesState } from "reactflow";
import { useMediaQuery } from "react-responsive";
import SyncBotsDrawer from "../../components/syncbot/SyncBotsDrawer";
import ChatbotPreview from "../../components/syncbot/ChatbotPreview";

const SyncBotsSetup = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [showChatbotDrawer, setShowChatbotDrawer] = useState(false);
  const [chatbotConfig, setChatbotConfig] = useState([]);
  const [initialMessages, setInitialMessages] = useState([]);
  const [showChatbotPreview, setShowChatbotPreview] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        const chatbotResponse = await GetChatbotConfig(projectId);
        if (chatbotResponse.success) {
          const config = chatbotResponse.data.config || {};
          setChatbotConfig(config);
          setNodes(config.nodes || []);
          setEdges(config.edges || []);
          setInitialMessages(config.initialMessages || []);
        } else {
          throw new Error(chatbotResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        message.error(error.message);
      }
    };

    fetchData();
  }, [projectId, dispatch]);

  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  const handleShowChatbotDrawer = () => setShowChatbotDrawer(true);
  const handleCloseChatbotDrawer = () => setShowChatbotDrawer(false);

  const saveChatbotConfig = async (config) => {
    try {
      dispatch(setLoading(true));
      const response = await SaveChatbotConfig({ projectId, config });
      if (response.success) {
        toast.success("Chatbot configuration saved successfully!");
      } else {
        throw new Error(response.message);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  useEffect(() => {
    saveChatbotConfig({ nodes, edges, initialMessages });
  }, [nodes, edges, initialMessages]);

  const handleSaveConfig = (newConfig) => {
    const nodes = newConfig.map((widget, index) => ({
      id: `node-${index}`,
      type: 'default',
      position: { x: 250 * index, y: 5 },
      data: { label: `${widget.type} Node` }
    }));
    const edges = [];
    setNodes(nodes);
    setEdges(edges);
    setChatbotConfig(newConfig);
    setInitialMessages([{ content: "Hello, how can I help you?" }]);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
          <Sidebar projectId={projectId} />
        </div>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
            <h1 className="h2">SyncBot Setup</h1>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Button
              type="primary"
              onClick={handleShowChatbotDrawer}
              style={getButtonStyle()}
            >
              <PlusOutlined /> Add Widget
            </Button>
          </div>
          <div style={{ height: "600px", border: "1px solid #ddd" }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              fitView
            >
              <Background />
              <Controls />
              <MiniMap />
            </ReactFlow>
          </div>
          <SyncBotsDrawer
            visible={showChatbotDrawer}
            onClose={handleCloseChatbotDrawer}
            onSave={handleSaveConfig}
            initialConfig={chatbotConfig}
            setInitialMessages={setInitialMessages}
          />
          <div style={{ position: "fixed", bottom: 20, right: 20 }}>
            <WechatOutlined
              style={{ fontSize: "24px", cursor: "pointer" }}
              onClick={() => setShowChatbotPreview(true)}
            />
          </div>
          <ChatbotPreview
            config={chatbotConfig}
            visible={showChatbotPreview}
            onClose={() => setShowChatbotPreview(false)}
          />
        </main>
      </div>
    </div>
  );
};

export default SyncBotsSetup;
