import React from "react";
import {  FileProtectOutlined, UserOutlined } from "@ant-design/icons";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const ProfileTab = ({ activeTab, onChange }) => {
  const handleTabChange = (key) => {
    onChange(key);
  };

  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange} centered>
      <TabPane
        tab={
          <span>
          <FileProtectOutlined />
            Projects
          </span>
        }
        key="account"
      ></TabPane>
      <TabPane
        tab={
          <span>
           <UserOutlined />
            Profile
          </span>
        }
        key="profile"
      ></TabPane>
    </Tabs>
  );
};

export default ProfileTab;
