import React, { useEffect, useState } from "react";
import { List, Avatar, Row, Col, Rate, Card, Carousel, Spin } from "antd";
import { GetReviews } from "../../apicalls/users";
import { useMediaQuery } from "react-responsive";
import "./reviews.css";
import { UserOutlined } from "@ant-design/icons";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await GetReviews();
      if (response.success) {
        setReviews(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch reviews:", error.message);
    } finally {
      setLoading(false); // Set loading to false after fetching reviews
    }
  };

  // Define breakpoints
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMdOrLarger = useMediaQuery({ minWidth: 769 });

  const getSlidesToShow = () => {
    if (isXs || isSm) return 1;
    if (isMdOrLarger) return 1;
    return 1;
  };

  return (
    <div className="container px-4 py-5 ath-custom-margin">
      <h2 className="text-start ath-feature-text mb-5">
        <strong>Customer</strong> Review
      </h2>
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Spin size="large" />
          <p>Loading reviews, please wait...</p>
        </div>
      ) : reviews.length === 0 ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <p>No Reviews</p>
        </div>
      ) : (
        <Carousel
          slidesToShow={getSlidesToShow()}
          slidesToScroll={1}
          arrows
          autoplay
          autoplaySpeed={2000}
          infinite
          // className="custom-carousel"
        >
          {reviews.map((item) => (
            <div key={item._id}>
              {/* Add console logs */}
              {console.log("User object:", item.user)}

              <Row gutter={[16, 16]} justify="center">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Card className="shadow ath-review-card">
                    <List.Item.Meta
                      avatar={
                        item.user.avatar ? (
                          <Avatar src={item.user.avatar.url} size={50} />
                        ) : (
                          <Avatar icon={<UserOutlined />} size={50} />
                        )
                      }
                      title={
                        <div>
                          <strong>{`${item.user.firstName} ${item.user.lastName}`}</strong>
                        </div>
                      }
                      description={
                        <div>
                          <small>{`${item.user.company}`}</small>
                          <div>
                            <Rate disabled defaultValue={item.rating} />
                          </div>
                        </div>
                      }
                    />
                    <p>{item.review}</p>
                    <small>{new Date(item.createdAt).toLocaleString()}</small>
                  </Card>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default Reviews;
