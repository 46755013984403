const { apiRequest } = require (".");


export const RegisterUser = async (payload) =>  apiRequest('post', '/api/users/register', payload);
export const LoginUser = async (payload) =>  apiRequest('post', '/api/users/login', payload);
export const GetLoggedInUser = async () => apiRequest('get', '/api/users/get-logged-in-user');
export const AddReview = async (payload) => apiRequest('post', '/api/users/add-review', payload);
export const GetReviews = async () => apiRequest('get', '/api/users/get-reviews');

export const ForgotPassword = async (payload) => apiRequest('post', '/api/users/forgot-password', payload);
export const ResetPassword = async (token, newPassword) => {
   const url = `/api/users/reset-password/${token}`; 
   const body = { password: newPassword }; 
   return apiRequest('post', url, body);
 };
 