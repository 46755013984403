import React, { useState, useEffect } from "react";
import { Popconfirm, Button, Drawer, Menu } from "antd";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { GetLoggedInUser } from "../../apicalls/users";
import { useDispatch, useSelector } from "react-redux";
import { SetUser } from "../../redux/usersSlice";
import {
  BarChartOutlined,
  CommentOutlined,
  ContactsOutlined,
  DashboardOutlined,
  HomeOutlined,
  LinkOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  SettingOutlined,
  ShopOutlined,
  TagsOutlined,
  ThunderboltOutlined,
  TrophyOutlined,
  VideoCameraAddOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CloseOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

const { SubMenu } = Menu;

const Sidebar = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);

  const [disabledItem, setDisabledItem] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMdOrLarger = useMediaQuery({ minWidth: 769 });

  const handleClick = (itemId) => {
    setDisabledItem(itemId);
  };

  const getUser = async () => {
    try {
      const response = await GetLoggedInUser();
      if (response.success) {
        dispatch(SetUser(response.data)); // Use the correct case here
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUser();
    } else {
      navigate("/login");
    }
  }, []);

  const confirm = (e) => {
    console.log(e);
    toast.success("Log out successfully");
  };

  const cancel = (e) => {
    console.log(e);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const renderMenuItems = () => (
    <Menu mode="inline" className="mt-5 shadow-right border-right">
      <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
        <NavLink to={`/dashboard/project/${projectId}`}>Dashboard</NavLink>
      </Menu.Item>
      <Menu.Item key="leads" icon={<UserOutlined />}>
        <NavLink to={`/lead/project/${projectId}`}>Leads</NavLink>
      </Menu.Item>
      <Menu.Item key="contact" icon={<ContactsOutlined />}>
        <NavLink to={`/contact/project/${projectId}`}>Contact</NavLink>
      </Menu.Item>
      <Menu.Item key="calls" icon={<PhoneOutlined />}>
        <NavLink to={`/call/project/${projectId}`}>Calls</NavLink>
      </Menu.Item>
      <Menu.Item key="tasks" icon={<OrderedListOutlined />}>
        <NavLink to={`/task/project/${projectId}`}>Tasks</NavLink>
      </Menu.Item>
      <Menu.Item key="deals" icon={<TrophyOutlined />}>
        <NavLink to={`/deal/project/${projectId}`}>All Deals</NavLink>
      </Menu.Item>
      <Menu.Item key="products" icon={<ShopOutlined />}>
        <NavLink to={`/product/project/${projectId}`}>Products</NavLink>
      </Menu.Item>

      <Menu.Item
        key="socials"
        icon={<CommentOutlined />}
        disabled={disabledItem === "socials"}
        onClick={() => handleClick("socials")}
      >
        Socials{" "}
        {disabledItem === "socials" && (
          <span style={{ fontSize: "0.8em" }}> - Coming Soon</span>
        )}
      </Menu.Item>
      <Menu.Item
        key="meetings"
        icon={<VideoCameraAddOutlined />}
        disabled={disabledItem === "meetings"}
        onClick={() => handleClick("meetings")}
      >
        Meetings{" "}
        {disabledItem === "meetings" && (
          <span style={{ fontSize: "0.8em" }}> - Coming Soon</span>
        )}
      </Menu.Item>
      <Menu.Item
        key="reports"
        icon={<BarChartOutlined />}
        disabled={disabledItem === "reports"}
        onClick={() => handleClick("reports")}
      >
        Reports{" "}
        {disabledItem === "reports" && (
          <span style={{ fontSize: "0.8em" }}> - Coming Soon</span>
        )}
      </Menu.Item>

      {/* <Menu.Item key="syncbots" icon={<ThunderboltOutlined />}>
        <NavLink to={`/syncbot/project/${projectId}`}>SYNC BOTS</NavLink>
      </Menu.Item> */}

      <Menu.Item
        key="syncbots"
        icon={<ThunderboltOutlined />}
        disabled={disabledItem === "syncbots"}
        onClick={() => handleClick("syncbots")}
      >
        SYNC BOTS{" "}
        {disabledItem === "syncbots" && (
          <span style={{ fontSize: "0.8em" }}> - Coming Soon</span>
        )}
      </Menu.Item>

      <Menu.Item
        key="marketplace"
        icon={<LinkOutlined />}
        disabled={disabledItem === "marketplace"}
        onClick={() => handleClick("marketplace")}
      >
        SYNC APPS{" "}
        {disabledItem === "marketplace" && (
          <span style={{ fontSize: "0.8em" }}> - Coming Soon</span>
        )}
      </Menu.Item>

      <Menu.Divider color="#082e67" />
      <Menu.Item key="account" icon={<HomeOutlined />}>
        <NavLink to="/account">My Account</NavLink>
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        <NavLink to={`/setting/project/${projectId}`}>Project Settings</NavLink>
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        <Popconfirm
          title="Log out?"
          onConfirm={() => {
            confirm();
            localStorage.removeItem("token");
            navigate("/login");
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
          placement="bottom"
        >
          Log out
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {isMdOrLarger ? (
        // Show menu items directly on medium and larger screens
        renderMenuItems()
      ) : (
        // Show drawer and toggle icons on smaller screens
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {drawerVisible ? (
              <MenuFoldOutlined onClick={toggleDrawer} />
            ) : (
              <MenuUnfoldOutlined onClick={toggleDrawer} />
            )}
          </div>
          <Drawer
            title="Menu"
            placement="left"
            closable={true}
            onClose={toggleDrawer}
            visible={drawerVisible}
            closeIcon={<CloseOutlined />}
          >
            {renderMenuItems()}
          </Drawer>
        </>
      )}
    </div>
  );
};

export default Sidebar;
