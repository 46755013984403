import React from "react";
import "./auth.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAntdFormInputRules } from "../../utils/helpers";
import { ForgotPassword } from "../../apicalls/users";
import toast from "react-hot-toast";
import { setButtonLoading } from "../../redux/loadersSlice";

const ForgetPassword = () => {
  const [form] = Form.useForm(); // Get the form instance
  const { buttonLoading } = useSelector((state) => state.loaders);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));
      const response = await ForgotPassword(values);
      dispatch(setButtonLoading(false));
      if (response.success) {
        toast.success(response.message);
        form.resetFields(); // Reset the form values
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error("Error in password reset request");
      console.error("Error in password reset request:", error);
    }
  };

  return (
    <div>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="ath-header-text mb-3">
              Lets Get <br /> You Back In ⚙️
            </h1>
            <p className="col-lg-10 ath-desc-text">
              Enter your email address to get an account recovery link.
            </p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <Form
              form={form} // Bind the form instance
              className="p-4 p-md-5 border rounded-3 bg-body-transparent bg-transparent shadow"
              onFinish={onFinish}
            >
              <div className="form-floating mb-3">
                <Form.Item
                  name="email"
                  label="Email address"
                  rules={getAntdFormInputRules}
                >
                  <Input placeholder="name@example.com" />
                </Form.Item>
              </div>
              <div className="d-flex justify-content-center ">
                <Button
                  type="primary"
                  className="btn-lg ath-auth-button shadow"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  {buttonLoading ? "loading" : "Request"}
                </Button>
              </div>
              <hr className="my-4" />
              <div className=" text-center mx-auto">
                <small className="text-body-secondary mx-auto">
                  Remember Password ?
                  <span>
                    {" "}
                    <NavLink to="/login">login</NavLink>{" "}
                  </span>
                </small>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
