import { apiRequest } from ".";

export const CreateLead = async (lead) =>
  apiRequest("post", "/api/leads/create-lead", lead);

export const GetAllLeads = async (filters) =>
  apiRequest("post", "/api/leads/get-all-leads", filters);

export const GetLeadById = async (leadId) =>
  apiRequest("get", `/api/leads/get-lead-by-id/${leadId}`);

export const UpdateLead = async (lead) =>
  apiRequest("post", "/api/leads/update-lead", lead);

export const DeleteLead = async (id) => 
  apiRequest("post", "/api/leads/delete-lead", { _id: id });

export const AddLeadComment = async (comment) => 
  apiRequest("post", "/api/leads/add-comment", comment);

export const GetCommentsByLeadId = async (projectId, leadId) =>
  apiRequest("get", `/api/leads/get-comments/${projectId}/${leadId}`);