import { apiRequest } from ".";


export const CreatePipeline = async (pipeline) =>
  apiRequest("post", "/api/pipelines/create-pipeline", pipeline);

export const GetAllPipelines = async (filters) =>
  apiRequest("post", "/api/pipelines/get-all-pipelines", filters);

export const GetPipelineById = async (pipelineId) =>
  apiRequest("get", `/api/pipelines/get-pipeline-by-id/${pipelineId}`);

export const UpdatePipeline = async (pipeline) =>
  apiRequest("post", "/api/pipelines/update-pipeline", pipeline);

export const DeletePipeline = async (id) =>
  apiRequest("post", "/api/pipelines/delete-pipeline", { _id: id });

  