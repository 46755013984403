import React, { useState, useEffect } from "react";
import { Drawer, Input, Button, List } from "antd";
import { UserOutlined } from "@ant-design/icons";

const ChatbotPreview = ({ config, visible, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentNodeId, setCurrentNodeId] = useState("");

  useEffect(() => {
    if (config.initialMessages && config.initialMessages.length > 0) {
      const firstNode = config.nodes?.find(node => node.id === config.initialMessages[0].nodeId);
      if (firstNode) {
        setMessages([{ type: "bot", text: firstNode.data.label }]);
        setCurrentNodeId(firstNode.id);
      }
    }
  }, [config]);

  const handleUserMessage = () => {
    if (currentMessage.trim() === "") return;

    const updatedMessages = [...messages, { type: "user", text: currentMessage }];
    setMessages(updatedMessages);
    setCurrentMessage("");

    const currentNode = config.nodes?.find(node => node.id === currentNodeId);
    if (currentNode) {
      const nextEdge = config.edges?.find(edge => edge.source === currentNodeId);
      if (nextEdge) {
        const nextNode = config.nodes?.find(node => node.id === nextEdge.target);
        if (nextNode) {
          let botMessage = nextNode.data.label;

          // Check if the node has custom widgets and update the message accordingly
          if (nextNode.data.widget) {
            const widget = nextNode.data.widget;
            switch (widget.type) {
              case "options":
                botMessage = `${widget.header}\n${widget.config.options}`;
                break;
              case "name":
              case "email":
              case "phone":
                botMessage = widget.config.label;
                break;
              case "image":
                botMessage = (
                  <div>
                    <img src={widget.config.url} alt="Image" />
                  </div>
                );
                break;
              default:
                botMessage = widget.header;
                break;
            }
          }

          setMessages([...updatedMessages, { type: "bot", text: botMessage }]);
          setCurrentNodeId(nextNode.id);
        }
      }
    }
  };

  return (
    <Drawer
      title="Chatbot Preview"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
        <List
          dataSource={messages}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<UserOutlined />}
                title={item.type === "bot" ? "Bot" : "You"}
                description={item.text}
              />
            </List.Item>
          )}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <Input
          value={currentMessage}
          onChange={e => setCurrentMessage(e.target.value)}
          placeholder="Type a message"
          style={{ marginRight: "10px" }}
        />
        <Button type="primary" onClick={handleUserMessage}>Send</Button>
      </div>
    </Drawer>
  );
};

export default ChatbotPreview;
