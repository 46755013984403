import { apiRequest } from ".";

export const CreateCall = async (call) =>
  apiRequest("post", "/api/calls/create-call", call);

export const GetAllCalls = async (filters) =>
  apiRequest("post", "/api/calls/get-all-calls", filters);

export const GetCallById = async (callId) =>
  apiRequest("get", `/api/calls/get-call-by-id/${callId}`);

export const UpdateCall = async (call) =>
  apiRequest("post", "/api/calls/update-call", call);

export const DeleteCall = async (id) =>
  apiRequest("post", "/api/calls/delete-call", { _id: id });

export const AddCallComment = async (comment) =>
  apiRequest("post", "/api/calls/add-comment", comment);

export const GetCommentsByCallId = async (projectId, callId) =>
  apiRequest("get", `/api/calls/get-comments/${projectId}/${callId}`);
