import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetLoggedInUser } from "../../apicalls/users";
import { SetUser } from "../../redux/usersSlice";
import { Card, Row, Col, Avatar, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";

const UserProfile = () => {
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const getUser = async () => {
    try {
      const response = await GetLoggedInUser();
      if (response.success) {
        dispatch(SetUser(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Col xs={24} sm={16} md={12} lg={10} xl={8}>
        <Card
          title={`${user.firstName} ${user.lastName} Profile`}
          className="shadow border-warning text-center"
          cover={
            <Avatar
              src={user.avatar.url}
              size={100}
              style={{ margin: "20px auto" }}
            />
          }
          extra={
            <Button
              type="default"
              icon={<EditOutlined />}
              onClick={() => console.log("Edit button clicked")}
              disabled
            />
          }
        >
          <p>
            <strong>Company:</strong> {user.company}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Phone Number:</strong> {user.phoneNumber}
          </p>
          <p>
            <strong>Created At:</strong>{" "}
            {moment(user.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
          <p>
            <strong>Updated At:</strong>{" "}
            {moment(user.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </Card>
      </Col>
    </Row>
  );
};

export default UserProfile;
