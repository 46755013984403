import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import { CheckCircleFilled, LoadingOutlined, TrophyOutlined } from '@ant-design/icons';
import { GetAllPipelines } from '../../apicalls/pipeline';
import { useDispatch } from 'react-redux';
import { setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import { useParams } from 'react-router-dom';
import { GetProjectById } from '../../apicalls/projects';

const Pipeline = ({ dealPipeline }) => {
  const { projectId } = useParams();
  const [projectPipelines, setProjectPipelines] = useState([]);
  const [project, setProject] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    const fetchPipelines = async () => {
      try {
        if (!project) return;
        dispatch(setLoading(true));
        const response = await GetAllPipelines({ project: project._id });
        if (response.success) {
          setProjectPipelines(response.data);
        } else {
          throw new Error(response.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    if (project) {
      fetchPipelines();
    }
  }, [dispatch, project, dealPipeline]);

  if (projectPipelines.length === 0) {
    return <p>Loading pipeline data...</p>;
  }

  const sortedPipelines = projectPipelines.slice().sort((a, b) => a.progressPercentage - b.progressPercentage);
  const currentStepIndex = sortedPipelines.findIndex(pipeline => pipeline._id === dealPipeline);

  const steps = sortedPipelines.map((pipeline, index) => {
    let icon = null;
    let color = '';

    if (index < currentStepIndex) {
      icon = <CheckCircleFilled style={{ color: '#082e67' }} />;
    } else if (index === currentStepIndex) {
      if (pipeline.progressPercentage === 100) {
        icon = <TrophyOutlined style={{ color: 'green' }} />;
      } else {
        icon = <LoadingOutlined style={{ color: '#fbc43a' }} />;
      }
    } else if (pipeline.progressPercentage === 100) {
      icon = <TrophyOutlined style={{ color: 'grey' }} />;
    }

    return {
      title: pipeline.pipelineName,
      description: pipeline.progressPercentage ? `${pipeline.progressPercentage}%` : '',
      icon,
      color,
    };
  });

  return (
    <div className='container shadow border border-warning'>
      <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Pipeline Steps</p>
      <Steps current={currentStepIndex} direction="horizontal">
        {steps.map((step, index) => (
          <Steps.Step
            key={index}
            title={<span style={{ fontSize: '9px' }}>{step.title}</span>}
            description={<span style={{ fontSize: '9px' }}>{step.description}</span>}
            icon={step.icon}
            style={{ color: step.color }}
          />
        ))}
      </Steps>
    </div>
  );
};

export default Pipeline;
