import React, { useEffect, useState } from "react";
import Sidebar from "../../components/user/Sidebar";
import {
  Table as AntTable,
  Button,
  Space,
  Modal,
  Select,
  Row,
  Col,
  Popover,
  DatePicker,
} from "antd";
import {
  PlusOutlined,
  EyeOutlined,
  PhoneOutlined,
  FilterOutlined,
  ExportOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import AddCallModal from "../../components/addcall/AddCallModal";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import { GetProjectById } from "../../apicalls/projects";
import toast from "react-hot-toast";
import { GetAllCalls } from "../../apicalls/call";
import { getDateFormat } from "../../utils/helpers";
import { useMediaQuery } from "react-responsive";
import "./admin.css";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Call = ({ call }) => {
  const [filters, setFilters] = useState({
    createdAt: "all",
    callStatus: "all",
    callOutcome: "all",
    callType: "all",
    createdBy: "all",
  });

  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [calls, setCalls] = useState([]);
  const [showAddCallModal, setShowAddCallModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        // console.log("Fetched project data:", projectResponse.data);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      // console.log("Project data changed:", project);
      getCalls();
    }
  }, [project, filters]);

  const getCalls = async () => {
    try {
      dispatch(setLoading(true));
      let filtersToSend = { project: project?._id };

      if (filters.createdBy !== "all") {
        filtersToSend.createdBy = filters.createdBy;
      }

      // Convert createdAt filters to JavaScript Date format
      if (filters.createdAt && filters.createdAt !== "all") {
        filtersToSend.createdAt = filters.createdAt.map(
          (date) => new Date(date)
        );
      }

      if (filters.callStatus !== "all") {
        filtersToSend.callStatus = filters.callStatus;
      }

      if (filters.callType !== "all") {
        filtersToSend.callType = filters.callType;
      }

      if (filters.callOutcome !== "all") {
        filtersToSend.callOutcome = filters.callOutcome;
      }

      console.log("Fetching calls with filters:", filters);

      const response = await GetAllCalls({
        project: project?._id,
        filters: filtersToSend,
      });
      dispatch(setLoading(false));
      if (response.success) {
        // console.log("Fetched calls data:", response.data);
        setCalls(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const handleAddCall = () => {
    setShowAddCallModal(true);
  };

  // Define breakpoints
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px" };
    }
  };

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      setFilters({
        ...filters,
        createdAt: dates,
      });
    } else {
      setFilters({
        ...filters,
        createdAt: "all",
      });
    }
  };

  const resetFilters = () => {
    setFilters({
      createdAt: "all",
      createdBy: "all",
      dealStatus: "all",
      paymentType: "all",
    });
  };

  // Function to handle preset date selections
  const handlePresetDate = (preset) => {
    let startDate, endDate;

    switch (preset) {
      case "today":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      case "yesterday":
        startDate = moment().subtract(1, "day").startOf("day");
        endDate = moment().subtract(1, "day").endOf("day");
        break;
      case "last7days":
        startDate = moment().subtract(6, "days").startOf("day");
        endDate = moment().endOf("day");
        break;
      case "thismonth":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        break;
      default:
        return;
    }

    setFilters({
      ...filters,
      createdAt: [startDate, endDate],
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",

      render: (text, record) => getDateFormat(text, "datetime"),
    },

    {
      title: "Contact",
      dataIndex: "contact",

      render: (contact) => {
        if (contact && contact.contactFirstName && contact.contactLastName) {
          return `${contact.contactFirstName} ${contact.contactLastName}`;
        } else {
          return "N/A"; // Or handle the case when contact data is not available
        }
      },
    },

    {
      title: "Call Type",
      dataIndex: "callType",

      render: (text, record) => record.callType,
    },
    {
      title: "Call Outcome",
      dataIndex: "callOutcome",

      render: (text, record) => record.callOutcome,
    },
    {
      title: "Call Status",
      dataIndex: "callStatus",

      render: (text, record) => record.callStatus,
    },
    {
      title: "Time",
      dataIndex: "timeOfCall",

      render: (text, record) => getDateFormat(text, "time"),
    },
    {
      title: "Duration",
      dataIndex: "callDuration",

      render: (text, record) => getDateFormat(text, "time"),
    },
    {
      title: "Owner",
      dataIndex: "createdBy",

      render: (text, record) =>
        record?.createdBy?.firstName + " " + record?.createdBy?.lastName,
    },

    {
      title: "Start Time",
      dataIndex: "callStartTime",

      render: (callStartTime) => getDateFormat(callStartTime, "time"),
    },
    {
      title: "End Time",
      dataIndex: "callEndTime",

      render: (callEndTime) => getDateFormat(callEndTime, "time"),
    },
    {
      title: "Mobile Phone",
      dataIndex: "contact",

      render: (contact) => {
        if (
          contact &&
          contact.phoneNumbers &&
          contact.phoneNumbers.length > 0
        ) {
          // Extract the first phone number without JSON parsing
          const firstPhoneNumber = contact.phoneNumbers[0];
          return firstPhoneNumber;
        } else {
          return "No phone number"; // Or any other appropriate value
        }
      },
    },

    {
      title: "Work Phone",
      dataIndex: "contact",

      render: (contact) => {
        if (
          contact &&
          contact.phoneNumbers &&
          contact.phoneNumbers.length > 1
        ) {
          // Extract the first phone number without JSON parsing
          const secondPhoneNumber = contact.phoneNumbers[1];
          return secondPhoneNumber;
        } else {
          return "No phone number"; // Or any other appropriate value
        }
      },
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" icon={<PhoneOutlined />} />
          <NavLink to={`/call/detail/${projectId}/${record._id}`}>
            <Button type="default" icon={<EyeOutlined />} />
          </NavLink>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  useEffect(() => {
    // console.log("Filters changed, fetching calls...");
    getCalls();
  }, [filters]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <Sidebar projectId={projectId} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Call Module</h1>

              <div className="btn-toolbar mb-2 mb-md-0">
                <Popover title="Log Call">
                  <Button
                    className="topbar-button-ath-color"
                    style={getButtonStyle()}
                    onClick={handleAddCall}
                    icon={<PlusOutlined />}
                  />
                </Popover>

                <Popover title="Filter Calls">
                  <Button
                    className="topbar-button-ath-color"
                    style={getButtonStyle()}
                    icon={<FilterOutlined />}
                    onClick={() => setShowFilterModal(true)}
                  />
                </Popover>

                <div className="btn-group me-3">
                  <Popover title="Calls Settings">
                    <Button
                      className="topbar-button-ath-color"
                      style={getAdditionalButtonStyle()}
                      icon={<ToolOutlined />}
                      disabled
                    />
                  </Popover>

                  <Popover title="Export File">
                    <Button
                      className="topbar-button-ath-color"
                      style={getAdditionalButtonStyle()}
                      icon={<ExportOutlined />}
                      disabled
                    />
                  </Popover>
                </div>
              </div>
            </div>
            <h2 className="mt-5">All Calls</h2>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Button
                onClick={() => handlePresetDate("today")}
                style={{ marginRight: "10px" }}
              >
                Today
              </Button>
              <Button
                onClick={() => handlePresetDate("yesterday")}
                style={{ marginRight: "10px" }}
              >
                Yesterday
              </Button>
              <Button
                onClick={() => handlePresetDate("last7days")}
                style={{ marginRight: "10px" }}
              >
                Last 7 Days
              </Button>
              <Button onClick={() => handlePresetDate("thismonth")}>
                This Month
              </Button>
            </div>

            <div className="table-responsive">
              <AntTable
                columns={columns}
                project={projectId}
                dataSource={calls}
                rowSelection={rowSelection}
              />
            </div>

            <AddCallModal
              showModal={showAddCallModal}
              handleClose={() => setShowAddCallModal(false)}
              project={project}
              reloadData={getCalls}
              initialCall={call}
            /> 

            <Modal
              title="Filter Options"
              visible={showFilterModal}
              onCancel={() => setShowFilterModal(false)}
              footer={[
                <Button
                  key="submit"
                  className="save-button-ath-color"
                  onClick={() => {
                    setShowFilterModal(false);
                    getCalls(); // Fetch tasks with updated filters
                  }}
                >
                  Apply
                </Button>,
                <Button
                  className="cancel-button-ath-color"
                  key="cancel"
                  onClick={() => setShowFilterModal(false)}
                >
                  Cancel
                </Button>,
                <Button
                  key="reset"
                  className="reset-button-ath-color"
                  onClick={() => {
                    resetFilters();
                    getCalls();
                  }}
                >
                  Reset
                </Button>,
              ]}
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>
                    <span>Created At</span>
                    <RangePicker
                      style={{ width: "100%" }}
                      onChange={handleDateRangeChange}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment().subtract(1, "day").startOf("day"),
                          moment().subtract(1, "day").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                      }}
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <span>Call Status</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("callStatus", value)
                      }
                      value={filters.callStatus}
                    >
                      <Option value="all">All</Option>
                      <Option value="Following up">Following up</Option>
                      <Option value="Pending">Pending</Option>
                      <Option value="In progress">In progress</Option>
                      <Option value="Call completed">Call completed</Option>
                    </Select>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <span>Created By</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("createdBy", value)
                      }
                      value={filters.createdBy}
                    >
                      <Option value="all">All</Option>
                      {project?.members
                        ?.filter((m) =>
                          [
                            "owner",
                            "admin",
                            "employee",
                            "finance",
                            "credit recorvery",
                            "projects",
                            "logistics",
                            "customer service",
                            "sales",
                            "iot",
                            "software developer",
                            "human reasources",
                            "digital marketing",
                            "engineer",
                            "operations",
                            "visuals",
                            "advertising",
                            "community manager",
                            "intern",
                          ].includes(m.role.toLowerCase())
                        )
                        .map((m) => (
                          <Option key={m.user._id} value={m.user._id}>
                            {m.user.firstName + " " + m.user.lastName}
                          </Option>
                        ))}
                    </Select>
                    {/* {console.log("Assigned By options:", project?.members)} */}
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <span>Call Outcome</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("callOutcome", value)
                      }
                      value={filters.callOutcome}
                    >
                      <Option value="all">All</Option>
                      <Option value="Not Reachable">Not Reachable</Option>
                      <Option value="Call Back">Call Back</Option>
                      <Option value="Call Drop">Call Drop</Option>
                      <Option value="Canceled">Canceled</Option>
                      <Option value="Bad Network">Bad Network</Option>
                      <Option value="Wrong Number">Wrong Number</Option>
                    </Select>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <span>Call Type</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("callType", value)
                      }
                      value={filters.callType}
                    >
                      <Option value="all">All</Option>
                      <Option value="inbound">Inbound Calls</Option>
                      <Option value="outbound">Outbound Calls</Option>
                      <Option value="Missed">Missed Calls</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Modal>
          </main>
        </div>
      </div>
    </>
  );
};

export default Call;
