import React, { useEffect } from "react";
import { Modal, message, Button, List, Typography, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SetNotifications } from "../../redux/usersSlice";
import {
  MarkNotificationAsRead,
  MarkAllNotificationsAsRead,
  DeleteAllNotifications,
  DeleteNotificationById,
} from "../../apicalls/notifications";
import { setLoading } from "../../redux/loadersSlice";
import { DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const NotificationsModal = ({
  showNotifications,
  setShowNotifications,
  navigate,
}) => {
  const { notifications } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const markNotificationAsRead = async (id) => {
    try {
      const response = await MarkNotificationAsRead(id);
      if (response.success) {
        const updatedNotifications = notifications.map((notification) =>
          notification._id === id ? { ...notification, read: true } : notification
        );
        dispatch(SetNotifications(updatedNotifications));
        message.success("Notification marked as read");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const markAllNotificationsAsRead = async () => {
    try {
      dispatch(setLoading(true));
      const response = await MarkAllNotificationsAsRead();
      dispatch(setLoading(false));
      if (response.success) {
        dispatch(SetNotifications(response.data));
        message.success("All notifications marked as read");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      message.error(error.message);
    }
  };

  const deleteAllNotifications = async () => {
    try {
      dispatch(setLoading(true));
      const response = await DeleteAllNotifications();
      dispatch(setLoading(false));
      if (response.success) {
        dispatch(SetNotifications([]));
        message.success("All notifications deleted");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      message.error(error.message);
    }
  };

  const deleteNotification = async (id) => {
    try {
      dispatch(setLoading(true));
      const response = await DeleteNotificationById(id);
      dispatch(setLoading(false));
      if (response.success) {
        dispatch(SetNotifications(notifications.filter((notification) => notification._id !== id)));
        message.success(response.message);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      message.error(error.message);
    }
  };

  return (
    <Modal
      title="NOTIFICATIONS"
      visible={showNotifications}
      onCancel={() => setShowNotifications(false)}
      centered
      footer={null}
      width={600} // Adjust the width as needed
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {notifications.length > 0 ? (
          <Space align="end">
            <Button
              type="text"
              onClick={markAllNotificationsAsRead}
              icon={<CheckCircleOutlined />}
            >
              Mark All as Read
            </Button>
            <Button
              type="text"
              onClick={deleteAllNotifications}
              icon={<DeleteOutlined />}
              danger
            >
              Clear Notifications
            </Button>
          </Space>
        ) : (
          <div className="flex text-center">
            <Text className="text-center">No Notifications</Text>
          </div>
        )}
        <List 
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={(item) => (
            <List.Item
              key={item._id}
              className="cursor-pointer"
              style={{
                backgroundColor: item.read ? "#ffffff"  : "#caffcf",
              }}
              actions={[
                <Button
                  type="text"
                  icon={<CheckCircleOutlined />}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the item click handler from being triggered
                    markNotificationAsRead(item._id);
                  }}
                />,
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the item click handler from being triggered
                    deleteNotification(item._id);
                  }}
                  danger
                />
              ]}
              onClick={() => {
                setShowNotifications(false);
                navigate(item.onClick);
              }}
            >
              <List.Item.Meta
                title={<Text strong>{item.title}</Text>}
                description={item.description}
              />
              <div>
                <Text type="secondary">{moment(item.createdAt).fromNow()}</Text>
              </div>
            </List.Item>
          )}
        /> 
      </Space>
    </Modal>
  );
};

export default NotificationsModal;
