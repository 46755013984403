import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Drawer, Row, Col, Card, Popover } from "antd";
import { DeleteOutlined, EyeOutlined, SaveOutlined } from "@ant-design/icons";
import { setLoading, setButtonLoading } from "../../redux/loadersSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { GetProjectById } from "../../apicalls/projects";
import { GetAllCategories, CreateCategory, DeleteCategory } from "../../apicalls/category";
import "./addcategorydrawer.css";
import moment from "moment";

const AddCategoryDrawer = ({ open, onClose, initialCategory, reloadData }) => {
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user,  } = useSelector((state) => state.users) || {};
  const { buttonLoading } = useSelector((state) => state.loaders);
  const [project, setProject] = useState(null);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  useEffect(() => {
    if (initialCategory) {
      form.setFieldsValue(initialCategory);
    }
  }, [initialCategory, form]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getCategories();
    }
  }, [project]);

  const getCategories = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetAllCategories({
        project: project?._id,
      });
      dispatch(setLoading(false));
      if (response.success) {
        setCategories(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));

      const { categoryName } = values;
      let categoryId = null;
      if (initialCategory) {
        categoryId = initialCategory._id;
      }
      const data = {
        project: project._id,
        categoryName: categoryName,
        createdBy: user._id,
        updatedBy: user._id,
        categoryId: categoryId,
      };

      const response = await CreateCategory(data);

      if (response.success) {
        form.resetFields();  // Clear the form input
        getCategories();  // Refresh the categories list
        toast.success(response.message);

        showChildrenDrawer();
      } else {
        toast.error(response.message);
      }

      dispatch(setButtonLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  const confirmDelete = async (id) => {
    try {
      const response = await DeleteCategory(id);
      if (response.success) {
        toast.success(response.message);
        getCategories();  // Refresh the categories list after deletion
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Drawer
        title="Create Category"
        className="drawer-display shadow-warning"
        width={520}
        closable={true}
        onClose={onClose}
        open={open}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Popover title={buttonLoading ? "loading" : "Save Category"}>
              <Button key="submit" onClick={() => form.submit()} icon={<SaveOutlined />} loading={buttonLoading} />
            </Popover>
            <Popover title="View all categories">
              <Button type="primary" onClick={showChildrenDrawer} icon={<EyeOutlined />} />
            </Popover>
          </div>
        }
      >
        <Form form={form} onFinish={onFinish} className="form-centered">
          <Row justify="center" align="middle" className="form-container">
            <Col span={24}>
              <Form.Item
                label={<span className="form-label">Category Name</span>}
                name="categoryName"
                className="form-item-centered"
                rules={[
                  {
                    required: true,
                    message: "Please enter category Name!",
                  },
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Drawer
          title="All Categories"
          width={320}
          closable={true}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
        >
          <Row gutter={[16, 16]}>
            {categories.length > 0 ? (
              categories.map((category) => (
                <Col span={24} key={category._id}>
                  <Card
                    className="shadow border-warning"
                    actions={[
                      <DeleteOutlined
                        key="delete"
                        onClick={() => confirmDelete(category._id)}
                      />,
                    ]}
                  >
                    <Card.Meta
                      title={category.categoryName}
                      description={
                        <>
                          <p>Created by: {category.createdBy?.firstName || 'Unknown'} {category.createdBy?.lastName || 'Unknown'}</p>
                          <p>Created on: {moment(category.createdAt).format('YYYY-MM-DD hh:mm A')}</p>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))
            ) : (
              <div className="flex text-center">
                <h1 className="text-primary text-xl">
                  You have no categories
                </h1>
              </div>
            )}
          </Row>
        </Drawer>
      </Drawer>
    </>
  );
};

export default AddCategoryDrawer;
