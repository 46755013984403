import { Button, Col, Input, Modal, Row, Select } from "antd";
import React from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import { AddMemberToProject } from "../../apicalls/projects";

const MembersForm = ({
  showModal,
  handleClose,
  reloadData,
  project,
  setRole,
  role,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const emailExists = project.members.find(
        (member) => member.user.email === values.email
      );
      if (emailExists) {
        throw new Error("User is already a member of this project");
      } else {
        dispatch(setLoading(true));
        const response = await AddMemberToProject({
          projectId: project._id,
          email: values.email,
          role: values.role,
        });
        dispatch(setLoading(false));
        if (response.success) {
          toast.success(response.message);
          reloadData(); // Reload data if necessary
          handleClose(); // Close the modal
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  return (
    <Modal
      title="ADD MEMBER"
      open={showModal}
      onCancel={handleClose}
      footer={null}
    >
      <Form form={form} onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: "Please select role" }]}
            >
              <Select
                placeholder="Select role"
                onChange={(value) => setRole(value)}
                value={role}
              >
                <Select.Option value="Admin">Admin</Select.Option>
                <Select.Option value="Employee">Employee</Select.Option>
                <Select.Option value="Finance">Finance</Select.Option>
                <Select.Option value="Credit recorvery">Credit recovery</Select.Option>
                <Select.Option value="Projects">Projects</Select.Option>
                <Select.Option value="Logistics">Logistics</Select.Option>
                <Select.Option value="Community manager">Community manager</Select.Option>
                <Select.Option value="Customer Service">Customer Service</Select.Option>
                <Select.Option value="Sales">Sales</Select.Option>
                <Select.Option value="IOT">IOT</Select.Option>
                <Select.Option value="Software Developer">Software Developer</Select.Option>
                <Select.Option value="Human Reasources">Human Reasources</Select.Option>
                <Select.Option value="Digital Marketing">Digital Marketing</Select.Option>
                <Select.Option value="Engineer">Engineer</Select.Option>
                <Select.Option value="Operations">Operations</Select.Option>
                <Select.Option value="Visuals">Visuals</Select.Option>
                <Select.Option value="Advertising">Advertising</Select.Option>
                <Select.Option value="Intern">Intern</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Member
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MembersForm;
