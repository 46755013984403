import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Button,
  Row,
  Col,
  Typography,
  Space,
  Card,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { UpdateProduct, DeleteProductImage } from "../../apicalls/product";
import { toast } from "react-hot-toast";
import { GetAllCategories } from "../../apicalls/category";
import { GetAllDiscounts } from "../../apicalls/discount";
import { setLoading, setButtonLoading } from "../../redux/loadersSlice";
import { useDispatch, useSelector } from "react-redux";
import { GetProjectById } from "../../apicalls/projects";
import { useParams } from "react-router-dom";
import "./editproduct.css";

const { Option } = Select;
const { Text } = Typography;

const EditProductModal = ({ showModal, handleClose, reloadData, product }) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const [categories, setCategories] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const [project, setProject] = useState(null);
  const { user } = useSelector((state) => state.users);
  const { buttonLoading } = useSelector((state) => state.loaders);
  const [categoryNamesToIds, setCategoryNamesToIds] = useState({});
  const [discountCodesToIds, setDiscountCodesToIds] = useState({});

  useEffect(() => { 
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  const fetchCategories = async () => {
    try {
      const response = await GetAllCategories({ project: project?._id });
      if (response.success) {
        setCategories(response.data);
        const categoryMap = {};
        response.data.forEach((category) => {
          categoryMap[category.categoryName] = category._id;
        });
        setCategoryNamesToIds(categoryMap);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchDiscounts = async () => {
    try {
      const response = await GetAllDiscounts({ project: project?._id });
      if (response.success) {
        setDiscounts(response.data);
        const discountMap = {};
        response.data.forEach((discount) => {
          discountMap[discount.discountCode] = discount._id;
        });
        setDiscountCodesToIds(discountMap);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (project) {
      fetchCategories();
      fetchDiscounts();
    }
  }, [project]);

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        productName: product.productName,
        quantity: product.quantity,
        price: product.price,
        productSize: product.productSize,
        productColor: product.productColor,
        categories: product.categories.map((category) => category._id),
        discounts: product.discounts.map((discount) => discount._id),
        productDescription: product.productDescription,
      });

      setImages(product.attachments || []);
    }
  }, [form, product]);

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));
      const formData = new FormData();
  
      // Append form values to formData
      Object.entries(values).forEach(([key, value]) => {
        if (key !== "categories" && key !== "discounts") {
          formData.append(key, value);
        }
      });
  
      // Append categories and discounts
      formData.append("categories", JSON.stringify(values.categories));
      formData.append("discounts", JSON.stringify(values.discounts));
  
      // Append other necessary fields
      formData.append("updatedBy", user._id);
      formData.append("project", project._id);  // Ensure the project ID is appended
      files.forEach((file) => formData.append("attachments", file));
  
      // Log the form data before sending
      console.log("Form data being sent:", values);
      console.log("FormData entries:", Array.from(formData.entries()));
  
      const productId = product._id;
  
      const response = await UpdateProduct(productId, formData);
      if (response.success) {
        toast.success("Product updated successfully!");
        form.resetFields(); // Reset the form fields
        setFiles([]); // Clear the file list
        setImages([]); // Clear the images list
        handleClose();
        reloadData();
      } else {
        throw new Error(response.message);
      }
      console.log('Response:', response.data);
      
      dispatch(setButtonLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };
  
  
  const handleBeforeUpload = (file) => {
    setFiles((prevFiles) => [...prevFiles, file]);
    return false;
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
  };

  const handleDeleteImage = async (index) => {
    const imageToDelete = images[index];
    try {
      await DeleteProductImage(product._id, imageToDelete);
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
      toast.success("Image deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete image");
    }
  };

  return (
    <Modal
      title="Edit Product"
      visible={showModal}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
          Cancel
        </Button>,

        <Button
          key="submit"
        className="save-button-ath-color"
          onClick={() => form.submit()}
          loading={buttonLoading}
        >
          {buttonLoading ? "loading" : "Save Changes"}
        </Button>,
      ]}
      width={800}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Product Name"
              name="productName"
              rules={[
                { required: true, message: "Please enter product name!" },
              ]}
            >
              <Input placeholder="Product name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Product Quantity"
              name="quantity"
              rules={[
                { required: true, message: "Please enter product quantity!" },
              ]}
            >
              <Input placeholder="Product quantity" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Product Price"
              name="price"
              rules={[
                { required: true, message: "Please enter product price!" },
              ]}
            >
              <Input placeholder="Product price" type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Product Size"
              name="productSize"
              rules={[
                { required: true, message: "Please enter product size!" },
              ]}
            >
              <Input placeholder="Product size" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Product Color"
              name="productColor"
              rules={[
                { required: true, message: "Please enter product color!" },
              ]}
            >
              <Input placeholder="Product color" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="discounts"
              label="Discounts"
              rules={[{ required: true, message: "Please select the discounts!" }]}
            >
              <Select mode="multiple" placeholder="Select discounts">
                {discounts.map((discount) => (
                  <Option key={discount._id} value={discount._id}>
                    {discount.discountCode}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col span={12}>
            <Form.Item
              name="categories"
              label="Categories"
              rules={[{ required: true, message: "Please select the categories!" }]}
            >
              <Select mode="multiple" placeholder="Select categories">
                {categories.map((category) => (
                  <Option key={category._id} value={category._id}>
                    {category.categoryName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="productDescription"
              rules={[{ required: true, message: "Please enter description!" }]}
            >
              <Input.TextArea rows={4} placeholder="Product description" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Upload Images">
          <Upload
            listType="picture-card"
            fileList={files.map((file) => ({
              uid: file.uid,
              name: file.name,
              status: "done",
              url: URL.createObjectURL(file),
            }))}
            beforeUpload={(file) => {
              handleBeforeUpload(file);
              return false;
            }}
            onRemove={handleRemove}
          >
            {files.length >= 8 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Select Files</div>
              </div>
            )}
          </Upload>
          {images && images.length > 0 && (
            <div>
              <Text>Images</Text>
              <Space wrap style={{ marginTop: 10 }}>
                {images.map((image, index) => (
                  <Card
                    key={index}
                    hoverable
                    cover={
                      <img
                        src={image}
                        alt={`image-${index}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    }
                    actions={[
                      <Button
                        danger
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size="small"
                        onClick={() => handleDeleteImage(index)}
                      />,
                    ]}
                    style={{ width: 120, height: 120, position: "relative" }}
                  />
                ))}
              </Space>
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditProductModal;
