import React, { useState, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Card } from "antd";
import AccountCard from "../../components/user/AccountCard";
import ProfileTab from "../../components/user/ProfileTab";
import { useSelector } from "react-redux";
import UserProfile from "../../components/user/UserProfile";
import ProjectForm from "../../components/user/ProjectForm";
import toast from "react-hot-toast"; // Import toast

const Account = () => {
  const { user } = useSelector((state) => state.users);

  const [modalVisible, setModalVisible] = useState(false);
  const [projectCreated, setProjectCreated] = useState(false);
  const [activeTab, setActiveTab] = useState("account");
  const formRef = useRef(null); // Define formRef using useRef

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    formRef.current
      .validateFields()
      .then((values) => {
        formRef.current.submit();
        console.log("Form values:", values);
        setProjectCreated(true);
        // localStorage.setItem('projectCreated', 'true'); // Store projectCreated status in local storage
        setModalVisible(false);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const reloadData = () => {
    // Define or import your reloadData function here
    console.log("Reloading data...");
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <main className="col-md px-md-4">
            <h2 className="mt-5">My Account</h2>
            <ProfileTab activeTab={activeTab} onChange={handleTabChange} />
            {activeTab === "account" ? (
              <>
                <AccountCard />
                {/* {!projectCreated && localStorage.getItem('projectCreated') !== 'true' && ( */}
                <div className="col">
                  <Card
                    className="card"
                    hoverable
                    onClick={showModal}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "40px",
                    }}
                  >
                    <PlusOutlined
                      style={{
                        fontSize: "42px",
                        cursor: "pointer",
                        color: "blue",
                      }}
                    />
                    <p>Create New Project</p>
                  </Card>
                </div>
                {/* )} */}
                <Modal
                  title="Add Project"
                  visible={modalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <ProjectForm ref={formRef} reloadData={reloadData} />
                </Modal>
              </>
            ) : (
              <UserProfile />
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default Account;
