import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Upload,
  Row,
  Col,
  Space,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { UpdateContact } from "../../apicalls/contact";
// import { getAntdFormInputRules } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddNotification } from "../../apicalls/notifications";
import { setButtonLoading, setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import "./editcontact.css";

// const { Option } = Select;

const EditContactModal = ({
  showModal,
  handleClose,
  reloadData,
  contact,
  project,
}) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const { user } = useSelector((state) => state.users);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const { buttonLoading } = useSelector((state) => state.loaders);

  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        contactFirstName: contact.contactFirstName || "",
        contactLastName: contact.contactLastName || "",
        contactSource: contact.contactSource || "",
        gender: contact.gender || "",
        contactEmail: contact.contactEmail || "",
        contactAddress: contact.contactAddress || "",
        occupation: contact.occupation || "",
        phoneNumbers: contact.phoneNumbers[0] || "", // Set the first phone number
        additionalPhoneNumbers: contact.phoneNumbers.slice(1).join("\n") || "", // Set additional phone numbers joined by newline
        assignedTo: contact.assignedTo || "",
        location: {
          state: contact.location.state || "",
          country: contact.location.country || "",
        },
      });
      setImages(contact.attachments || []);
      setCountry(contact.location.country || "");
      setState(contact.location.state || "");
    }
  }, [form, contact]);


  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));
      const { phoneNumbers, additionalPhoneNumbers, ...restValues } = values;

      const updatedValues = {
        ...restValues,
        phoneNumbers: [phoneNumbers, ...additionalPhoneNumbers.split("\n").filter(Boolean)], // Combine main and additional phone numbers
      };

      const updatedContact = {
        ...contact,
        ...updatedValues,
        updatedBy: user._id,
      };

      const response = await UpdateContact(updatedContact);

      if (response.success) {
        toast.success(response.message);
        if (project) {
          await AddNotification({
            title: `Contact "${contact.contactName}" in ${project.name} has been updated by ${contact.createdBy.firstName} ${contact.assignedBy.lastName} to ${values.status}`,
            user: contact.assignedTo,
            onClick: `/contact/detail/${projectId}/${response.data._id}`,
            description: values.description,
            taskName: values.taskName,
          });
        }
        reloadData();
        handleClose();
      } else {
        throw new Error(response.message);
      }
      dispatch(setButtonLoading(false));
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };


  return (
    <Modal
      title="Edit Contact"
      open={showModal}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
          Cancel
        </Button>,
        <Button
        key="submit"
        className="save-button-ath-color"
        onClick={() => form.submit()}
        loading={buttonLoading}
      >
        {buttonLoading ? "loading" : "Save Changes"}
      </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>First Name</span>}
                name="contactFirstName"
                className="form-col"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input style={{ width: "170px" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Last Name</span>}
                name="contactLastName"
                className="form-col"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input style={{ width: "170px" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Source</span>}
                name="contactSource"
                className="form-col"
              >
                <Input style={{ width: "170px" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Gender</span>}
                name="gender"
                className="form-col"
                initialValue={["male", "female", "others"]}
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Gender"
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                    { value: "others", label: "Others" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Email Address</span>}
                name="contactEmail"
                className="form-col"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                  {
                    required: true,
                    message: "Please enter customer email!",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Email"
                  style={{ width: "170px" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Phone number</span>}
                name="phoneNumbers"
                className="form-col"
                rules={[
                  { required: true, message: "Please enter phone number!" },
                ]}
              >
                <Input placeholder="Phone Number" style={{ width: "170px" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Country</span>}
                name={["location", "country"]}
                className="form-col"
              >
                <CountryDropdown
                  value={country}
                  onChange={(val) => setCountry(val)}
                  priorityOptions={["US", "GB", "CA"]}
                  style={{ width: "170px" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>State</span>}
                name={["location", "state"]}
                className="form-col"
              >
                <RegionDropdown
                  country={country}
                  value={state}
                  onChange={(val) => setState(val)}
                  style={{ width: "170px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Occupation</span>}
                name="occupation"
                className="form-col"
              >
                <Input placeholder="Occupation" style={{ width: "170px" }} />
              </Form.Item>
            </Col>

      <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Work Phone</span>}
                name="additionalPhoneNumbers"
                className="form-col"
              >
                <Input.TextArea
                  placeholder="Work Phone"
                  style={{ width: "170px" }}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={<span style={{ fontSize: "12px" }}>Address</span>}
            name="contactAddress"
            className="form-col"
          >
            <Input.TextArea placeholder="Address" style={{ width: "380px" }} />
          </Form.Item>

          <Form.Item label="Contact Images" className="form-col">
            <Upload
              multiple
              fileList={files}
              onChange={({ fileList }) => {
                setFiles(fileList);
                form.setFieldsValue({ attachments: fileList });
              }}
              beforeUpload={(file) => {
                setFiles([...files, file]);
                return false;
              }}
            >
              <Button icon={<PlusOutlined />} />
            </Upload>
            <Space direction="vertical">
              {images.map((image, index) => (
                <Space align="baseline" key={index}>
                  <img
                    src={image}
                    alt="contact"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      const newImages = [...images];
                      newImages.splice(index, 1);
                      setImages(newImages);
                    }}
                  />
                </Space>
              ))}
            </Space>
          </Form.Item>
        </>
      </Form>
    </Modal>
  );
};

export default EditContactModal;
