import moment from "moment";

export const getAntdFormInputRules = [
  {
    required: true,
    message: "Required",
  },
];

export const getDateFormat = (time, formatType = "datetime") => {
  if (!time) return "N/A";

  const date = new Date(time);

  if (formatType === "datetime") {
    return moment(date).format("MMMM Do YYYY, h:mm A");
  } else if (formatType === "time") {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  } else {
    return "N/A";
  }
};
