import React, { useState, useEffect } from "react";
import { Column, Bar, Line } from "@ant-design/plots";
import { Button, Card, Col, Row, Statistic } from "antd";
import "./usercomponents.css";

const DataChart = () => {
  // Data for the Column Chart
  const columnChartData = [
    {
      category: "Furniture & Appliances",
      deals: 38,
    },
    {
      category: "Grains & Staples",
      deals: 52,
    },
    {
      category: "Fresh Fruits",
      deals: 61,
    },
    {
      category: "Beauty & Personal Care",
      deals: 145,
    },
    {
      category: "Baby & Maternity",
      deals: 48,
    },
    {
      category: "Imported Foods",
      deals: 38,
    },
    {
      category: "Food & Beverages",
      deals: 38,
    },
    {
      category: "Home Cleaning",
      deals: 38,
    },
  ];

  // Data for the Bar Chart
  const barChartData = [
    {
      year: "1951",
      value: 38,
    },
    {
      year: "1952",
      value: 52,
    },
    {
      year: "1956",
      value: 61,
    },
    {
      year: "1957",
      value: 145,
    },
    {
      year: "1958",
      value: 48,
    },
  ];

  // Data for the Line Chart
  const lineChartData = [
    {
      year: "1991",
      value: 3,
    },
    {
      year: "1992",
      value: 4,
    },
    {
      year: "1993",
      value: 3.5,
    },
    {
      year: "1994",
      value: 5,
    },
    {
      year: "1995",
      value: 4.9,
    },
    {
      year: "1996",
      value: 6,
    },
    {
      year: "1997",
      value: 7,
    },
    {
      year: "1998",
      value: 9,
    },
    {
      year: "1999",
      value: 13,
    },
  ];

  const [stackedColumnData, setStackedColumnData] = useState([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/antfincdn/mor%26R5yBI9/stack-group-column.json"
    )
      .then((response) => response.json())
      .then((json) => {
        const formattedData = json.map((item) => ({
          ...item,
          sex: item.sex === "male" ? "Boy" : "Girl", // Update gender labels
        }));
        setStackedColumnData(formattedData);
      })
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };

  // Configuration for the Column chart
  const columnChartConfig = {
    label: {
      position: "top", // or 'bottom' or other valid options
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  // Configuration for the Stacked Column chart
  const stackedColumnConfig = {
    data: stackedColumnData,
    xField: "product_type",
    yField: "order_amt",
    isGroup: true,
    isStack: true,
    seriesField: "product_sub_type",
    groupField: "sex",
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.product_sub_type} ${
          datum.sex === "male" ? "Boy" : "Girl"
        }`, // Update gender labels
        value: datum.order_amt,
        productType: "Product Type",
        orderAmt: "Order Amount",
        productSubType: "Product Sub Type",
        gender: "Gender",
      }),
    },
  };

  // Configuration for the Line chart
  const lineChartConfig = {
    data: lineChartData,
    xField: "year",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };

  return (
    <React.Fragment>
    <div className="blur-background">
    <div className="blur-cover"></div>
    <div className="coming-soon-text">Chart metrics coming soon</div> 
      <Row gutter={[4, 4]}>
        <Col span={12}>
          <h5 className="card-title mb-2">Deals</h5>
          <Card bordered={true}>
            <Column
              {...columnChartConfig}
              data={columnChartData}
              xField="category"
              yField="deals"
            />
          </Card>
        </Col>

        <Col span={12}>
          <h5 className="card-title mb-2">Contacts</h5>
          <Card bordered={true}>
            <Bar
              {...columnChartConfig}
              data={barChartData}
              xField="value"
              yField="year"
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <h5 className="card-title mb-2 mt-2">Task</h5>
          <Card bordered={true}>
            <Column {...stackedColumnConfig} />
          </Card>
        </Col>

        <Col span={12}>
          <h5 className="card-title mb-2 mt-2">Calls</h5>
          <Card bordered={true}>
            {/* Integrate the Line chart */}
            <Line {...lineChartConfig} />
          </Card>
        </Col>
      </Row>
      </div>
      </React.Fragment>
  );
};

export default DataChart;
