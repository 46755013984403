import React from "react";
import "./auth.css";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"; // Update import
import { ResetPassword } from "../../apicalls/users";
import { setButtonLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";

const ResetPasswordComponent = () => {
  const { buttonLoading } = useSelector((state) => state.loaders);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  // Log the token received from the URL parameter
  // console.log("Token received in frontend:", token);

  const onFinish = async (values) => {
    try {
      if (values.password !== values.confirmPassword) {
        return;
      }
      dispatch(setButtonLoading(true));
      const response = await ResetPassword(token, values.password);
      dispatch(setButtonLoading(false));
      if (response.success) {
        toast.success(response.message);
        navigate("/login");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <div>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="display-5 fw-bold lh-1 text-body-emphasis mb-3">
              Reset your password
            </h1>
            <p className="col-lg-10 fs-4">One more step and you're all set!</p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <Form
              className="p-4 p-md-5 border rounded-3 bg-body-transparent bg-transparent shadow"
              onFinish={onFinish}
            >
              <div className="form-floating mb-3">
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </div>
              <div className="form-floating mb-3">
                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </div>
              <div className="d-flex justify-content-center ">
                <Button
                  type="primary"
                  className="btn-lg ath-auth-button shadow"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  {buttonLoading ? "loading" : "Reset"}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
