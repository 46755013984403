import React, { useState, useEffect } from "react";
import Sidebar from "../../components/user/Sidebar";
import { Button, Card, Col, Input, Popconfirm, Row, Image, Form, Popover } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EditContactModal from "../../components/editcontact/EditContactModal";
import toast from "react-hot-toast";
import { GetContactById, DeleteContact } from "../../apicalls/contact";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetProjectById } from "../../apicalls/projects";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const ContactDetails = () => {
  const { contactId, projectId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const { user } = useSelector((state) => state.users) || {};
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [contact, setContact] = useState(null);
  const [projectMembers, setProjectMembers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => { 
    fetchContactDetails();
    fetchProjectMembers();
  }, []);

  const fetchContactDetails = async () => {
    try {
      const response = await GetContactById(contactId);
      if (response.success) {
        console.log("Contact details:", response.data); // Log task details
        setContact(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };

  const fetchProjectMembers = async () => {
    try {
      const projectResponse = await GetProjectById(projectId);
      if (projectResponse.success) {
        setProjectMembers(projectResponse.data.members);
      } else {
        throw new Error(projectResponse.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await DeleteContact(contactId);
      if (response.success) {
        toast.success(response.message);
        // Redirect to contact listing page or any other desired action after successful deletion
        navigate(`/contact/project/${projectId}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const isOwnerOrAdmin = () => {
    // Check if projectMembers is defined and user is an owner or admin based on project members
    return (
      projectMembers &&
      user &&
      projectMembers.some(
        (member) =>
          member.user._id === user._id &&
          (member.role === "owner" || member.role === "Admin")
      )
    );
  };

  const isEmployeeAndAssignedToCurrentUser = () => {
    // Check if projectMembers is defined, user is defined and is an Employee, and task is assigned to the current user
    return (
      projectMembers &&
      user &&
      user.role === "Employee" &&
      contact &&
      contact.assignedTo &&
      contact.assignedTo._id === user._id &&
      projectMembers.some(
        (member) => member.user._id === user._id && member.role === "Employee"
      )
    );
  };

  const shouldShowDeleteButton = () => {
    // Show delete button if user is owner or admin, or if user is employee and task is assigned to them
    return isOwnerOrAdmin() || isEmployeeAndAssignedToCurrentUser();
  };

    // Define breakpoints
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  
    const getButtonStyle = () => {
      if (isMobile) {
        return { marginRight: "8px" };
      } else if (isTabletOrMobile) {
        return { marginRight: "16px" };
      } else {
        return { marginRight: "60px", backgroundColor:"#082e67", color:"#fff" };
      }
    };
  
    const getAdditionalButtonStyle = () => {
      if (isMobile) {
        return { marginLeft: "8px" };
      } else if (isTabletOrMobile) {
        return { marginLeft: "16px" };
      } else {
        return { marginLeft: "60px",  backgroundColor:"red", color:"#fff" };
      }
    };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <Sidebar projectId={projectId} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">
                {contact
                  ? `${contact.contactFirstName} ${contact.contactLastName}`
                  : "Contact Name"}
              </h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <Popover
                 title="Edit Contact">
                  <Button
                    type="default"
                    style={getButtonStyle()}
                    icon={<EditOutlined />}
                    onClick={handleShowModal}
                  />
                </Popover>


                {shouldShowDeleteButton() && (
                  <Popconfirm
                    title="Delete call"
                    description="Are you sure to delete this contact?"
                    onConfirm={confirmDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                  
                      <Button
                        type="danger"
                        style={getAdditionalButtonStyle()}
                        icon={<DeleteOutlined />}
                    
                      />
               
                  </Popconfirm>
                )}
              </div>
              <EditContactModal
                showModal={showModal}
                handleClose={handleCloseModal}
                reloadData={fetchContactDetails}
                contact={contact}
                projectMembers={projectMembers}
              />
            </div>

            <div className="container">
              <div className="mt-5 mt-4">
                <h4 className="h4 mt-3 mb-3">Contact infomation</h4>

                {/* Contact details */}

                <div className="col">
                  <Card
                    className="card"
                    hoverable
                    style={{
                      width: "100%",
                      textAlign: "start",
                      marginTop: "40px",
                    }}
                  >
                    {contact ? (
                      <>
                        <Form labelCol={{ span: 24 }}>
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Contact First Name:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={contact.contactFirstName}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Contact Last Name
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                
                                  value={contact.contactLastName}
                                  readOnly
                                
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Assigned To:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact && contact.assignedTo
                                      ? contact.assignedTo.firstName +
                                        " " +
                                        contact.assignedTo.lastName
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Contact Owner:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact && contact.createdBy
                                      ? contact.createdBy.firstName +
                                        " " +
                                        contact.createdBy.lastName
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Source
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={contact.contactSource} readOnly />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Last updated at:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact && contact.updatedAt
                                      ? moment(contact.updatedAt).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Gender:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={contact.gender} readOnly />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Email Address
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                              
                                  value={contact.contactEmail}
                                  readOnly
                                 
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Phone Number
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact && contact.phoneNumbers
                                      ? contact.phoneNumbers[0] // Access the first phone number directly
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Work Number
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact &&
                                    contact.phoneNumbers &&
                                    contact.phoneNumbers.length > 1
                                      ? contact.phoneNumbers[1] // Access the second phone number directly
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    State
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact && contact.location
                                      ? contact.location.state
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Country
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact && contact.location
                                      ? contact.location.country
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Occupation
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={contact.occupation} readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Address
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input.TextArea
                                  rows={4}
                                  value={contact.contactAddress}
                                  readOnly
                                  style={{ width: "400px" }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                          <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Last Updated By:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact && contact.updatedBy
                                      ? contact.updatedBy.firstName +
                                        " " +
                                        contact.updatedBy.lastName
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>

                            <Col span={24}>
                              <Form.Item
                                label="Contact Images"
                                className="form-col"
                              >
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {contact.attachments &&
                                    contact.attachments.map((image, index) => (
                                      <Image
                                        key={index}
                                        width={100}
                                        height={100}
                                        src={image}
                                        style={{
                                          marginRight: "10px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                    ))}
                                </div>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    ) : (
                      <p>No contact details found.</p>
                    )}
                  </Card>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
