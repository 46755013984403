import { apiRequest } from ".";


export const CreateDiscount = async (discount) =>
  apiRequest("post", "/api/discounts/create-discount", discount);

export const GetAllDiscounts = async (filters) =>
  apiRequest("post", "/api/discounts/get-all-discounts", filters);

export const GetDiscountById = async (discountId) =>
  apiRequest("get", `/api/discounts/get-discount-by-id/${discountId}`);

export const UpdateDiscount = async (discount) =>
  apiRequest("post", "/api/discounts/update-discount", discount);

export const DeleteDiscount = async (id) =>
  apiRequest("post", "/api/discounts/delete-discount", { _id: id });