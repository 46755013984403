import { apiRequest } from ".";



export const CreateCategory = async (category) =>
  apiRequest("post", "/api/category/create-category", category);

export const GetAllCategories = async (filters) =>
  apiRequest("post", "/api/category/get-all-categories", filters);

export const GetCategoryById = async (categoryId) =>
  apiRequest("get", `/api/category/get-Category-by-id/${categoryId}`);

export const UpdateCategory = async (category) =>
  apiRequest("post", "/api/category/update-category", category);

export const DeleteCategory = async (id) =>
  apiRequest("post", "/api/category/delete-category", { _id: id });

