import React, { useEffect, useState } from "react";
import Sidebar from "../../components/user/Sidebar";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Mentions,
  Popover,
  Row,
  message,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditProductModal from "../../components/editproduct/EditProductModal";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  GetProductById,
  AddProductComment,
  GetCommentsByProductId,
} from "../../apicalls/product";
import { AddNotification } from "../../apicalls/notifications";
import { GetProjectById } from "../../apicalls/projects";

const ProductDetails = () => {
  const { projectId, productId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [project, setProject] = useState(null);
  const dispatch = useDispatch();
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [product, setProduct] = useState(null);
  const { user } = useSelector((state) => state.users) || {};
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  useEffect(() => { 
    fetchProductDetails();
    fetchProjectMembers();
    fetchComments();
  }, []);

  const fetchProductDetails = async () => {
    try {
      const response = await GetProductById(productId);
      if (response.success) {
        console.log("Product details:", response.data);
        setProduct(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [productId]);
  
  const fetchProjectMembers = async () => {
    try {
      const projectResponse = await GetProjectById(projectId);
      if (projectResponse.success) {
        setProject(projectResponse.data);
        setProjectMembers(
          projectResponse.data.members.map((member) => ({
            ...member,
            user: {
              ...member.user,
              _id: member.user._id,
            },
          }))
        );
      } else {
        throw new Error(projectResponse.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await GetCommentsByProductId(productId, projectId);
      if (response.success) {
        setComments(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleAddProductComment = async () => {
    if (!newComment.trim()) return;

    try {
      const commentData = {
        productId,
        projectId,
        comment: newComment,
        userId: user._id,
      };
      const response = await AddProductComment(commentData);
      if (response.success) {
        setNewComment("");
        fetchComments(); // Reload comments after adding a new one
        toast.success("Comment added successfully");

        // Check if the product has a project associated with it
        if (project) {
          // Notify the project members
          for (const member of projectMembers) {
            // Exclude the user who added the comment from being mentioned
            if (member.user._id !== user._id) {
              // Modify the description to replace user ID with user's name
              const modifiedDescription = newComment.replace(
                new RegExp(`@${user._id}`, "g"),
                `@${user.firstName} ${user.lastName}`
              );

              await AddNotification({
                title: `Product UPDATE! @"${user.firstName} ${user.lastName}" commented on a product in ${project.name}`,
                user: member.user._id,
                description: modifiedDescription, // Use modified description
                onClick: `/product/detail/${projectId}/${productId}`, // Redirect to task details page
              });
            }
          }
        }
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getMemberNameById = (id) => {
    const member = projectMembers.find((member) => member.user._id === id);
    return member ? `${member.user.firstName} ${member.user.lastName}` : id;
  };

  const formatCommentWithMentions = (comment) => {
    const mentionPattern = /@(\w+)/g;
    return comment.replace(mentionPattern, (match, userId) => {
      const name = getMemberNameById(userId);
      return `@${name}`;
    });
  };

  // Define breakpoints
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px", backgroundColor: "#082e67", color: "#fff" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px", backgroundColor: "red", color: "#fff" };
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <Sidebar projectId={projectId} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">
                {" "}
                {product ? `${product.productName}` : "Product Name"}
              </h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <Popover title="Edit Product">
                  <Button
                    type="default"
                    style={getButtonStyle()}
                    icon={<EditOutlined />}
                    onClick={handleShowModal}
                  />
                </Popover>
              </div>
              <EditProductModal
                showModal={showModal}
                handleClose={handleCloseModal}
                reloadData={fetchProductDetails}
                product={product}
                projectMembers={projectMembers}
              />
            </div>

            <div className="container">
              <div className="mt-5 mt-4">
                <h4 className="h4 mt-3 mb-3">Product infomation</h4>

                {/* Product details */}

                <div className="col">
                  <Card
                    className="card"
                    hoverable
                    style={{
                      width: "100%",
                      textAlign: "start",
                      marginTop: "40px",
                    }}
                  >
                    {product ? (
                      <>
                        <Form labelCol={{ span: 24 }}>
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Product Name:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={product.productName} readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Product quantity
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={product.quantity} readOnly />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Product price:
                                  </span>
                                } 
                                className="form-col"
                              >
                             <Input value={`₦${product.price}`} readOnly />

                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Product size:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={product.productSize} readOnly />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                          

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Product sold:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={product.sold} readOnly />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Categories:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    product &&
                                    product.categories &&
                                    product.categories
                                      .map((category) => category.categoryName)
                                      .join(", ")
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Discount:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    product &&
                                    product.discounts &&
                                    product.discounts
                                      .map(
                                        (discount) =>
                                          `${discount.discountCode} (${discount.discountPercentage}%)`
                                      )
                                      .join(", ")
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Product Color:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={product.productColor} readOnly />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Product Description:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input.TextArea
                                  rows={4}
                                  value={product.productDescription}
                                  readOnly
                                  style={{ width: "400px" }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Created By:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    product && product.createdBy
                                      ? product.createdBy.firstName +
                                        " " +
                                        product.createdBy.lastName
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Last Updated By
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    product && product.updatedBy
                                      ? product.updatedBy.firstName +
                                        " " +
                                        product.updatedBy.lastName
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Last updated at:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    product && product.updatedAt
                                      ? moment(product.updatedAt).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Created At:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    product && product.createdAt
                                      ? moment(product.createdAt).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={24}>
                              <Form.Item
                                label="Product Images"
                                className="form-col"
                              >
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {product.attachments &&
                                    product.attachments.map((image, index) => (
                                      <Image
                                        key={index}
                                        width={200}
                                        height={200}
                                        src={image}
                                        style={{
                                          objectFit: "cover",
                                          boxShadow:
                                            "0 4px 8px rgba(0, 0, 0, 0.1)",
                                          margin: "10px",
                                        }}
                                      />
                                    ))}
                                </div>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>

                        {/* Product Comment Section */}
                        <div className="mt-5">
                          <h4 className="h4 mt-3 mb-3">Products Comments</h4>
                          <div className="col">
                            {comments.map((comment, index) => (
                              <Card
                                key={index}
                                className="mb-3 comment-list shadow"
                              >
                                <p>
                                  <strong>
                                    {comment.user.firstName}{" "}
                                    {comment.user.lastName}
                                  </strong>{" "}
                                  commented:
                                </p>
                                <p>
                                  {formatCommentWithMentions(comment.comment)}
                                </p>
                                <p
                                  className="text-muted"
                                  style={{ fontSize: "12px" }}
                                >
                                  {moment(comment.createdAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </p>
                              </Card>
                            ))}
                          </div>
                          <div className="mt-4">
                            <Mentions
                              style={{ width: "50%" }}
                              value={newComment}
                              onChange={(value) => setNewComment(value)}
                              placeholder="Add a comment..."
                            >
                              {projectMembers.map((member) => (
                                <Mentions.Option
                                  key={member.user._id}
                                  value={`${member.user.firstName} ${member.user.lastName}`}
                                >
                                  {`${member.user.firstName} ${member.user.lastName}`}
                                </Mentions.Option>
                              ))}
                            </Mentions>
                            <div className="spa">
                              <Button
                                type="primary"
                                className="mt-2 comment-button"
                                onClick={handleAddProductComment}
                              >
                                Add Comment
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>No product details found.</p>
                    )}
                  </Card>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
