import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  TimePicker,
  Row,
  Col, 
  InputNumber,
} from "antd";
import { setLoading, setButtonLoading } from "../../redux/loadersSlice";
import { CreateCall } from "../../apicalls/call";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { GetProjectById } from "../../apicalls/projects";
import { GetAllContacts } from "../../apicalls/contact";
import "./addcall.css"

const { Option } = Select;

const AddCallModal = ({ handleClose, reloadData, initialCall, showModal }) => {
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users) || {};
  const [project, setProject] = useState(null);
  const { buttonLoading } = useSelector((state) => state.loaders);
  const [projectContacts, setProjectContacts] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [projectMembers, setProjectMembers] = useState(null);

  useEffect(() => {}, [initialCall]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
          setProjectMembers(projectResponse.data.members);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getContacts();
    }
  }, [project]);

  const getContacts = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetAllContacts({
        project: project?._id,
      });
      dispatch(setLoading(false));
      if (response.success) {
        setProjectContacts(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const onFinish = async (values) => {
    try {
      console.log("Form values:", values);
      dispatch(setButtonLoading(true));

      const {
        callType,
        callOutcome,
        callStatus,
        timeOfCall,
        callDescription,
        callDuration,
        callStartTime,
        callEndTime,
      } = values;

      let callId = null;
      if (initialCall) {
        callId = initialCall._id;
      }

      const data = {
        contact: contactId,
        project: project._id,
        callType: callType,
        callOutcome: callOutcome,
        callStatus: callStatus,
        timeOfCall: timeOfCall,
        callDescription: callDescription,
        callDuration: callDuration,
        callStartTime: callStartTime,
        callEndTime: callEndTime,
        createdBy: user._id, // store the user who created the call
        updatedBy: user._id, 
        callId: callId,
      };

      const response = await CreateCall(data);

      if (response.success) {
        reloadData();
        toast.success(response.message);

        handleClose();
      } else {
        toast.error(response.message);
      }

      dispatch(setButtonLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  return (
    <div>
      {/* Modal */}

      <Modal
        title="Log Call"
        open={showModal}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
            Cancel
          </Button>,
         <Button
         key="submit"
        className="save-button-ath-color"
         onClick={() => form.submit()}
         loading={buttonLoading}
       >
         {buttonLoading ? "loading" : "Save Changes"}
       </Button>,
        ]}
      >
        <Form form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Contact"
                  name="contact"
                  rules={[
                    { required: true, message: "Please select a contact!" },
                  ]}
                >
                  <Select
                    placeholder="Select contact"
                    onChange={(value) => setContactId(value)}
                    style={{ width: "170px" }}
                  >
                    {projectContacts?.map((contact) => (
                      <Option key={contact._id} value={contact._id}>
                        {`${contact.contactFirstName} ${contact.contactLastName} ${contact.contactEmail}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Call Type"
                  name="callType"
                  rules={[
                    { required: true, message: "Please select call type!" },
                  ]}
                >
                  <Select
                    style={{ width: "170px" }}
                    placeholder="Select Call Type"
                    options={[
                      { value: "inbound", label: "inbound" },
                      { value: "outbound", label: "outbound" },
                      { value: "missed", label: "missed" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ fontSize: "12px" }}>Call Outcome</span>}
                  name="callOutcome"
                  className="form-col"
                  rules={[
                    { required: true, message: "Please select call outcome!" },
                  ]}
                >
                  <Select
                    style={{ width: "170px" }}
                    placeholder="Select Call Outcome"
                    options={[
                      { value: "Not Reachable", label: "Not Reachable" },
                      { value: "Call Back", label: "Call Back" },
                      { value: "Call Drop", label: "Call Drop" },
                      { value: "Canceled", label: "Canceled" },
                      { value: "Bad Network", label: "Bad Network" },
                      { value: "Wrong Number", label: "Wrong Number" },
                      { value: "Call Completed", label: "Call Completed" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={<span style={{ fontSize: "12px" }}>Call Status</span>}
                  name="callStatus"
                  className="form-col"
                  rules={[
                    { required: true, message: "Please select call status!" },
                  ]}
                >
                  <Select
                    style={{ width: "170px" }}
                    placeholder="Call Status"
                    options={[
                      { value: "Following up", label: "Following up" },
                      { value: "Pending", label: "Pending" },
                      { value: "In progress", label: "In progress" },
                      { value: "Call completed", label: "Call completed" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Call Duration"
                  name="callDuration"
                  rules={[
                    { required: true, message: "Please enter call duration!" },
                  ]}
                >
                  <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Time of call"
                  name="timeOfCall"
                  rules={[
                    { required: true, message: "Please select time of call!" },
                  ]}
                >
                  <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Call Start Time"
                  name="callStartTime"
                  rules={[
                    {
                      required: true,
                      message: "Please enter call start time!",
                    },
                  ]}
                >
                  <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Call End Time"
                  name="callEndTime"
                  rules={[
                    { required: true, message: "Please enter call end time!" },
                  ]}
                >
                  <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <span style={{ fontSize: "12px" }}>Call Description</span>
                  }
                  name="callDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please enter call description!",
                    },
                  ]}
                >
                  <Input.TextArea rows={4} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </Modal>
    </div>
  );
};

export default AddCallModal;
