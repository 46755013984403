import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./navbar.css";
import { GetLoggedInUser } from "../../apicalls/users";
import { SetUser } from "../../redux/usersSlice";
import { Avatar, Menu, Drawer, Button } from "antd";
import {
  HomeOutlined,
  InfoCircleOutlined,
  DollarOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  UserAddOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

const Navbar = () => {
  const user = useSelector((state) => state.users.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDrawer, setShowDrawer] = useState(false);

  const getUser = async () => {
    try {
      const response = await GetLoggedInUser();
      if (response.success) {
        dispatch(SetUser(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <div>
      <nav className="navbar bg-transparent" aria-label="Navbar">
        <div className="container-fluid mb-5">
          <NavLink className="fw-bold text-decoration-none text-light" to="/">
            <img
              src="/assets/img/athbrand.png"
              alt=""
              className="img-fluid rounded-circle"
              style={{ width: "150px", height: "150px" }}
            />
          </NavLink>

          <Button
            className="d-lg-none"
            type="default"
            onClick={toggleDrawer}
            icon={<MenuUnfoldOutlined />}
          />

          {/* <Menu
            mode="horizontal"
            className="d-none d-lg-flex"
            style={{ flexGrow: 1, justifyContent: "center" }}
         
          >
            <Menu.Item key="home">
              <NavLink to="#">Home</NavLink>
            </Menu.Item>
            <Menu.Item key="about">
              <NavLink to="#">About</NavLink>
            </Menu.Item>
            <Menu.Item key="pricing">
              <NavLink to="#">Pricing</NavLink>
            </Menu.Item>
            <SubMenu key="ourProducts" title="Our Products">
              <Menu.Item key="ath_crm">ATH CRM</Menu.Item>
              <Menu.Item key="ath_crm_plus">ATH CRM Plus</Menu.Item>
              <Menu.Item key="ath_crm_business">ATH CRM Business</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="forums">Forums</Menu.Item>
              <Menu.Item key="documentations">Documentations</Menu.Item>
            </SubMenu>
          </Menu> */}

          <Menu
            mode="vertical"
            className="d-none d-lg-flex"
            style={{ justifyContent: "flex-end" }}
            ellipsis="false"
          >
            {user ? (
              <Menu.Item key="account">
                <NavLink to="/account">
                  <Avatar
                    src={user?.avatar?.url}
                    size={40}
                    style={{ marginBottom: "70px" }}
                  />
                </NavLink>
              </Menu.Item>
            ) : (
              <>
                <Menu.Item key="login">
                  <NavLink to="/login">
                    <button
                      className="btn btn-sm ath-auth-button mr-2 shadow"
                      type="submit"
                      style={{ marginRight: "8px", marginBottom: "70px" }}
                    >
                      Login
                    </button>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="register">
                  <NavLink to="/register">
                    <button
                      className="btn btn-sm ath-auth-button-2 shadow"
                      type="submit"
                      style={{ marginLeft: "8px", marginBottom: "70px" }}
                    >
                      Register
                    </button>
                  </NavLink>
                </Menu.Item>
              </>
            )}
          </Menu>
        </div>
      </nav>

      <Drawer
        placement="right"
        closable={false}
        onClose={closeDrawer}
        visible={showDrawer}
        bodyStyle={{ padding: 0 }}
      >
        <Menu
          mode="inline"
          onClick={closeDrawer}
          style={{ width: "100%", textAlign: "center" }}
        >
          {/* <Menu.Item key="home">
            <NavLink to="#">Home</NavLink>
          </Menu.Item>
          <Menu.Item key="about">
            <NavLink to="#">About</NavLink>
          </Menu.Item>
          <Menu.Item key="pricing">
            <NavLink to="#">Pricing</NavLink>
          </Menu.Item>
          <SubMenu key="ourProducts" title="Our Products">
            <Menu.Item key="ath_crm">ATH CRM</Menu.Item>
            <Menu.Item key="ath_crm_plus">ATH CRM Plus</Menu.Item>
            <Menu.Item key="ath_crm_business">ATH CRM Business</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="forums">Forums</Menu.Item>
            <Menu.Item key="documentations">Documentations</Menu.Item>
          </SubMenu> */}
          {!user && (
            <>
              <Menu
                mode="inline"
                onClick={closeDrawer}
                className="d-flex mt-5 mb-5"
              >
                <Menu.Item key="login">
                  <NavLink to="/login">
                    <button
                      className="btn btn-sm ath-auth-button shadow"
                      type="submit"
                    >
                      Login
                    </button>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="register">
                  <NavLink to="/register">
                    <button
                      className="btn btn-sm ath-auth-button-2 shadow"
                      type="submit"
                    >
                      Register
                    </button>
                  </NavLink>
                </Menu.Item>
              </Menu>
            </>
          )}
          {user && (
            <Menu.Item key="account">
              <NavLink to="/account">
                <Avatar
                  src={user?.avatar?.url}
                  size={40}
                  style={{ marginBottom: "30px", marginTop: "30px" }}
                />
              </NavLink>
            </Menu.Item>
          )}
          <Button
            onClick={closeDrawer}
            style={{ width: "20%", marginTop: "25px" }}
          >
            <CloseOutlined />
          </Button>
        </Menu>
      </Drawer>
    </div>
  );
};

export default Navbar;
