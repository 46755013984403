import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  TimePicker,
  Space,
  Row,
  Col,
} from "antd";
import "./editcall.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setButtonLoading } from "../../redux/loadersSlice";
import { UpdateCall } from "../../apicalls/call";
import toast from "react-hot-toast";
import { getDateFormat } from "../../utils/helpers";
import moment from "moment";
import "./editcall.css"

const { Option } = Select;

const EditCallModal = ({
  showModal,
  handleClose,
  reloadData,
  call,
  project,
}) => {
  const [form] = Form.useForm();

  const { projectId } = useParams();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { buttonLoading } = useSelector((state) => state.loaders);

  useEffect(() => {
    if (call) {
      // Set initial values of the form fields when task is available
      form.setFieldsValue({
        callType: call.callType,
        callOutcome: call.callOutcome,
        callStatus: call.callStatus,
        timeOfCall: moment(call.timeOfCall),
        callDescription: call.callDescription,
        callDuration: moment(call.callDuration),
        callStartTime: moment(call.callStartTime),
        callEndTime: moment(call.callEndTime),
      });
      
    }
  }, [call, form]);

  const onFinish = async (values) => {
    console.log("Form values:", values);
    try {
      dispatch(setButtonLoading(true));

      const updatedCall = {
        ...call,
        ...values,
        updatedBy: user._id,
      };

      const response = await UpdateCall(updatedCall);
      if (response.success) {
        toast.success(response.message);

        reloadData();
        handleClose(); // Close modal after successful update
      } else {
        throw new Error(response.message);
      }
      dispatch(setButtonLoading(false));
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  return (
    <Modal
      title="Update Call Log"
      open={showModal}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
          Cancel
        </Button>,
        <Button
        key="submit"
     className="save-button-ath-color"
        onClick={() => form.submit()}
        loading={buttonLoading}
      >
        {buttonLoading ? "loading" : "Save Changes"}
      </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 24 }} // Set labelCol to occupy the full width
      >
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Call Type"
                name="callType"
                rules={[
                  { required: true, message: "Please select call type!" },
                ]}
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Select Call Type"
                  options={[
                    { value: "inbound", label: "inbound" },
                    { value: "outbound", label: "outbound" },
                    { value: "missed", label: "missed" },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Call Outcome</span>}
                name="callOutcome"
                className="form-col"
                rules={[
                  { required: true, message: "Please select call outcome!" },
                ]}
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Select Call Outcome"
                  options={[
                    { value: "Not Reachable", label: "Not Reachable" },
                    { value: "Call Back", label: "Call Back" },
                    { value: "Call Drop", label: "Call Drop" },
                    { value: "Canceled", label: "Canceled" },
                    { value: "Bad Network", label: "Bad Network" },
                    { value: "Wrong Number", label: "Wrong Number" },
                    { value: "Interested", label: "Interested" },
                    { value: "Not Interested", label: "Not Interested" },

                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Call Status</span>}
                name="callStatus"
                className="form-col"
                rules={[
                  { required: true, message: "Please select call status!" },
                ]}
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Call Status"
                  options={[
                    { value: "Following up", label: "Following up" },
                    { value: "Pending", label: "Pending" },
                    { value: "In progress", label: "In progress" },
                    { value: "Call completed", label: "Call completed" },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Call Duration"
                name="callDuration"
                rules={[
                  { required: true, message: "Please enter call duration!" },
                ]}
              >
                <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Call Start Time"
                name="callStartTime"
                rules={[
                  {
                    required: true,
                    message: "Please enter call start time!",
                  },
                ]}
              >
                <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Call End Time"
                name="callEndTime"
                rules={[
                  { required: true, message: "Please enter call end time!" },
                ]}
              >
                <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Time of call"
                name="timeOfCall"
                rules={[
                  { required: true, message: "Please select time of call!" },
                ]}
              >
                <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px" }}>Call Description</span>
                }
                name="callDescription"
                
                rules={[
                  {
                    required: true,
                    message: "Please enter call description!",
                  },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};

export default EditCallModal;
