import React from "react";
import "./features.css";

const Features = () => {
  return (
    <div>
      <div className="container px-4 py-5 ath-custom-margin" id="custom-cards">
        <h2 className="ath-feature-text">Easy-to-Use CRM Tools</h2>
        <p className="ath-content-text">
          Our CRM tools are meticulously crafted for modern businesses in
          Nigeria and beyond.
          <br />
          Enhance teamwork and daily productivity with our comprehensive app
          features.
          <br />
          Sign up for free today to start optimizing your operations!
        </p>

        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden ath-feature-card-bg rounded-4 shadow-lg"
              // style={{ backgroundImage: 'url("unsplash-photo-1.jpg")' }}
            >
              <div className="d-flex flex-column h-100 p-5 pb-3 ath-feature-text text-shadow-1">
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Contacts Management
                </h3>
                <ul className="d-flex list-unstyled mt-auto">
                  <li className="me-auto">
                    <i
                      className="bi me-2 bi-people-fill"
                      width="1em"
                      height="1em"
                    />
                  </li>
                  <li className="d-flex align-items-center me-3">
                    <svg className="bi me-2" width="1em" height="1em">
                      <use xlinkHref="#geo-fill" />
                    </svg>

                    <small>modules</small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden ath-feature-card-bg rounded-4 shadow-lg"
              // style={{ backgroundImage: 'url("unsplash-photo-2.jpg")' }}
            >
              <div className="d-flex flex-column h-100 p-5 pb-3 ath-feature-text text-shadow-1">
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Tasks Management
                </h3>
                <ul className="d-flex list-unstyled mt-auto">
                  <li className="me-auto">
                    <i
                      className="me-2 bi bi-list-task"
                      width="1em"
                      height="1em"
                    />
                  </li>

                  <li className="d-flex align-items-center me-3">
                    <svg className="bi me-2" width="1em" height="1em">
                      <use xlinkHref="#geo-fill" />
                    </svg>
                    <small>modules</small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden ath-feature-card-bg rounded-4 shadow-lg"
              // style={{ backgroundImage: 'url("unsplash-photo-3.jpg")' }}
            >
              <div className="d-flex flex-column h-100 p-5 pb-3 ath-feature-text text-shadow-1">
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Tickets Management
                </h3>
                <ul className="d-flex list-unstyled mt-auto">
                  <li className="me-auto">
                    <i
                      className="me-2 bi bi-ticket-detailed"
                      width="1em"
                      height="1em"
                    />
                  </li>
                  <li className="d-flex align-items-center me-3">
                    <svg className="bi me-2" width="1em" height="1em">
                      <use xlinkHref="#geo-fill" />
                    </svg>
                    <small>Modules</small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
