import React, { useEffect, useState } from "react";
import Sidebar from "../../components/user/Sidebar";
import {
  Table as AntTable,
  Button,
  Space,
  Modal,
  Select,
  Row,
  Col,
  Popover,
  DatePicker,
} from "antd";
import {
  PlusOutlined,
  UserAddOutlined,
  EyeOutlined,
  FilterOutlined, 
  ExportOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import AddTaskModal from "../../components/addtask/AddTaskModal";
import { NavLink, useParams } from "react-router-dom"; // Import Link
import { setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { GetAllTasks } from "../../apicalls/task";
import { getDateFormat } from "../../utils/helpers";
import { GetProjectById } from "../../apicalls/projects";
import { useMediaQuery } from "react-responsive";
import "./admin.css";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Task = ({ task }) => {
  const [filters, setFilters] = useState({
    createdAt: "all",
    status: "all",
    assignedTo: "all",
    assignedBy: "all",
  });
  const { projectId } = useParams();
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { user } = useSelector((state) => state.users) || {};
  const [tasks, setTasks] = useState([]);
  const dispatch = useDispatch();
  const [project, setProject] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getTasks();
    }
  }, [project, filters]);

  const getTasks = async () => {
    try {
      dispatch(setLoading(true));
      let filtersToSend = { project: project?._id };

      // Handle filtering logic for createdBy field
      if (filters.assignedBy !== "all") {
        filtersToSend.assignedBy = filters.assignedBy;
      }

      // Convert createdAt filters to JavaScript Date format
      if (filters.createdAt && filters.createdAt !== "all") {
        filtersToSend.createdAt = filters.createdAt.map(
          (date) => new Date(date)
        );
      }

      // Include filters for gender and assignedTo if they are not "all"
      if (filters.status !== "all") {
        filtersToSend.status = filters.status;
      }

      if (filters.assignedTo !== "all") {
        filtersToSend.assignedTo = filters.assignedTo;
      }

      const response = await GetAllTasks({
        project: project?._id,
        filters: filtersToSend,
      });
      dispatch(setLoading(false));
      if (response.success) {
        setTasks(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const handleAddTask = () => {
    setShowAddTaskModal(true);
  };

  // Define breakpoints
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px" };
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",

      render: (text, record) => getDateFormat(text, "datetime"),
    },

    {
      title: "Task Name",
      dataIndex: "taskName",

      render: (text, record) => record.taskName,
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",

      render: (text, record) =>
        record?.assignedTo?.firstName + " " + record?.assignedTo?.lastName,
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",

      render: (text, record) =>
        record?.assignedBy?.firstName + " " + record?.assignedBy?.lastName,
    },

    {
      title: "Task Status",
      dataIndex: "status",

      render: (text, record) => record.status,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/task/detail/${projectId}/${record._id}`}>
            <Button type="danger" icon={<EyeOutlined />} />
          </NavLink>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      setFilters({
        ...filters,
        createdAt: dates,
      });
    } else {
      setFilters({
        ...filters,
        createdAt: "all",
      });
    }
  };

  const resetFilters = () => {
    setFilters({
      createdAt: "all",
      assignedBy: "all",
      assignedTo: "all",
      status: "all"
    });
  };

  // Function to handle preset date selections
  const handlePresetDate = (preset) => {
    let startDate, endDate;

    switch (preset) {
      case "today":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      case "yesterday":
        startDate = moment().subtract(1, "day").startOf("day");
        endDate = moment().subtract(1, "day").endOf("day");
        break;
      case "last7days":
        startDate = moment().subtract(6, "days").startOf("day");
        endDate = moment().endOf("day");
        break;
      case "thismonth":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        break;
      default:
        return;
    }

    setFilters({
      ...filters,
      createdAt: [startDate, endDate],
    });
  };

  useEffect(() => {
    getTasks();
  }, [filters]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
          <Sidebar projectId={projectId} />
        </div>
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Task Module</h1>

            <div className="btn-toolbar mb-2 mb-md-0">
              <Popover title="Add Tasks">
                <Button
                  className="topbar-button-ath-color"
                  style={getButtonStyle()}
                  onClick={handleAddTask}
                  icon={<PlusOutlined />}
                />
              </Popover>

              <Popover title="Filter Tasks">
                <Button
                  className="topbar-button-ath-color"
                  style={getButtonStyle()}
                  icon={<FilterOutlined />}
                  onClick={() => setShowFilterModal(true)}
                />
              </Popover>
              <div className="btn-group me-3">
                <Popover title="Task Settings">
                  <Button
                    className="topbar-button-ath-color"
                    style={getAdditionalButtonStyle()}
                    icon={<ToolOutlined />}
                    disabled
                  />
                </Popover>

                <Popover title="Export File">
                  <Button
                    className="topbar-button-ath-color"
                    style={getAdditionalButtonStyle()}
                    icon={<ExportOutlined />}
                    disabled
                  />
                </Popover>
              </div>
            </div>
          </div>

          <h2 className="mt-5">All Task</h2>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Button
              onClick={() => handlePresetDate("today")}
              style={{ marginRight: "10px" }}
            >
              Today
            </Button>
            <Button
              onClick={() => handlePresetDate("yesterday")}
              style={{ marginRight: "10px" }}
            >
              Yesterday
            </Button>
            <Button
              onClick={() => handlePresetDate("last7days")}
              style={{ marginRight: "10px" }}
            >
              Last 7 Days
            </Button>
            <Button onClick={() => handlePresetDate("thismonth")}>
              This Month
            </Button>
          </div>
          <div className="table-responsive">
            <AntTable
              columns={columns}
              project={projectId}
              dataSource={tasks}
              rowSelection={rowSelection}
            />
          </div>

          <AddTaskModal 
            showModal={showAddTaskModal}
            handleClose={() => setShowAddTaskModal(false)}
            project={project}
            reloadData={getTasks}
            initialTask={task} // Pass initialTask here instead of task
          /> 
          <Modal
            title="Filter Options"
            visible={showFilterModal}
            onCancel={() => setShowFilterModal(false)}
            footer={[
              <Button
                key="submit"
                className="save-button-ath-color"
                onClick={() => {
                  setShowFilterModal(false);
                  getTasks(); // Fetch tasks with updated filters
                }}
              >
                Apply
              </Button>,
              <Button
                className="cancel-button-ath-color"
                key="cancel"
                onClick={() => setShowFilterModal(false)}
              >
                Cancel
              </Button>,
              <Button
                key="reset"
                className="reset-button-ath-color"
                onClick={() => {
                  resetFilters();
                  getTasks();
                }}
              >
                Reset
              </Button>,
            ]}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <span>Created At</span>
                  <RangePicker
                    style={{ width: "100%" }}
                    onChange={handleDateRangeChange}
                    ranges={{
                      Today: [moment().startOf("day"), moment().endOf("day")],
                      Yesterday: [
                        moment().subtract(1, "day").startOf("day"),
                        moment().subtract(1, "day").endOf("day"),
                      ],
                      "Last 7 Days": [
                        moment().subtract(6, "days").startOf("day"),
                        moment().endOf("day"),
                      ],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"), 
                      ],
                    }}
                  />
                </div>
              </Col>

              <Col span={12}>
                <div>
                  <span>Status</span>
                  <Select
                    defaultValue="all"
                    style={{ width: "100%" }}
                    onChange={(value) => handleFilterChange("status", value)}
                    value={filters.status}
                  >
                    <Option value="all">All</Option>
                    <Option value="Pending">Pending</Option>
                  <Option value="Following up">Following up</Option>
                  <Option value="In progress">In progress</Option>
                  <Option value="Task Canceled">Task Cancelled</Option>
                  <Option value="Closed Task">Task Completed</Option>
                  </Select>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <span>Assigned By</span>
                  <Select
                    defaultValue="all"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      handleFilterChange("assignedBy", value)
                    }
                    value={filters.assignedBy}
                  >
                    <Option value="all">All</Option>
                    {project?.members
                      ?.filter((m) =>
                        [
                          "owner",
                          "admin",
                          "employee",
                          "finance",
                          "credit recorvery",
                          "projects",
                          "logistics",
                          "customer service",
                          "sales",
                          "iot",
                          "software developer",
                          "human reasources",
                          "digital marketing",
                          "engineer",
                          "operations",
                          "visuals",
                          "advertising",
                          "intern",
                          "community manager",
                        ].includes(m.role.toLowerCase())
                      )
                      .map((m) => (
                        <Option key={m.user._id} value={m.user._id}>
                          {m.user.firstName + " " + m.user.lastName}
                        </Option>
                      ))}
                  </Select>
                  {/* {console.log("Assigned By options:", project?.members)} */}
                </div>
              </Col>

              <Col span={12}>
                <div className="mt-3">
                  <span>Assigned To</span>
                  <Select
                    defaultValue="all"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      handleFilterChange("assignedTo", value)
                    }
                    value={filters.assignedTo}
                  >
                    <Option value="all">All</Option>
                    {project?.members
                      ?.filter((m) =>
                        [
                          "owner",
                          "admin",
                          "employee",
                          "finance",
                          "credit recorvery",
                          "projects",
                          "logistics",
                          "customer service",
                          "sales",
                          "iot",
                          "software developer",
                          "human reasources",
                          "digital marketing",
                          "engineer",
                          "operations",
                          "visuals",
                          "advertising",
                          "intern",
                          "community manager",
                        ].includes(m.role.toLowerCase())
                      )
                      .map((m) => (
                        <Option key={m.user._id} value={m.user._id}>
                          {m.user.firstName + " " + m.user.lastName}
                        </Option>
                      ))}
                  </Select>
                  {/* {console.log("Assigned To options:", project?.members)} */}
                </div>
              </Col>
            </Row>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default Task;
