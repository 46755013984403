import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button, 
  Drawer,
  Row,
  Col,
  Card,
  Popover,
  InputNumber,
} from "antd";
import { DeleteOutlined, EyeOutlined, SaveOutlined } from "@ant-design/icons";
import { setLoading, setButtonLoading } from "../../redux/loadersSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { GetProjectById } from "../../apicalls/projects";
import moment from "moment";
import "./pipelinedrawer.css";
import {
  CreatePipeline,
  DeletePipeline,
  GetAllPipelines,
} from "../../apicalls/pipeline";

const AddPipelineDrawer = ({ open, onClose, initialPipeline, reloadData }) => {
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.users) || {};
  const { buttonLoading } = useSelector((state) => state.loaders);
  const [pipelines, setPipelines] = useState([]);
  const dispatch = useDispatch();
  const [project, setProject] = useState(null);
  const [projectMembers, setProjectMembers] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
          setProjectMembers(projectResponse.data.members);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getPipelines();
    }
  }, [project]);

  const getPipelines = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetAllPipelines({
        project: project?._id,
      });
      dispatch(setLoading(false));
      if (response.success) {
        setPipelines(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));
      const { pipelineName, progressPercentage } = values;
      let pipelineId = initialPipeline ? initialPipeline._id : null;
      const data = {
        project: project._id,
        pipelineName,
        progressPercentage,
        createdBy: user._id,
        updatedBy: user._id,
        pipelineId,
      };

      const response = await CreatePipeline(data);
      if (response.success) {
        form.resetFields();
        getPipelines();
        toast.success(response.message);
        showChildrenDrawer();
      } else {
        toast.error(response.message);
      }
      dispatch(setButtonLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(setButtonLoading(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const confirmDelete = async (id) => {
    try {
      const response = await DeletePipeline(id);
      if (response.success) {
        toast.success(response.message);
        // Refresh the discounts list after deletion
        getPipelines();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Drawer
        title="Create Pipelines"
        className="drawer-display shadow-warning"
        width={520}
        closable={true}
        onClose={onClose}
        open={open}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Popover title={buttonLoading ? "loading" : "Save Pipeline"}>
              <Button
                key="submit"
                onClick={() => form.submit()}
                icon={<SaveOutlined />}
                loading={buttonLoading}
                className="save-button-ath-color"
              />
            </Popover>
            <Popover title="View all pipelines">
              <Button
                type="primary"
                onClick={showChildrenDrawer}
                icon={<EyeOutlined />}
                className="save-button-ath-color"
              />
            </Popover>
          </div>
        }
      >
        <Form form={form} onFinish={onFinish} className="form-centered">
          <Row justify="center" align="middle" className="form-container">
            <Col span={24}>
              <Form.Item
                label={<span className="form-label">Pipeline Name</span>}
                name="pipelineName"
                className="form-item-centered"
                rules={[
                  {
                    required: true,
                    message: "Please enter a unique pipeline!",
                  },
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={<span className="form-label">Progress Percentage</span>}
                name="progressPercentage"
                className="form-item-centered"
                rules={[
                  {
                    required: true,
                    message: "Please set pipeline percentage!",
                  },
                  {
                    type: "number",
                    min: 10,
                    max: 100,
                    message: "Percentage must be between 10 and 100!",
                  },
                  {
                    validator: (_, value) =>
                      value % 10 === 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Percentage must be a multiple of 10!")
                          ),
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Drawer
          title="All Pipelines"
          width={320}
          closable={true}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
        >
          <Row gutter={[16, 16]}>
            {pipelines.length > 0 ? (
              pipelines.map((pipeline) => (
                <Col span={24} key={pipeline._id}>
                  <Card
                    className="shadow border-warning"
                    actions={[
                      <DeleteOutlined
                        key="delete"
                        onClick={() => confirmDelete(pipeline._id)}
                      />,
                    ]}
                  >
                    <Card.Meta
                      title={<h3>{pipeline.pipelineName}</h3>}
                      description={
                        <>
                          <p>Percentage: {pipeline.progressPercentage} % </p>
                          <p>
                            Created by:{" "}
                            {pipeline.createdBy?.firstName || "Unknown"}{" "}
                            {pipeline.createdBy?.lastName || "Unknown"}
                          </p>
                          <p>
                            Created on:{" "}
                            {moment(pipeline.createdAt).format(
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </p>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))
            ) : (
              <div className="flex text-center">
                <h1 className="text-primary text-xl">You have no Pipeline</h1>
              </div>
            )}
          </Row>
        </Drawer>
      </Drawer>
    </>
  );
};

export default AddPipelineDrawer;
