import React, { useState, useEffect } from "react";
import Pipeline from "../../components/user/Pipeline";
import Sidebar from "../../components/user/Sidebar";
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Form,
  Mentions,
  Popover,
  Image,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EditDealModal from "../../components/editdeal/EditDealModal";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetContactById } from "../../apicalls/contact";
import { GetProjectById } from "../../apicalls/projects";
import { GetPipelineById } from "../../apicalls/pipeline";
import { GetProductById } from "../../apicalls/product";
import moment from "moment";
import { getDateFormat } from "../../utils/helpers";
import { AddNotification } from "../../apicalls/notifications";
import { useMediaQuery } from "react-responsive";
import {
  GetDealById,
  DeleteDeal,
  AddDealComment,
  GetCommentsByDealId,
} from "../../apicalls/deal";

const DealsDetails = () => {
  const { projectId, dealId } = useParams();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const { user } = useSelector((state) => state.users) || {};
  const [deal, setDeal] = useState(null);
  const [project, setProject] = useState(null);
  const [projectMembers, setProjectMembers] = useState([]);
  const navigate = useNavigate();
  const [contact, setContact] = useState(null);
  const [pipeline, setPipeline] = useState(null);
  const [product, setProduct] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const { setLoading } = useSelector((state) => state.loaders);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDealDetails();
    fetchProjectMembers();
    fetchComments();
  }, []);

  const fetchDealDetails = async () => {
    try {
      const response = await GetDealById(dealId);
      if (response.success) {
        setDeal(response.data);
        console.log("Fetched deal details:", response.data); // Add console log
        if (response.data.contact) {
          const contactResponse = await GetContactById(response.data.contact);
          if (contactResponse.success) {
            setContact(contactResponse.data);
          } else {
            throw new Error(contactResponse.message);
          }
        }
        if (response.data.product) {
          const productResponse = await GetProductById(response.data.product);
          if (productResponse.success) {
            setProduct(productResponse.data);
          } else {
            throw new Error(productResponse.message);
          }
        }
        if (response.data.pipeline) {
          const pipelineResponse = await GetPipelineById(
            response.data.pipeline
          );
          if (pipelineResponse.success) {
            setPipeline(pipelineResponse.data);
          } else {
            throw new Error(pipelineResponse.message);
          }
        }
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error fetching deal details:", error);
    }
  };

  const fetchProjectMembers = async () => {
    try {
      const projectResponse = await GetProjectById(projectId);
      if (projectResponse.success) {
        setProject(projectResponse.data);
        setProjectMembers(
          projectResponse.data.members.map((member) => ({
            ...member,
            user: {
              ...member.user,
              _id: member.user._id,
            },
          }))
        );
      } else {
        throw new Error(projectResponse.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await GetCommentsByDealId(dealId, projectId);
      if (response.success) {
        setComments(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await DeleteDeal(dealId);
      if (response.success) {
        toast.success(response.message);
        // Redirect to deal listing page or any other desired action after successful deletion
        navigate(`/deal/project/${projectId}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const isOwnerOrAdmin = () => {
    // Check if projectMembers is defined and user is an owner or admin based on project members
    return (
      projectMembers &&
      user &&
      projectMembers.some(
        (member) =>
          member.user._id === user._id &&
          (member.role === "owner" || member.role === "Admin")
      )
    );
  };

  const isEmployeeAndCreatedByCurrentUser = () => {
    // Check if projectMembers is defined, user is defined and is an Employee, and call is created by the current user
    return (
      projectMembers &&
      user &&
      user.role === "Employee" &&
      deal &&
      deal.createdBy &&
      deal.createdBy._id === user._id &&
      projectMembers.some(
        (member) => member.user._id === user._id && member.role === "Employee"
      )
    );
  };

  const shouldShowDeleteButton = () => {
    // Show delete button if user is owner or admin, or if user is employee and call is created by the current user
    return isOwnerOrAdmin() || isEmployeeAndCreatedByCurrentUser();
  };

  const handleAddDealComment = async () => {
    if (!newComment.trim()) return;

    try {
      const commentData = {
        dealId,
        projectId,
        comment: newComment,
        userId: user._id,
      };
      const response = await AddDealComment(commentData);
      if (response.success) {
        setNewComment("");
        fetchComments(); // Reload comments after adding a new one
        toast.success("Comment added successfully");

        // Check if the deal has a project associated with it
        if (project) {
          // Notify the project members
          for (const member of projectMembers) {
            // Exclude the user who added the comment from being mentioned
            if (member.user._id !== user._id) {
              // Modify the description to replace user ID with user's name
              const modifiedDescription = newComment.replace(
                new RegExp(`@${user._id}`, "g"),
                `@${user.firstName} ${user.lastName}`
              );

              await AddNotification({
                title: `Deal UPDATE! @"${user.firstName} ${user.lastName}" commented on a Deal in ${project.name}`,
                user: member.user._id,
                description: modifiedDescription, // Use modified description
                onClick: `/deal/detail/${projectId}/${dealId}`, // Redirect to task details page
              });
            }
          }
        }
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getMemberNameById = (id) => {
    const member = projectMembers.find((member) => member.user._id === id);
    return member ? `${member.user.firstName} ${member.user.lastName}` : id;
  };

  const formatCommentWithMentions = (comment) => {
    const mentionPattern = /@(\w+)/g;
    return comment.replace(mentionPattern, (match, userId) => {
      const name = getMemberNameById(userId);
      return `@${name}`;
    });
  };

  // Define breakpoints
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px", backgroundColor: "#082e67", color: "#fff" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px", backgroundColor: "red", color: "#fff" };
    }
  };

  const handleReloadData = () => {
    fetchDealDetails();
  };

  // console.log("Deal:", deal);
  // console.log("Pipeline:", pipeline);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <Sidebar projectId={projectId} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">
                {" "}
                {contact
                  ? `${contact.contactFirstName} ${contact.contactLastName}`
                  : "Contact Name"}
              </h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <Popover title="Edit Deal">
                  <Button
                    type="default"
                    style={getButtonStyle()}
                    icon={<EditOutlined />}
                    onClick={handleShowModal}
                  />
                </Popover>

                {shouldShowDeleteButton() && (
                  <Popconfirm
                    title="Delete deal"
                    description="Are you sure to delete this deal?"
                    onConfirm={confirmDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="danger"
                      style={getAdditionalButtonStyle()}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm> 
                )}
              </div>
        
            </div>

            <div className="container">
              <div className="mt-5">
                <h4 className="h4 mt-3">Deal pipeline</h4>
              

                {deal && (
        <>
        
        <Pipeline dealPipeline={deal.pipeline} />
              
              <EditDealModal
              showModal={showModal}
              handleClose={handleCloseModal}
              reloadData={handleReloadData}
              deal={deal}
              projectMembers={projectMembers}
              projectPipelines={project?.pipelines}
            />
            
        </>
              )}
              </div>

              <div className="mt-5 mt-4">
                <h4 className="h4 mt-3 mb-3">Deal infomation</h4>

                {/* Deal details */}

                <div className="col">
                  <Card
                    className="card"
                    hoverable
                    style={{
                      width: "100%",
                      textAlign: "start",
                      marginTop: "40px",
                    }}
                  >
                    {deal ? (
                      <>
                        <Form labelCol={{ span: 24 }}>
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Deal Name:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={deal.dealName} readOnly />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Deal Description
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input.TextArea
                                  rows={4}
                                  value={deal.dealDescription}
                                  readOnly
                                  style={{ width: "400px" }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Payment Type:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={deal.paymentType} readOnly />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Deal Price:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={`₦${deal.dealPrice}`} readOnly />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Deal Status
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input value={deal.dealStatus} readOnly />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Product
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={product ? product.productName : "N/A"}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Updated By
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    deal && deal.updatedBy
                                      ? deal.updatedBy.firstName +
                                        " " +
                                        deal.updatedBy.lastName
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Created By:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    deal && deal.createdBy
                                      ? deal.createdBy.firstName +
                                        " " +
                                        deal.createdBy.lastName
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Last updated at:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    deal && deal.updatedAt
                                      ? moment(deal.updatedAt).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Pipeline
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    pipeline ? pipeline.pipelineName : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Contact Name:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={
                                    contact
                                      ? `${contact.contactFirstName} ${contact.contactLastName}`
                                      : "N/A"
                                  }
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Contact Email:
                                  </span>
                                }
                                className="form-col"
                              >
                                <Input
                                  value={contact ? contact.contactEmail : "N/A"}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Mobile Phone No:
                                  </span>
                                }
                                className="form-col"
                              >
                                {contact &&
                                contact.phoneNumbers &&
                                contact.phoneNumbers.length > 0 ? (
                                  <Input
                                    value={contact.phoneNumbers[0]}
                                    readOnly
                                  />
                                ) : (
                                  <Input value="N/A" readOnly />
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <span style={{ fontSize: "12px" }}>
                                    Work Phone No:
                                  </span>
                                }
                                className="form-col"
                              >
                                {contact &&
                                contact.phoneNumbers &&
                                contact.phoneNumbers.length > 1 ? (
                                  <Input
                                    value={contact.phoneNumbers[1]}
                                    readOnly
                                  />
                                ) : (
                                  <Input value="N/A" readOnly />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={24}>
                              <Form.Item
                                label="Deal Attachments"
                                className="form-col"
                              >
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {deal.attachments &&
                                    deal.attachments.map((image, index) => (
                                      <Image
                                        key={index}
                                        width={200}
                                        height={200}
                                        src={image}
                                        style={{
                                          objectFit: "cover",
                                          boxShadow:
                                            "0 4px 8px rgba(0, 0, 0, 0.1)",
                                          margin: "10px",
                                        }}
                                      />
                                    ))}
                                </div>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>

                        {/* deal Comment Section */}
                        <div className="mt-5">
                          <h4 className="h4 mt-3 mb-3">Deals Comments</h4>
                          <div className="col">
                            {comments.map((comment, index) => (
                              <Card
                                key={index}
                                className="mb-3 comment-list shadow"
                              >
                                <p>
                                  <strong>
                                    {comment.user.firstName}{" "}
                                    {comment.user.lastName}
                                  </strong>{" "}
                                  commented:
                                </p>
                                <p>
                                  {formatCommentWithMentions(comment.comment)}
                                </p>
                                <p
                                  className="text-muted"
                                  style={{ fontSize: "12px" }}
                                >
                                  {moment(comment.createdAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </p>
                              </Card>
                            ))}
                          </div>
                          <div className="mt-4">
                            <Mentions
                              style={{ width: "50%" }}
                              value={newComment}
                              onChange={(value) => setNewComment(value)}
                              placeholder="Add a comment..."
                            >
                              {projectMembers.map((member) => (
                                <Mentions.Option
                                  key={member.user._id}
                                  value={`${member.user.firstName} ${member.user.lastName}`}
                                >
                                  {`${member.user.firstName} ${member.user.lastName}`}
                                </Mentions.Option>
                              ))}
                            </Mentions>
                            <div className="spa">
                              <Button
                                type="primary"
                                className="mt-2 comment-button"
                                onClick={handleAddDealComment}
                              >
                                Add Comment
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>No deal details found.</p>
                    )}
                  </Card>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default DealsDetails;
