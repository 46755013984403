import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GetLoggedInUser } from "../../apicalls/users";
import Header from "../user/Header";
import { useDispatch, useSelector } from "react-redux";
import { SetUser } from "../../redux/usersSlice";
import { setLoading } from "../../redux/loadersSlice";

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [showNotifications, setShowNotifications] = useState(false);

  const getUser = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetLoggedInUser();
      dispatch(setLoading(false));
      if (response.success) {
        dispatch(SetUser(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUser();
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <Header
        showNotifications={showNotifications}
        setShowNotifications={setShowNotifications}
      />
      {children}
    </div>
  );
};

export default ProtectedRoutes;
