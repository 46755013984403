import React, { useEffect, useState } from "react";
import Sidebar from "../../components/user/Sidebar";
import {
  Avatar,
  Button,
  Card, 
  Col,
  Image,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Row,
  Select,
  Badge,
  Carousel,
} from "antd";
import {
  PlusOutlined,
  AppstoreOutlined,
  PercentageOutlined,
  FilterOutlined,
  ExportOutlined,
  ImportOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import AddProductModal from "../../components/addproduct/AddProductModal.jsx";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import AddCategoryDrawer from "../../components/categorydrawer/AddCategoryDrawer";
import AddDiscountDrawer from "../../components/discountdrawer/AddDiscountDrawer";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import { GetProjectById } from "../../apicalls/projects";
import toast from "react-hot-toast";
import { DeleteProduct, GetAllProducts } from "../../apicalls/product";
import Meta from "antd/es/card/Meta";
import "./admin.css";

const { Option } = Select;

const Product = ({ product }) => {
  const [filters, setFilters] = useState({
    createdBy: "all",
    categories: [],
    discounts: [],
    updatedBy: "",
  });

  const { projectId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showCategoryDrawer, setShowCategoryDrawer] = useState(false);
  const [showDiscountDrawer, setShowDiscountDrawer] = useState(false);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowCategoryDrawer = () => setShowCategoryDrawer(true);
  const handleCloseCategoryDrawer = () => setShowCategoryDrawer(false);

  const handleShowDiscountDrawer = () => setShowDiscountDrawer(true);
  const handleCloseDiscountDrawer = () => setShowDiscountDrawer(false);
  const dispatch = useDispatch();
  const [project, setProject] = useState(null);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();

  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueDiscounts, setUniqueDiscounts] = useState([]);

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Number of items per page

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
          dispatch(setLoading(false));
          getProducts(); // Fetch products after project data is set
        } else {
          throw new Error(projectResponse.message);
        }
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getProducts();
    }
  }, [project, filters]);


  const handleFilterChange = (field, value) => {
    console.log("Filter changed:", field, value);
    // Ensure categories are sent as an array of valid ObjectId strings or as needed
    if (field === "categories") {
      setFilters({ ...filters, [field]: value.map(String) });
    } else {
      setFilters({ ...filters, [field]: value });
    }
  };
  


// Function to fetch products
const getProducts = async () => {
  try {
    console.log('Fetching products with filters:', filters);
    dispatch(setLoading(true));

    let filtersToSend = { project: project?._id };

    if (filters.createdBy !== 'all') {
      filtersToSend.createdBy = filters.createdBy;
    }

    if (filters.updatedBy !== 'all') {
      filtersToSend.updatedBy = filters.updatedBy;
    }

    if (filters.categories.length > 0) {
      filtersToSend.categories = filters.categories; // Ensure categories are sent as an array of strings
    }

    if (filters.discounts.length > 0) {
      filtersToSend.discounts = filters.discounts;
    }

    const response = await GetAllProducts({
      project: project?._id,
      filters: filtersToSend,
    });

    dispatch(setLoading(false));
    if (response.success) {
      setProducts(response.data);
    } else {
      throw new Error(response.message);
    } 
  } catch (error) {
    dispatch(setLoading(false));
    toast.error(error.message);
  }
};




  useEffect(() => {
    const categories = new Set();
    products.forEach((product) => {
      product.categories.forEach((category) => {
        categories.add(category.categoryName);
      });
    });
    setUniqueCategories(Array.from(categories));

    const discounts = new Set();
    products.forEach((product) => {
      product.discounts.forEach((discount) => {
        discounts.add(discount.discountCode);
      });
    });
    setUniqueDiscounts(Array.from(discounts));
  }, [products]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px" };
    }
  };

  const reloadData = () => {
    getProducts();
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const renderedproducts = products.slice(startIndex, endIndex);

  const confirmDelete = async (productId) => {
    try {
      const response = await DeleteProduct({ _id: productId });
      if (response.success) {
        toast.success(response.message);
        setProducts(products.filter((product) => product._id !== productId));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  const getRibbonProperties = (quantity) => {
    if (quantity === 0)
      return { color: "red", text: `Out Of Stock (${quantity})` };
    if (quantity < 5)
      return { color: "yellow", text: `Almost out (${quantity})` };
    return { color: "green", text: `In Stock (${quantity})` };
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <Sidebar projectId={projectId} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Products Module</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <Popover title="Add Products">
                  <Button
                    type="default"
                    style={getButtonStyle()}
                    onClick={handleShowModal}
                    icon={<PlusOutlined />}
                  />
                </Popover>

                <Popover title="Filter Products">
                  <Button
                    type="default"
                    style={getButtonStyle()}
                    icon={<FilterOutlined />}
                    onClick={() => setShowFilterModal(true)}
                  />
                </Popover>

                <div className="btn-group me-3">
                  <Popover title="Categories">
                    <Button
                      type="default"
                      style={getButtonStyle()}
                      onClick={handleShowCategoryDrawer}
                      icon={<AppstoreOutlined />}
                    />
                  </Popover>

                  <Popover title="Discounts">
                    <Button
                      type="default"
                      style={getButtonStyle()}
                      onClick={handleShowDiscountDrawer}
                      icon={<PercentageOutlined />}
                    />
                  </Popover>

                  <Popover title="Import File">
                    <Button
                      type="default"
                      style={getAdditionalButtonStyle()}
                      icon={<ImportOutlined />}
                      disabled
                    />
                  </Popover>

                  <Popover title="Export File">
                    <Button
                      type="default"
                      style={getAdditionalButtonStyle()}
                      icon={<ExportOutlined />}
                      disabled
                    />
                  </Popover>
                </div>
              </div>

              <AddProductModal
                showModal={showModal}
                handleClose={handleCloseModal}
                project={project}
                initialProduct={product}
                reloadData={getProducts}
              />
            </div>

            <h2 className="mt-5 mb-5">All Products</h2>
            <div>
              {renderedproducts.length === 0 ? (
                <div className="text-center my-5">
                  <h3>No products found.</h3>
                </div>
              ) : (
                <>
                  <Row gutter={[16, 16]}>
                    {renderedproducts.map((product) => (
                      <Col key={product._id} xs={24} sm={12} lg={8}>
                        <Badge.Ribbon
                          color={getRibbonProperties(product.quantity).color}
                          text={getRibbonProperties(product.quantity).text}
                          placement="start"
                        >
                          <Card
                            style={{ width: 300 }}
                            className="shadow card-border-product"
                            cover={
                              <Carousel autoplay>
                                {product.attachments &&
                                  product.attachments.map((image, index) => (
                                    <div key={index}>
                                      <Image
                                        width={300}
                                        height={200}
                                        src={image}
                                        style={{
                                          objectFit: "cover",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </div>
                                  ))}
                              </Carousel>
                            }
                            actions={[
                              <NavLink
                                to={`/product/detail/${projectId}/${product._id}`}
                                key="view"
                              >
                                <Button type="default" icon={<EyeOutlined />} />
                              </NavLink>,
                              <Popconfirm
                                title="Delete product"
                                description="Are you sure you want to delete this product?"
                                onConfirm={() => confirmDelete(product._id)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button
                                  type="default"
                                  danger
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>,
                            ]}
                          >
                            <Meta
                              title={product.productName}
                              description={truncateText(
                                product.productDescription,
                                50
                              )}
                              className="text-start"
                            />
                          </Card>
                        </Badge.Ribbon>
                      </Col>
                    ))}
                  </Row>

                  <Pagination
                    current={currentPage}
                    onChange={setCurrentPage}
                    total={products.length}
                    pageSize={pageSize}
                    className="mt-5 mb-5 text-end"
                  />
                </>
              )}
            </div>
            <Modal
              title="Filter Options"
              visible={showFilterModal}
              onCancel={() => setShowFilterModal(false)}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    setShowFilterModal(false);
                    getProducts();
                  }}
                >
                  Apply
                </Button>,
                <Button key="cancel" onClick={() => setShowFilterModal(false)}>
                  Cancel
                </Button>,
              ]}
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>
                    <span>Created By</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("createdBy", value)
                      }
                    >
                      <Option value="all">All</Option>
                      {project?.members
                        ?.filter((m) =>
                          [
                            "owner",
                            "admin",
                            "employee",
                            "finance",
                            "credit recorvery",
                            "projects",
                            "logistics",
                            "customer service",
                            "sales",
                            "iot",
                            "software developer",
                            "human reasources",
                            "digital marketing",
                            "engineer",
                            "operations",
                            "visuals",
                            "advertising",
                            "intern",
                            "community manager",
                          ].includes(m.role.toLowerCase())
                        )
                        .map((m) => (
                          <Option key={m.user._id} value={m.user._id}>
                            {m.user.firstName + " " + m.user.lastName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <span>Updated By</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("updatedBy", value)
                      }
                    >
                      <Option value="all">All</Option>
                      {project?.members
                        ?.filter((m) =>
                          [
                            "owner",
                            "admin",
                            "employee",
                            "finance",
                            "credit recorvery",
                            "projects",
                            "logistics",
                            "customer service",
                            "sales",
                            "iot",
                            "software developer",
                            "human reasources",
                            "digital marketing",
                            "engineer",
                            "operations",
                            "visuals",
                            "advertising",
                            "intern",
                            "community manager",
                          ].includes(m.role.toLowerCase())
                        )
                        .map((m) => (
                          <Option key={m.user._id} value={m.user._id}>
                            {m.user.firstName + " " + m.user.lastName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>
                    <span>Categories</span>
                    <Select
  mode="multiple"
  style={{ width: '100%' }}
  placeholder="Select categories"
  onChange={(value) => handleFilterChange('categories', value)}
>
  {uniqueCategories.map((category) => (
    <Option key={category} value={category}>
      {category}
    </Option>
  ))}
</Select>

                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <span>Discounts</span>
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select discounts"
                      onChange={(value) =>
                        handleFilterChange("discounts", value)
                      }
                    >
                      {uniqueDiscounts.map((discount) => (
                        <Option key={discount} value={discount}>
                          {discount}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>
            </Modal>
          </main>
        </div>
      </div>

      <AddCategoryDrawer
        open={showCategoryDrawer}
        onClose={handleCloseCategoryDrawer}
        initialCategory={null}
        reloadData={reloadData}
      />

      <AddDiscountDrawer
        open={showDiscountDrawer}
        onClose={handleCloseDiscountDrawer}
        initialDiscount={null}
        reloadData={reloadData}
      />
    </>
  );
};

export default Product;
