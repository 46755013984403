import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Space,
  Row,
  Col,
  InputNumber,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { setButtonLoading, setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import { GetProjectById } from "../../apicalls/projects";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CreateProduct } from "../../apicalls/product";
import { GetAllCategories } from "../../apicalls/category";
import { GetAllDiscounts } from "../../apicalls/discount";
import "./addproduct.css";

const { Option } = Select;

const AddProductModal = ({
  showModal,
  handleClose,
  reloadData,
  initialProduct,
}) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users) || {};
  const [project, setProject] = useState(null);
  const [projectMembers, setProjectMembers] = useState(null);
  const [categories, setCategories] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const { buttonLoading } = useSelector((state) => state.loaders);

  useEffect(() => {
    if (initialProduct) {
      setImages(initialProduct.attachments || []);
    }
  }, [initialProduct]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        dispatch(setLoading(true));
        const response = await GetAllCategories({ project: project?._id });
        if (response.success) {
          setCategories(response.data);
        } else {
          throw new Error(response.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    const fetchDiscounts = async () => {
      try {
        dispatch(setLoading(true));
        const response = await GetAllDiscounts({ project: project?._id });
        if (response.success) {
          setDiscounts(response.data);
        } else {
          throw new Error(response.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    if (project) { 
      fetchCategories();
      fetchDiscounts();
    }
  }, [dispatch, project]);

  const onFinish = async (values) => {
    try {
     
      let productId = null;
      if (initialProduct) {
        productId = initialProduct._id;
      }
 
      dispatch(setButtonLoading(true));
      const formData = new FormData();

      formData.append("productName", values.productName);
      formData.append("quantity", values.quantity);
      formData.append("price", values.price);

      formData.append("productSize", values.productSize);
      formData.append("productColor", values.productColor);
      formData.append("productDescription", values.productDescription);
      formData.append("project", project._id);
      formData.append("createdBy", user._id);
      formData.append("updatedBy", user._id);
      formData.append("productId", productId);
      values.categories.forEach((categoryId) =>
        formData.append("categoryIds[]", categoryId)
      );
      values.discounts.forEach((discountId) =>
        formData.append("discounts[]", discountId)
      );

      files.forEach((file) => {
        formData.append("attachments", file.originFileObj);
      });
 
      const response = await CreateProduct(formData);
      if (response.success) {
        form.resetFields(); // Reset the form fields
        setFiles([]); // Clear the file list
        setImages([]); // Clear the images list
        reloadData();
        toast.success(response.message);
        handleClose();
      } else {
        toast.error(response.message);
      }
      dispatch(setButtonLoading(false));
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  const deleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  return (
    <Modal
      title="Add Product"
      visible={showModal}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
          Cancel
        </Button>,
       <Button
       key="submit"
      className="save-button-ath-color"
       onClick={() => form.submit()}
       loading={buttonLoading}
     >
       {buttonLoading ? "loading" : "Create Product"}
     </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          shipping: "no",
        }}
        labelCol={{ span: 24 }} // Set labelCol to occupy the full width
      >
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Product Name</span>}
                name="productName"
                className="form-col"
                rules={[
                  { required: true, message: "Please enter product name!" },
                ]}
              >
                <Input placeholder="product Name" style={{ width: "170px" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Category"
                name="categories"
                rules={[{ required: true, message: "Please select category!" }]}
              >
                <Select mode="multiple" placeholder="Select categories">
                  {categories.map((category) => (
                    <Option key={category._id} value={category._id}>
                      {category.categoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Quantity</span>}
                name="quantity"
                className="form-col"
                rules={[{ required: true, message: "Please enter quantity!" }]}
              >
                <Input
                  placeholder="Product quantity"
                  style={{ width: "170px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: "Please enter price!" }]}
              >
                <InputNumber
                  min={0}
                  formatter={(value) =>
                    `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/₦\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Product Size</span>}
                name="productSize"
                className="form-col"
                rules={[
                  { required: true, message: "Please enter product size!" },
                ]}
              >
                <Input placeholder="Product size" style={{ width: "170px" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Product Color</span>}
                name="productColor"
                className="form-col"
                rules={[
                  { required: true, message: "Please enter product color!" },
                ]}
              >
                <Input placeholder="Product color" style={{ width: "170px" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Discount" name="discounts">
                <Select mode="multiple" placeholder="Select discounts">
                  {discounts.map((discount) => (
                    <Option key={discount._id} value={discount._id}>
                      {discount.discountCode} ({discount.discountPercentage}%)
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px" }}>Product Description</span>
                }
                name="productDescription"
                rules={[
                  {
                    required: true,
                    message: "Please enter product description",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter your text here" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Product Images</span>}
              >
                <div>
                  {images.map((image, index) => (
                    <span key={index}>
                      <img
                        src={image}
                        alt="Selected"
                        style={{ maxWidth: "50%", marginTop: "10px" }}
                      />
                      <DeleteOutlined onClick={() => deleteImage(index)} />
                    </span>
                  ))}
                </div>
                <Upload
                  name="files"
                  showUploadList={false}
                  onChange={(info) => {
                    const fileList = info.fileList;
                    setFiles(fileList);
                    setImages(
                      fileList.map((file) =>
                        URL.createObjectURL(file.originFileObj)
                      )
                    );
                  }}
                  multiple={true}
                >
                  <Button icon={<PlusOutlined />} />
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};

export default AddProductModal;
