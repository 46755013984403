import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./hero.css";
import { GetLoggedInUser } from "../../apicalls/users";
import { SetUser } from "../../redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";

const Hero = () => {
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();

  const getUser = async () => {
    try {
      const response = await GetLoggedInUser();
      if (response.success) {
        dispatch(SetUser(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      {" "}
      <div className="container-fluid ath-custom-margin">
        <div className="container">
          <div className="row flex-lg-row-reverse align-items-between">
            <div className="col-lg-6">
              <img
                src="./assets/img/dots-crm-tool.png"
                className="d-block img-fluid shadow"
                alt="ATH-crm-tool"
                style={{ width: "100%", height: "100%" }}
                loading="lazy"
              />
            </div>
            <div className="col-md-6 mt-5">
              <h1 className="ath-hero-text text-align-sm-center mb-4">
              ATH CRM
    <br /> One Team, One App
              </h1>
              <p className="lead">
              Streamline and manage all your digital operations seamlessly in a single platform, free of charge.
              </p>
              <div className="d-grid gap-2 mt-5 mb-5 d-md-flex justify-content-md-start">
                {user ? (
                  <NavLink
                    to="/account"
                    className="btn ath-button btn-lg px-4 me-md-2 shadow"
                  >
                    My Account
                  </NavLink>
                ) : (
                  <NavLink
                    to="/register"
                    className="btn ath-button btn-lg px-4 me-md-2 shadow"
                  >
                    Get Started
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
