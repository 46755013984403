import React, { useState } from "react";
import { Form, Input, Button, Rate, Modal,  Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, ExpandAltOutlined, MessageOutlined } from "@ant-design/icons";
import "./fixedreviewbox.css";
import { AddReview } from "../../apicalls/users";

const FixedReviewBox = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false); // Default to false to show UpOutlined initially
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    if (!user) {
      navigate("/register");
      return;
    }

    try {
      const response = await AddReview({ ...values, userId: user._id });
      if (response.success) {
        setIsModalVisible(true);
        form.resetFields(); // Reset the form fields after successful submission
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Failed to add review:", error.message);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    window.location.reload(); // Reload the page to refresh reviews
  };

  const toggleBoxVisibility = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  return (
    <div className="fixed-review-box-container">
      <div className="fixed-review-box shadow">
        <div className="toggle-button" onClick={toggleBoxVisibility}>
          {!isBoxVisible ? (
            <>
              <span className="ml-4">
              <Popover title="Share Reviews">
                <MessageOutlined />
                </Popover>
              </span> 
            </>
          ) : (
            <>
              <span className="ml-4">
                <CloseOutlined /> Minimize
              </span>
            </>
          )}
        </div>
        <>
          {isBoxVisible && (
            <Form form={form} onFinish={onFinish} className="mt-2">
              <Form.Item
                name="review"
                rules={[
                  { required: true, message: "Please input your review!" },
                ]}
              >
                <Input.TextArea placeholder="Write your review..." />
              </Form.Item>
              <Form.Item
                name="rating"
                rules={[{ required: true, message: "Please rate!" }]}
              >
                <Rate />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="ath-review-button"
                  htmlType="submit"
                >
                  Submit Review
                </Button>
              </Form.Item>
            </Form>
          )}
        </>

        <Modal
          title="Review Submitted ✅"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleOk}
          footer={[
            <Button
              key="submit"
              className="ath-review-button"
              onClick={handleOk}
            >
              OK
            </Button>,
          ]}
        >
          <p>Thank you for your review. This means a lot to us! 💖</p>
        </Modal>

        {/* <p className="text-dark text-center align-center justify-center fs-8"> Review Box </p> */}
      </div>
    </div>
  );
};

export default FixedReviewBox;
