import axios from "axios";

export const apiRequest = async (method, endpoint, payload) => {
  try {
    const response = await axios({
      method,
      url: `${process.env.REACT_APP_API}${endpoint}`,
      data: payload,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return { success: false, message: "An unexpected error occurred" };
  }
};
