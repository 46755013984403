import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BellOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  Popconfirm,
  Badge,
  Avatar,
  Menu,
  Layout,
  Button,
  Row,
  Col,
} from "antd";
import { useMediaQuery } from "react-responsive";
import "./usercomponents.css";
import toast from "react-hot-toast";
import { GetLoggedInUser } from "../../apicalls/users";
import { useDispatch, useSelector } from "react-redux";
import { SetNotifications, SetUser } from "../../redux/usersSlice";
import { setLoading } from "../../redux/loadersSlice";
import { GetAllNotifications } from "../../apicalls/notifications";
import NotificationsModal from "../notification/NotificationsModal";
// import "./usercomponents.css";

const { Header } = Layout;

const AppHeader = ({ showNotifications, setShowNotifications }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, notifications } = useSelector((state) => state.users);

  // Define breakpoints
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "10px", marginBottom: "4px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "20px", marginBottom: "4px" };
    } else {
      return { marginRight: "80px", marginBottom: "30px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px", marginBottom: "4px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px", marginBottom: "4px" };
    } else {
      return { marginLeft: "60px", marginBottom: "30px" };
    }
  };

  const getUser = async () => {
    try {
      const response = await GetLoggedInUser();
      if (response.success) {
        dispatch(SetUser(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  const getNotifications = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetAllNotifications();
      dispatch(setLoading(false));
      if (response.success) {
        dispatch(SetNotifications(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUser();
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (user) {
      getNotifications();
    }
  }, [user]);

  const openNotification = () => {
    setShowNotifications(true);
  };

  const confirm = () => {
    toast.success("Log out successfully");
    localStorage.removeItem("token");
    navigate("/login");
  };

  const cancel = () => {};

  return (
    <Layout>
      <Header className="header bg-dark custom-header">
        <Row justify="space-between" align="middle" className="header-row">
          <Col>
            <NavLink to="/">
              <img
                src="/assets/img/athbrand.png"
                alt="logo"
                className="img-fluid rounded-circle"
                style={{
                  width: isMobile ? "80px" : "100px",
                  height: isMobile ? "80px" : "100px",
                  marginTop: isMobile ? "-10px" : "0px",
                }}
              />
            </NavLink>
          </Col>

          <Col
            className="header-icons"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Avatar
              src={user?.avatar?.url}
              size={isMobile ? 24 : 30}
              style={getButtonStyle()}
            />

            <Badge
              count={
                notifications.filter((notification) => !notification.read)
                  .length
              }
              className="cursor-hand"
              onClick={openNotification}
              style={getButtonStyle()}
            >
              <BellOutlined
                style={{
                  fontSize: isMobile ? "16px" : "20px",
                  color: "white",
                  ...getButtonStyle(),
                }}
              />
            </Badge>

            <Popconfirm
              title="Log out?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
              placement="bottom"
            >
              <Button
                type="text"
                icon={
                  <LogoutOutlined
                    style={{
                      fontSize: isMobile ? "16px" : "20px",
                      color: "white",
                      ...getAdditionalButtonStyle(),
                    }}
                  />
                }
              />
            </Popconfirm>
          </Col>
        </Row>
      </Header>

      {showNotifications && (
        <NotificationsModal
          showNotifications={showNotifications}
          setShowNotifications={setShowNotifications}
          reloadNotifications={getNotifications}
          navigate={navigate}
        />
      )}
    </Layout>
  );
};

export default AppHeader;
