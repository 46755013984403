import React from "react";
import {  FileProtectOutlined, UserOutlined } from "@ant-design/icons";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const ProjectSettingTab = ({ activeTab, onChange }) => {
  const handleTabChange = (key) => {
    onChange(key);
  };

  return (
 <>
    <Tabs activeKey={activeTab} onChange={handleTabChange} centered>
      <Tabs.Item
        icon={<FileProtectOutlined />}
        title="Projects List"
        key="projectsList"
      />
      <Tabs.Item
        icon={<UserOutlined />}
        title="Members List"
        key="membersList"
      />
    </Tabs>
 </>
  );
};

export default ProjectSettingTab;
