import React, { useState, useEffect } from "react";
import Sidebar from "../../components/user/Sidebar";
import {
  Table as AntTable,
  Button,
  Space,
  Modal,
  Select,
  Row,
  Col,
  Popover,
  DatePicker,
} from "antd";
import {
  PlusOutlined,
  EyeOutlined,
  FilterOutlined,
  ImportOutlined,
  ToolOutlined,
  ExportOutlined,
  NodeIndexOutlined, 
} from "@ant-design/icons";
import AddDealModal from "../../components/adddeal/AddDealModal";
import { NavLink, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import AddPipelineDrawer from "../../components/pipelinedrawer/AddPipelineDrawer";
import { GetProjectById } from "../../apicalls/projects";
import { GetAllDeals } from "../../apicalls/deal";
import { getDateFormat } from "../../utils/helpers";
import "./admin.css";
import toast from "react-hot-toast";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Deal = ({ deal }) => {
  const [filters, setFilters] = useState({
    createdAt: "all",
    createdBy: "all",
    dealStatus: "all",
    paymentType: "all",
  });

  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const { user } = useSelector((state) => state.users) || {};
  const [showPipelineDrawer, setShowPipelineDrawer] = useState(false);
  const [deals, setDeals] = useState([]);

  const handleShowPipelineDrawer = () => setShowPipelineDrawer(true);
  const handleClosePipelineDrawer = () => setShowPipelineDrawer(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getDeals();
    }
  }, [project, filters]);

  const getDeals = async () => {
    try {
      dispatch(setLoading(true));
      let filtersToSend = { project: project?._id };

      // Handle filtering logic for createdBy field
      if (filters.createdBy !== "all") {
        filtersToSend.createdBy = filters.createdBy;
      }

      // Convert createdAt filters to JavaScript Date format
      if (filters.createdAt && filters.createdAt !== "all") {
        filtersToSend.createdAt = filters.createdAt.map(
          (date) => new Date(date)
        );
      }

      if (filters.dealStatus !== "all") {
        filtersToSend.dealStatus = filters.dealStatus;
      }

      if (filters.paymentType !== "all") {
        filtersToSend.paymentType = filters.paymentType;
      }

      console.log("Fetching deals with filters:", filtersToSend);

      const response = await GetAllDeals({
        project: project?._id,
        filters: filtersToSend,
      });

      dispatch(setLoading(false));
      if (response.success) {
        setDeals(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text, record) => getDateFormat(text, "datetime"),
    },
    {
      title: "Deal Name",
      dataIndex: "dealName",
      render: (text, record) => record.dealName,
    },
    {
      title: "Deal Status",
      dataIndex: "dealStatus",
      render: (text, record) => record.dealStatus,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      render: (text, record) => record.paymentType,
    },
    {
      title: "Deal Price",
      dataIndex: "dealPrice",
      render: (text, record) => record.dealPrice,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      render: (text, record) =>
        record?.createdBy?.firstName + " " + record?.createdBy?.lastName,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/deal/detail/${projectId}/${record._id}`}>
            <Button type="danger" icon={<EyeOutlined />} />
          </NavLink>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px" };
    }
  };

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      setFilters({
        ...filters,
        createdAt: dates,
      });
    } else {
      setFilters({
        ...filters,
        createdAt: "all",
      });
    }
  };

  const resetFilters = () => {
    setFilters({
      createdAt: "all",
      createdBy: "all",
      dealStatus: "all",
      paymentType: "all",
    });
  };

  // Function to handle preset date selections
  const handlePresetDate = (preset) => {
    let startDate, endDate;

    switch (preset) {
      case "today":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      case "yesterday":
        startDate = moment().subtract(1, "day").startOf("day");
        endDate = moment().subtract(1, "day").endOf("day");
        break;
      case "last7days":
        startDate = moment().subtract(6, "days").startOf("day");
        endDate = moment().endOf("day");
        break;
      case "thismonth":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        break;
      default:
        return;
    }

    setFilters({
      ...filters,
      createdAt: [startDate, endDate],
    });
  };

  useEffect(() => {
    getDeals();
  }, [filters]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <Sidebar projectId={projectId} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Deals Module</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <Popover title="Add Deals">
                  <Button
                    type="default"
                    onClick={handleShowModal}
                    style={getButtonStyle()}
                    icon={<PlusOutlined />}
                    className="topbar-button-ath-color"
                  />
                </Popover>

                <Popover title="Filter Deals">
                  <Button
                    className="topbar-button-ath-color"
                    style={getButtonStyle()}
                    icon={<FilterOutlined />}
                    onClick={() => setShowFilterModal(true)}
                  />
                </Popover>

                <div className="btn-group me-3">
                  <Popover title="Create Pipelines">
                    <Button
                      className="topbar-button-ath-color"
                      style={getButtonStyle()}
                      onClick={handleShowPipelineDrawer}
                      icon={<NodeIndexOutlined />}
                    />
                  </Popover>

                  <Popover title="Deals Settings">
                    <Button
                      className="topbar-button-ath-color"
                      style={getAdditionalButtonStyle()}
                      icon={<ToolOutlined />}
                      disabled
                    />
                  </Popover>

                  <Popover title="Import File">
                    <Button
                      className="topbar-button-ath-color"
                      style={getAdditionalButtonStyle()}
                      icon={<ImportOutlined />}
                      disabled
                    />
                  </Popover>

                  <Popover title="Export File">
                    <Button
                      className="topbar-button-ath-color"
                      style={getAdditionalButtonStyle()}
                      icon={<ExportOutlined />}
                      disabled
                    />
                  </Popover>
                </div>
              </div>
              <AddDealModal
                showModal={showModal}
                handleClose={handleCloseModal}
                reloadData={getDeals}
                project={project}
                initailDeal={deal}
              />
            </div>

            <h2 className="mt-5">All Deals</h2>

            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Button
                onClick={() => handlePresetDate("today")}
                style={{ marginRight: "10px" }}
              >
                Today
              </Button>
              <Button
                onClick={() => handlePresetDate("yesterday")}
                style={{ marginRight: "10px" }}
              >
                Yesterday
              </Button>
              <Button
                onClick={() => handlePresetDate("last7days")}
                style={{ marginRight: "10px" }}
              >
                Last 7 Days
              </Button>
              <Button onClick={() => handlePresetDate("thismonth")}>
                This Month
              </Button>
            </div>

            <div className="table-responsive">
              <AntTable
                columns={columns}
                project={projectId}
                dataSource={deals}
                rowSelection={rowSelection}
              />
            </div>

            <Modal
              title="Filter Options"
              visible={showFilterModal}
              onCancel={() => setShowFilterModal(false)}
              footer={[
                <Button
                  key="submit"
                  className="save-button-ath-color"
                  onClick={() => {
                    setShowFilterModal(false);
                    getDeals();
                  }}
                >
                  Apply
                </Button>,
                <Button
                  className="cancel-button-ath-color"
                  key="cancel"
                  onClick={() => setShowFilterModal(false)}
                >
                  Cancel
                </Button>,
                <Button
                  key="reset"
                  className="reset-button-ath-color"
                  onClick={() => {
                    resetFilters();
                    getDeals();
                  }}
                >
                  Reset
                </Button>,
              ]}
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>
                    <span>Created At</span>
                    <RangePicker
                      style={{ width: "100%" }}
                      onChange={handleDateRangeChange}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment().subtract(1, "day").startOf("day"),
                          moment().subtract(1, "day").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                      }}
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <span>Deal Status</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("dealStatus", value)
                      }
                      value={filters.dealStatus}
                    >
                      <Option value="all">All</Option>
                      <Option value="pending">Pending</Option>
                      <Option value="in progress">In progress</Option>
                      <Option value="canceled">Cancel</Option>
                      <Option value="closed">Closed Deal</Option>
                    </Select>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <span>Created By</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("createdBy", value)
                      }
                      value={filters.createdBy}
                    >
                      <Option value="all">All</Option>
                      {project?.members
                        ?.filter((m) =>
                          [
                            "owner",
                            "admin",
                            "employee",
                            "finance",
                            "credit recorvery",
                            "projects",
                            "logistics",
                            "customer service",
                            "sales",
                            "iot",
                            "software developer",
                            "human reasources",
                            "digital marketing",
                            "engineer",
                            "operations",
                            "visuals",
                            "advertising",
                            "intern",
                            "community manager",
                          ].includes(m.role.toLowerCase())
                        )
                        .map((m) => (
                          <Option key={m.user._id} value={m.user._id}>
                            {m.user.firstName + " " + m.user.lastName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <span>Deal Payment Type</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("paymentType", value)
                      }
                      value={filters.paymentType}
                    >
                      <Option value="all">All</Option>
                      <Option value="full payment">full payment</Option>
                      <Option value="upfront payment">upfront payment</Option>
                      <Option value="monthly payment">monthly payment</Option>
                      <Option value="free">free</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Modal>
          </main>
        </div>
      </div>

      <AddPipelineDrawer
        open={showPipelineDrawer}
        onClose={handleClosePipelineDrawer}
        initialCategory={null}
        // reloadData={reloadData}
      />
    </>
  );
};

export default Deal;
