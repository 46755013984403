import { apiRequest } from ".";

export const SaveChatbotConfig = async (config) =>
  apiRequest("post", "/api/chatbots/save-chatbot-config", config);

export const GetChatbotConfig = async (projectId) =>
  apiRequest("get", `/api/chatbots/get-chatbot-config/${projectId}`);

export const GetChatbotFlow = async (projectId) =>
  apiRequest("get", `/api/chatbots/get-chatbot-flow/${projectId}`);

export const UpdateChatbotFlow = async (projectId, flow) =>
  apiRequest("post", `/api/chatbots/update-chatbot-flow/${projectId}`, flow);

export const GetChatbotPreview = async (projectId) =>
  apiRequest("get", `/api/chatbots/get-chatbot-preview/${projectId}`);
