import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Space,
  Row,
  Col,
  InputNumber,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { setLoading, setButtonLoading } from "../../redux/loadersSlice";
import { useDispatch, useSelector } from "react-redux";
import "./adddeal.css";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { GetProjectById } from "../../apicalls/projects";
import { GetAllPipelines } from "../../apicalls/pipeline";
import { GetAllContacts } from "../../apicalls/contact";
import { GetAllProducts } from "../../apicalls/product";
import { CreateDeal } from "../../apicalls/deal";

const { Option } = Select;

const AddDealModal = ({ showModal, handleClose, reloadData, initialDeal }) => {
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users) || {};
  const [project, setProject] = useState(null);
  const { buttonLoading } = useSelector((state) => state.loaders);
  const [selectedImage, setSelectedImage] = useState(null);
  const [projectPipelines, setProjectPipelines] = useState(null);
  const [pipelineId, setPipelineId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [projectContacts, setProjectContacts] = useState(null);
  const [productId, setProductId] = useState(null);
  const [projectProducts, setProjectProducts] = useState(null);

  useEffect(() => {
    if (initialDeal) {
      setImages(initialDeal.attachments || []);
    }
  }, [initialDeal]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        console.log("Fetching project data for projectId:", projectId);
        const projectResponse = await GetProjectById(projectId);
        console.log("Project response:", projectResponse);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    const fetchPipelines = async () => {
      try {
        dispatch(setLoading(true));
        console.log("Fetching pipelines for project:", project._id);
        const response = await GetAllPipelines({ project: project?._id });
        console.log("Pipelines response:", response);
        if (response.success) {
          setProjectPipelines(response.data);
        } else {
          throw new Error(response.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    if (project) {
      fetchPipelines();
    }
  }, [dispatch, project]);

  useEffect(() => {
    const getContacts = async () => {
      try {
        dispatch(setLoading(true));
        console.log("Fetching contacts for project:", project._id);
        const response = await GetAllContacts({
          project: project?._id,
        });
        console.log("Contacts response:", response);
        dispatch(setLoading(false));
        if (response.success) {
          setProjectContacts(response.data);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };
    if (project) {
      getContacts();
    }
  }, [dispatch, project]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        dispatch(setLoading(true));

        console.log("Fetching products for project:", project._id);
        const response = await GetAllProducts({
          project: project?._id,
        });
        console.log("Products response:", response);

        dispatch(setLoading(false));
        if (response.success) {
          setProjectProducts(response.data);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };
    if (project) {
      getProducts();
    }
  }, [dispatch, project]);

  const onFinish = async (values) => {
    try {
      console.log("Form values:", values);
      let dealId = null;
      if (initialDeal) {
        dealId = initialDeal.id;
      }

      dispatch(setButtonLoading(true));
      const formData = new FormData();

      formData.append("dealName", values.dealName);
      formData.append("paymentType", values.paymentType);
      formData.append("dealPrice", values.dealPrice);
      formData.append("dealStatus", values.dealStatus);
      formData.append("dealDescription", values.dealDescription);
      formData.append("contact", contactId);
      formData.append("pipeline", pipelineId);
      formData.append("product", productId);
      formData.append("project", project._id);
      formData.append("createdBy", user._id);
      formData.append("updatedBy", user._id);
      formData.append("dealId", dealId);

    
      files.forEach((file, index) => {
        formData.append(`files`, file.originFileObj);
      });

      console.log("Sending create deal request with formData:", formData);

      const response = await CreateDeal(formData);
      console.log("Create deal response:", response);
      if (response.success) {
        reloadData();
        form.resetFields(); // Reset the form fields
        toast.success(response.message);
        handleClose();
      } else {
        toast.error(response.message);
      }
      dispatch(setButtonLoading(false));
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  const deleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  return (
    <Modal
      title="Add Deal"
      visible={showModal}
      onCancel={handleClose}
      footer={[
        <Button
          key="back"
          onClick={handleClose}
          className="cancel-button-ath-color"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          className="save-button-ath-color"
          onClick={() => form.submit()}
          loading={buttonLoading}
        >
          {buttonLoading ? "loading" : "Save Changes"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 24 }} // Set labelCol to occupy the full width
      >
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Deal Name</span>}
                name="dealName"
                className="form-col"
                rules={[{ required: true, message: "Please enter deal name!" }]}
              >
                <Input placeholder="Deal Name" style={{ width: "170px" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact"
                name="contact"
                rules={[
                  { required: true, message: "Please select a contact!" },
                ]}
              >
                <Select
                  placeholder="Select contact"
                  onChange={(value) => {
                    setContactId(value);
                    form.setFieldsValue({ contact: value }); // Update form state
                  }}
                  style={{ width: "170px" }}
                >
                  {projectContacts &&
                    projectContacts?.map((contact) => (
                      <Option key={contact._id} value={contact._id}>
                        {`${contact.contactFirstName} ${contact.contactLastName} ${contact.contactEmail}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}> Product</span>}
                name="product"
                className="form-col"
                rules={[
                  { required: true, message: "Please choose a product!" },
                ]}
              >
                <Space wrap>
                  <Select
                    style={{ width: "170px" }}
                    placeholder="Choose Product"
                    onChange={(value) => {
                      setProductId(value);
                      form.setFieldsValue({ product: value }); // Update form state
                    }}
                  >
                    {projectProducts &&
                      projectProducts?.map((product) => (
                        <Option key={product._id} value={product._id}>
                          {product.productName}
                        </Option>
                      ))}
                  </Select>
                </Space>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Price</span>}
                name="dealPrice"
                className="form-col"
              >
                <InputNumber
                  min={0}
                  formatter={(value) =>
                    `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/₦\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Payment Type</span>}
                name="paymentType"
                className="form-col"
                rules={[
                  { required: true, message: "Please select payment type" },
                ]}
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Select payment type"
                >
                  <Option value="full payment">full payment</Option>
                  <Option value="upfront payment">upfront payment</Option>
                  <Option value="monthly payment">monthly payment</Option>
                  <Option value="free">free</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Deal Status</span>}
                name="dealStatus"
                className="form-col"
                rules={[
                  { required: true, message: "Please enter deal status!" },
                ]}
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Select deal status"
                >
                  <Option value="pending">Pending</Option>
                  <Option value="in progress">In progress</Option>
                  <Option value="canceled">Cancel</Option>
                  <Option value="closed">Closed Deal</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Pipeline</span>}
                name="pipeline"
                className="form-col"
                rules={[
                  {
                    required: true,
                    message: "Please select deal pipeline stage",
                  },
                ]}
              >
                <Select
                  placeholder="Select pipeline"
                  style={{ width: "170px" }}
                  onChange={(value) => {
                    setPipelineId(value);
                    form.setFieldsValue({ pipeline: value }); // Update form state
                  }}
                >
                  {projectPipelines && projectPipelines?.map((pipeline) => (
                    <Option key={pipeline._id} value={pipeline._id}>
                      {pipeline.pipelineName} ({pipeline.progressPercentage}%)
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px" }}>Deal Description</span>
                }
                name="dealDescription"
                rules={[
                  {
                    required: true,
                    message: "Please enter deal description",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter your text here" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Images</span>}
              >
                <div>
                  {images.map((image, index) => (
                    <span key={index}>
                      <img
                        src={image}
                        alt="Selected"
                        style={{ maxWidth: "50%", marginTop: "10px" }}
                      />
                      <DeleteOutlined onClick={() => deleteImage(index)} />
                    </span>
                  ))}
                </div>
                <Upload
                  name="files"
                  showUploadList={false}
                  onChange={(info) => {
                    const fileList = info.fileList;
                    setFiles(fileList);
                    setImages(
                      fileList.map((file) =>
                        URL.createObjectURL(file.originFileObj)
                      )
                    );
                  }}
                  multiple={true}
                >
                  <Button icon={<PlusOutlined />} />
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};

export default AddDealModal;
