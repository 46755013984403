import React, { useEffect, useState } from "react";
import Sidebar from "../../components/user/Sidebar";
import {
  Table as AntTable,
  Button,
  Space,
  Modal,
  Select,
  Row,
  Col,
  Popover,
  DatePicker,
} from "antd";
import {
  PlusOutlined,
  EyeOutlined,
  FilterOutlined,
  ExportOutlined,
  ToolOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import AddContactModal from "../../components/addcontact/AddContactModal";
import { NavLink, useParams } from "react-router-dom";
import { setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { GetAllContacts } from "../../apicalls/contact";
import { getDateFormat } from "../../utils/helpers";
import { GetProjectById } from "../../apicalls/projects";
import { useMediaQuery } from "react-responsive";
import "./admin.css";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Contact = ({ contact }) => {
  const [filters, setFilters] = useState({
    createdAt: "all",
    gender: "all",
    assignedTo: "all",
    createdBy: "all",
  });
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { user } = useSelector((state) => state.users) || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project) {
      getContacts();
    }
  }, [project, filters]);

  const getContacts = async () => {
    try {
      dispatch(setLoading(true));
      let filtersToSend = { project: project?._id };

      // Handle filtering logic for createdBy field
      if (filters.createdBy !== "all") {
        filtersToSend.createdBy = filters.createdBy;
      }

      // Convert createdAt filters to JavaScript Date format
      if (filters.createdAt && filters.createdAt !== "all") {
        filtersToSend.createdAt = filters.createdAt.map(
          (date) => new Date(date)
        );
      }

      // Include filters for gender and assignedTo if they are not "all"
      if (filters.gender !== "all") {
        filtersToSend.gender = filters.gender;
      }

      if (filters.assignedTo !== "all") {
        filtersToSend.assignedTo = filters.assignedTo;
      }

      console.log("Fetching contacts with filters:", filters);

      const response = await GetAllContacts({
        project: project?._id,
        filters: filtersToSend,
      });
      dispatch(setLoading(false));
      if (response.success) {
        setContacts(response.data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  const handleAddContact = () => {
    setShowAddContactModal(true);
  };

  // Define breakpoints
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px" };
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",

      render: (text, record) => getDateFormat(text, "datetime"),
    },

    {
      title: "First Name",
      dataIndex: "contactFirstName",

      render: (text, record) => record.contactFirstName,
    },
    {
      title: "Last Name",
      dataIndex: "contactLastName",

      render: (text, record) => record.contactLastName,
    },
    {
      title: "Gender",
      dataIndex: "gender",

      render: (text, record) => {
        return record.gender;
      },
    },
    {
      title: "Owner",
      dataIndex: "createdBy",

      render: (text, record) =>
        record?.createdBy?.firstName + " " + record?.createdBy?.lastName,
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",

      render: (text, record) =>
        record?.assignedTo?.firstName + " " + record?.assignedTo?.lastName,
    },

    {
      title: "Source",
      dataIndex: "contactSource",

      render: (text, record) => record.contactSource,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumbers",

      render: (phoneNumbers, record) => {
        // Extract the first phone number without JSON parsing
        const firstPhoneNumber = phoneNumbers[0]
          ? phoneNumbers[0].replace(/[$$$$"]/g, "").split(",")[0]
          : "";

        return firstPhoneNumber || "";
      },
    },
    {
      title: "Email",
      dataIndex: "contactEmail",

      render: (text, record) => record.contactEmail,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/contact/detail/${projectId}/${record._id}`}>
            <Button type="danger" icon={<EyeOutlined />} />
          </NavLink>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      setFilters({
        ...filters,
        createdAt: dates,
      });
    } else {
      setFilters({
        ...filters,
        createdAt: "all",
      });
    }
  };

  const resetFilters = () => {
    setFilters({
      createdAt: "all",
      createdBy: "all",
      dealStatus: "all",
      paymentType: "all",
    });
  };

  // Function to handle preset date selections
  const handlePresetDate = (preset) => {
    let startDate, endDate;

    switch (preset) {
      case "today":
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      case "yesterday":
        startDate = moment().subtract(1, "day").startOf("day");
        endDate = moment().subtract(1, "day").endOf("day");
        break;
      case "last7days":
        startDate = moment().subtract(6, "days").startOf("day");
        endDate = moment().endOf("day");
        break;
      case "thismonth":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");
        break;
      default:
        return;
    }

    setFilters({
      ...filters,
      createdAt: [startDate, endDate],
    });
  };

  useEffect(() => {
    getContacts();
  }, [filters]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <Sidebar projectId={projectId} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Contact Module</h1>

              <div className="btn-toolbar mb-2 mb-md-0">
                <Popover title="Add Contacts">
                  <Button
                    className="topbar-button-ath-color"
                    style={getButtonStyle()}
                    onClick={handleAddContact}
                    icon={<PlusOutlined />}
                  />
                </Popover>

                <Popover title="Filter Contacts">
                  <Button
                    className="topbar-button-ath-color"
                    style={getButtonStyle()}
                    icon={<FilterOutlined />}
                    onClick={() => setShowFilterModal(true)}
                  />
                </Popover>

                <div className="btn-group me-3">
                  <Popover title="Contact Settings">
                    <Button
                      className="topbar-button-ath-color"
                      style={getAdditionalButtonStyle()}
                      icon={<ToolOutlined />}
                      disabled
                    />
                  </Popover>

                  <Popover title="Import File">
                    <Button
                      className="topbar-button-ath-color"
                      style={getAdditionalButtonStyle()}
                      icon={<ImportOutlined />}
                      disabled
                    />
                  </Popover>

                  <Popover title="Export File">
                    <Button
                      className="topbar-button-ath-color"
                      style={getAdditionalButtonStyle()}
                      icon={<ExportOutlined />}
                      disabled
                    />
                  </Popover>
                </div>
              </div>
            </div>
            <h2 className="mt-5">All Contacts</h2>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Button
                onClick={() => handlePresetDate("today")}
                style={{ marginRight: "10px" }}
              >
                Today
              </Button>
              <Button
                onClick={() => handlePresetDate("yesterday")}
                style={{ marginRight: "10px" }}
              >
                Yesterday
              </Button>
              <Button
                onClick={() => handlePresetDate("last7days")}
                style={{ marginRight: "10px" }}
              >
                Last 7 Days
              </Button>
              <Button onClick={() => handlePresetDate("thismonth")}>
                This Month
              </Button>
            </div>
            <div className="table-responsive">
              <AntTable
                columns={columns}
                project={projectId}
                dataSource={contacts}
                rowSelection={rowSelection}
              />
            </div>
            <AddContactModal
              showModal={showAddContactModal}
              handleClose={() => setShowAddContactModal(false)}
              project={project}
              reloadData={getContacts}
              initailContact={contact}
            />
            <Modal
              title="Filter Options"
              visible={showFilterModal}
              onCancel={() => setShowFilterModal(false)}
              footer={[
                <Button
                  key="submit"
                  className="save-button-ath-color"
                  onClick={() => {
                    setShowFilterModal(false);
                    getContacts();
                  }}
                >
                  Apply
                </Button>,
                <Button
                  className="cancel-button-ath-color"
                  key="cancel"
                  onClick={() => setShowFilterModal(false)}
                >
                  Cancel
                </Button>,
                <Button
                  key="reset"
                  className="reset-button-ath-color"
                  onClick={() => {
                    resetFilters();
                    getContacts();
                  }}
                >
                  Reset
                </Button>,
              ]}
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>
                    <span>Created At</span>
                    <RangePicker
                      style={{ width: "100%" }}
                      onChange={handleDateRangeChange}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment().subtract(1, "day").startOf("day"),
                          moment().subtract(1, "day").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                      }}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <span>Gender</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) => handleFilterChange("gender", value)}
                      value={filters.gender}
                    >
                      <Option value="all">All</Option>
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <span>Created By</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("createdBy", value)
                      }
                      value={filters.createdBy}
                    >
                      <Option value="all">All</Option>
                      {project?.members
                        ?.filter((m) =>
                          [
                            "owner",
                            "admin",
                            "employee",
                            "finance",
                            "credit recorvery",
                            "projects",
                            "logistics",
                            "customer service",
                            "sales",
                            "iot",
                            "software developer",
                            "human reasources",
                            "digital marketing",
                            "engineer",
                            "operations",
                            "visuals",
                            "advertising",
                            "intern",
                            "community manager",
                          ].includes(m.role.toLowerCase())
                        )
                        .map((m) => (
                          <Option key={m.user._id} value={m.user._id}>
                            {m.user.firstName + " " + m.user.lastName}
                          </Option>
                        ))}
                    </Select>
                    {/* {console.log("Assigned By options:", project?.members)} */}
                  </div>
                </Col>

                <Col span={12}>
                  <div className="mt-3">
                    <span>Assigned To</span>
                    <Select
                      defaultValue="all"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleFilterChange("assignedTo", value)
                      }
                      value={filters.assignedTo}
                    >
                      <Option value="all">All</Option>
                      {project?.members
                        ?.filter((m) =>
                          [
                            "owner",
                            "admin",
                            "employee",
                            "finance",
                            "credit recorvery",
                            "projects",
                            "logistics",
                            "customer service",
                            "sales",
                            "iot",
                            "software developer",
                            "human reasources",
                            "digital marketing",
                            "engineer",
                            "operations",
                            "visuals",
                            "advertising",
                            "intern",
                            "community manager",
                          ].includes(m.role.toLowerCase())
                        )
                        .map((m) => (
                          <Option key={m.user._id} value={m.user._id}>
                            {m.user.firstName + " " + m.user.lastName}
                          </Option>
                        ))}
                    </Select>
                    {/* {console.log("Assigned To options:", project?.members)} */}
                  </div>
                </Col>
              </Row>
            </Modal>
          </main>
        </div>
      </div>
    </>
  );
};

export default Contact;
