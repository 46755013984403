import React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { getAntdFormInputRules } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadersSlice";
import { CreateProject } from "../../apicalls/projects";
import toast from "react-hot-toast";

const ProjectForm = React.forwardRef(({ reloadData, project }, ref) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      dispatch(setLoading(true));
      values.owner = user._id;
      values.members = [
        {
          user: user._id,
          role: "owner",
        },
      ];
      const response = await CreateProject(values);
      if (response.success) {
        toast.success(response.message);
        console.log("Project created successfully!");
        reloadData(); 
        form.resetFields();
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Form  form={form} labelCol={{ span: 24 }} onFinish={onFinish} ref={ref} initialValues={project} >
      <Row>
        <Col span={12}>
          <Form.Item
            label={<span style={{ fontSize: "12px" }}>Project Name</span>}
            name="name"
            className="form-col"
            rules={getAntdFormInputRules}
          >
            <Input placeholder="Project name" style={{ width: "170px" }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "12px" }}>Project Description</span>
            }
            name="description"
            rules={getAntdFormInputRules}
          >
            <Input.TextArea rows={4} placeholder="Project brief" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "12px" }}>Owner Phone Number</span>
            }
            name="phone"
            rules={getAntdFormInputRules}
          >
            <Input placeholder="Enter your official phone number" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

export default ProjectForm;
