import { apiRequest } from ".";

export const CreateContact = async (contact) =>
  apiRequest("post", "/api/contacts/create-contact", contact);

export const GetAllContacts = async (filters) =>
  apiRequest("post", "/api/contacts/get-all-contacts", filters);

export const GetContactById = async (contactId) =>
  apiRequest("get", `/api/contacts/get-contact-by-id/${contactId}`);

export const UpdateContact = async (contact) =>
  apiRequest("post", "/api/contacts/update-contact", contact);

export const DeleteContact = async (id) =>
  apiRequest("post", "/api/contacts/delete-contact", { _id: id });
