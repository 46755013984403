import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Upload, Row, Col } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { UpdateTask } from "../../apicalls/task";
import { getAntdFormInputRules } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddNotification } from "../../apicalls/notifications";
import { setButtonLoading, setLoading } from "../../redux/loadersSlice";
import toast from "react-hot-toast";
import "./edittask.css";

const { Option } = Select;

const EditTaskModal = ({
  showModal,
  handleClose,
  reloadData,
  task,
  project,
}) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const { user } = useSelector((state) => state.users);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const { buttonLoading } = useSelector((state) => state.loaders);

  useEffect(() => {
    if (task) {
      // Set initial values of the form fields when task is available
      form.setFieldsValue({
        taskName: task.taskName,
        assignedTo: task.assignedTo,
        status: task.status,
        description: task.description,
      });
      setImages(task.attachments || []); // Display original images
    }
  }, [task, form]);

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));
      const updatedTask = {
        ...task,
        ...values,
        updatedBy: user._id,
      };
      const response = await UpdateTask(updatedTask);
      if (response.success) {
        toast.success(response.message);
        reloadData();
        handleClose(); // Close modal after successful update
      } else {
        throw new Error(response.message);
      }
      dispatch(setButtonLoading(false));
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  const deleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    // Update the files state to exclude the deleted image
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  

  return (
    <Modal
      title="Update Task"
      open={showModal}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
          Cancel
        </Button>,
       <Button
       key="submit"
     className="save-button-ath-color"
       onClick={() => form.submit()}
       loading={buttonLoading}
     >
       {buttonLoading ? "loading" : "Save Changes"}
     </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} labelCol={{ span: 24 }}>
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Task Name</span>}
                name="taskName"
                className="form-col"
                rules={[{ required: true, message: "Please enter task name!" }]}
              >
                <Input placeholder="Task Name" style={{ width: "170px" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Task Status</span>}
                name="status"
                className="form-col"
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Select a task status"
                >
                  <Option value="Pending">Pending</Option>
                  <Option value="Following up">Following up</Option>
                  <Option value="In progress">In progress</Option>
                  <Option value="Task Canceled">Task Cancelled</Option>
                  <Option value="Closed Task">Task Completed</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px" }}>Task Description</span>
                }
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter task description!",
                  },
                  ...getAntdFormInputRules,
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter your text here" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Attachments</span>}
                disabled={!task}
              >
                <div>
                  {images.map((image, index) => (
                    <span key={index}>
                      <img
                        src={image}
                        alt="Selected"
                        style={{ maxWidth: "50%", marginTop: "10px" }}
                      />
                      <DeleteOutlined onClick={() => deleteImage(index)} />
                    </span>
                  ))}
                </div>
                <Upload
                  name="files"
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={(info) => {
                    const fileList = info.fileList;
                    // Concatenate the new images with the existing ones
                    const newImages = fileList.map((file) =>
                      URL.createObjectURL(file.originFileObj)
                    );
                    setImages([...images, ...newImages]);
                    setFiles(fileList); // Update the files state
                  }}
                  multiple={true}
                >
                  <Button icon={<PlusOutlined />} />
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};

export default EditTaskModal;
