import React, { useRef, useState } from "react";
import { setLoading } from "../../redux/loadersSlice";
import {
  DeleteProject,
  EditProject,
  GetAllProjects,
} from "../../apicalls/projects";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Button,
  Space,
  Table as AntTable,
  Popconfirm,
  Modal,
  Popover,
} from "antd";
import { DeleteOutlined, EditOutlined, FilterOutlined, ShareAltOutlined } from "@ant-design/icons";
import { getDateFormat } from "../../utils/helpers";
import ProjectForm from "../../components/user/ProjectForm";
import { useMediaQuery } from "react-responsive";

const ProjectList = () => {
  const { projectId } = useParams();
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [projects, setProjects] = React.useState([]);

  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const formRef = useRef(null);

  const showModal = () => {
    setModalVisible(true);
  };
  // Inside your ProjectList component
  const handleOk = async () => {
    try {
      formRef.current
        .validateFields()
        .then(async (values) => {
          // Update the project with the new values
          const updatedProject = {
            ...selectedProject,
            ...values,
          };

          // Send a request to update the project
          const response = await EditProject(updatedProject);

          if (response.success) {
            toast.success(response.message);
            setModalVisible(false);
            reloadData(); // Reload the project list
          } else {
            throw new Error(response.error);
          }
        })
        .catch((errorInfo) => {
          console.log("Validation failed:", errorInfo);
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const reloadData = () => {
    // Define or import your reloadData function here
    console.log("Reloading data...");
  };

  const getData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await GetAllProjects({ owner: user._id });
      if (response.success) {
        setProjects(response.data);
      } else {
        throw new Error(response.error);
      }
      dispatch(setLoading(false));
    } catch (error) {
      toast.error(error.message);
      dispatch(setLoading(false));
    }
  };

  const clearProjectCreatedStatus = () => {
    localStorage.removeItem("projectCreated");
  };

  const onDelete = async (id) => {
    try {
      dispatch(setLoading(true));
      const response = await DeleteProject(id);
      if (response.success) {
        toast.success(response.message);
        getData();
        navigate("/account");
        clearProjectCreatedStatus(); // Call the function to clear the projectCreated status from local storage
      } else {
        throw new Error(response.error);
      }
      dispatch(setLoading(false));
    } catch (error) {
      toast.error(error.message);
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const columns = [
    { title: "Name", ellipsis: true, dataIndex: "name" },

    { title: "Description", ellipsis: true, dataIndex: "description" },
    {
      title: "Status",
      dataIndex: "status",
      ellipsis: true,
      render: (text) => text.toUpperCase(),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      ellipsis: true,
      render: (text) => getDateFormat(text),
    },
    {
      title: "Delete Project",
      key: "action",
      ellipsis: true,
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Delete project"
            description="Are you sure to delete this project?"
            onConfirm={() => onDelete(record._id)} // Move onDelete function call here
            okText="Yes"
            cancelText="No"
          >
            <Popover title="Delete Project">
              <Button
                type="default"
                style={getAdditionalButtonStyle()}
                danger
                icon={<DeleteOutlined />}
              />
            </Popover>
          </Popconfirm>
        </Space>
      ),
    },

    {
      title: "Edit Project",
      key: "edit",
      ellipsis: true,
      render: (text, record) => (
        <Space>
          <Popover title="Edit Project">
            <Button
              type="default"
              style={getButtonStyle()}
              primary
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedProject(record);
                showModal(true);
              }}
            />
          </Popover>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  // Define breakpoints
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const getButtonStyle = () => {
    if (isMobile) {
      return { marginRight: "8px" };
    } else if (isTabletOrMobile) {
      return { marginRight: "16px" };
    } else {
      return { marginRight: "60px" };
    }
  };

  const getAdditionalButtonStyle = () => {
    if (isMobile) {
      return { marginLeft: "8px" };
    } else if (isTabletOrMobile) {
      return { marginLeft: "16px" };
    } else {
      return { marginLeft: "60px" };
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Projects Page</h1>

        <div className="btn-toolbar mb-2 mb-md-0">
        <Popover title="Filter projects">
            <Button
              type="default"
              style={getButtonStyle()}
              icon={<FilterOutlined />}
            />
          </Popover>
          <div className="btn-group me-3">
          <Popover title="Share Report">
              <Button
                type="default"
                style={getAdditionalButtonStyle()}
                icon={<ShareAltOutlined />}
                disabled
              />
            </Popover>
          </div>
        </div>
      </div>
      <h2 className="mt-5">My Projects</h2>

      <div className="table-responsive">
        <AntTable
          columns={columns}
          dataSource={projects.map((row, index) => ({
            ...row,
            key: index.toString(),
          }))}
          rowSelection={rowSelection}
        />
        {showModal && (
          <Modal
            title="Edit Project"
            open={modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <ProjectForm
              ref={formRef}
              reloadData={reloadData}
              project={selectedProject}
            />
          </Modal>
        )}

        <>
          {projects.length === 0 && (
            <div className="flex text-center">
              <h1 className="text-primary text-xl">You have no projects yet</h1>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default ProjectList;
