import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Upload,
  Typography,
  Space,
  Row,
  Col,
  InputNumber,
  Card,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { setButtonLoading, setLoading } from "../../redux/loadersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetProjectById } from "../../apicalls/projects";
import { GetAllPipelines } from "../../apicalls/pipeline";
import { DeleteDealImage, UpdateDeal } from "../../apicalls/deal";

const { Option } = Select;
const { Text } = Typography;

const EditDealModal = ({ showModal, handleClose, reloadData, deal }) => {
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const { buttonLoading } = useSelector((state) => state.loaders);
  const [project, setProject] = useState(null);
  const [projectPipelines, setProjectPipelines] = useState([]);
  const [pipelineId, setPipelineId] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        dispatch(setLoading(true));
        const projectResponse = await GetProjectById(projectId);
        if (projectResponse.success) {
          setProject(projectResponse.data);
        } else {
          throw new Error(projectResponse.message);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.message);
      }
    };

    fetchProject();
  }, [dispatch, projectId]);

  const fetchPipelines = async () => {
    try {
      if (!project) return;
      dispatch(setLoading(true));
      const response = await GetAllPipelines({ project: project._id });
      if (response.success) {
        setProjectPipelines(response.data);
      } else {
        throw new Error(response.message);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (project) {
      fetchPipelines();
    }
  }, [project]);

  useEffect(() => {
    if (deal) {
      // Set initial values of the form fields when deal is available
      form.setFieldsValue({
        dealName: deal.dealName,
        paymentType: deal.paymentType,
        dealPrice: deal.dealPrice,
        dealStatus: deal.dealStatus,
        dealDescription: deal.dealDescription,
        pipeline: deal.pipeline,
      });
      setImages(deal.images || []);
    }
  }, [deal, form]);

  const onFinish = async (values) => {
    try {
      dispatch(setButtonLoading(true));
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      files.forEach((file) => {
        formData.append("attachments", file);
      });
      const response = await UpdateDeal(deal._id, formData);
      if (response.success) {
        toast.success(response.message);
        reloadData(); // Refresh pipeline data
        handleClose();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(setButtonLoading(false));
    }
  };

  const handleBeforeUpload = (file) => {
    setFiles((prevFiles) => [...prevFiles, file]);
    return false;
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
  };

  const handleDeleteImage = async (index) => {
    const imageToDelete = images[index];
    try {
      await DeleteDealImage(deal._id, imageToDelete);
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
      toast.success("Image deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete image");
    }
  };

  return (
    <Modal
      title="Edit Deal"
      visible={showModal}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose} className="cancel-button-ath-color">
          Cancel
        </Button>,
        <Button
          key="submit"
          className="save-button-ath-color"
          onClick={() => form.submit()}
          loading={buttonLoading}
        >
          {buttonLoading ? "loading" : "Save Changes"}
        </Button>,
      ]}
      width={800}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 24 }} // Set labelCol to occupy the full width
      >
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Deal Name</span>}
                name="dealName"
                className="form-col"
                rules={[{ required: true, message: "Please enter deal name!" }]}
              >
                <Input placeholder="Deal Name" style={{ width: "170px" }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Price</span>}
                name="dealPrice"
                className="form-col"
              >
                <InputNumber
                  min={0}
                  formatter={(value) =>
                    `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/₦\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Payment Type</span>}
                name="paymentType"
                className="form-col"
                rules={[
                  { required: true, message: "Please select payment type" },
                ]}
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Select payment type"
                >
                  <Option value="full payment">full payment</Option>
                  <Option value="upfront payment">upfront payment</Option>
                  <Option value="monthly payment">monthly payment</Option>
                  <Option value="free">free</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Deal Status</span>}
                name="dealStatus"
                className="form-col"
                rules={[
                  { required: true, message: "Please enter deal status!" },
                ]}
              >
                <Select
                  style={{ width: "170px" }}
                  placeholder="Select deal status"
                >
                  <Option value="pending">Pending</Option>
                  <Option value="in progress">In progress</Option>
                  <Option value="canceled">Cancel</Option>
                  <Option value="closed">Closed Deal</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "12px" }}>Pipeline</span>}
                name="pipeline"
                className="form-col"
                rules={[
                  {
                    required: true,
                    message: "Please select deal pipeline stage",
                  },
                ]}
              >
                <Select
                  placeholder="Select pipeline"
                  style={{ width: "170px" }}
                  onChange={(value) => {
                    setPipelineId(value);
                    form.setFieldsValue({ pipeline: value }); // Update form state
                  }}
                >
                  {projectPipelines &&
                    projectPipelines.map((pipeline) => (
                      <Option key={pipeline._id} value={pipeline._id}>
                        {pipeline.pipelineName} ({pipeline.progressPercentage}%)
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px" }}>Deal Description</span>
                }
                name="dealDescription"
                rules={[
                  {
                    required: true,
                    message: "Please enter deal description",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter your text here" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              <Form.Item label="Upload Images">
                <Upload
                  listType="picture-card"
                  fileList={files.map((file) => ({
                    uid: file.uid,
                    name: file.name,
                    status: "done",
                    url: URL.createObjectURL(file),
                  }))}
                  beforeUpload={(file) => {
                    handleBeforeUpload(file);
                    return false;
                  }}
                  onRemove={handleRemove}
                >
                  {files.length >= 8 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Select Files</div>
                    </div>
                  )}
                </Upload>
                {images && images.length > 0 && (
                  <div>
                    <Text>Images</Text>
                    <Space wrap style={{ marginTop: 10 }}>
                      {images.map((image, index) => (
                        <Card
                          key={index}
                          hoverable
                          cover={
                            <img
                              src={image}
                              alt={`image-${index}`}
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                          actions={[
                            <Button
                              danger
                              shape="circle"
                              icon={<DeleteOutlined />}
                              size="small"
                              onClick={() => handleDeleteImage(index)}
                            />,
                          ]}
                          style={{
                            width: 120,
                            height: 120,
                            position: "relative",
                          }}
                        />
                      ))}
                    </Space>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};

export default EditDealModal;
