import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { message, Form, Input, Button, Upload, Avatar } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RegisterUser } from "../../apicalls/users";
import toast from "react-hot-toast";
import "./auth.css";
import { useDispatch, useSelector } from "react-redux";
import { setButtonLoading } from "../../redux/loadersSlice";
import { getAntdFormInputRules } from "../../utils/helpers";

const Register = () => {
  const { buttonLoading } = useSelector((state) => state.loaders);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [avatarFile, setAvatarFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleAvatarChange = ({ fileList }) => {
    const lastFile = fileList[fileList.length - 1];
    setAvatarFile(lastFile.originFileObj);

    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(lastFile.originFileObj);
  };

  const onFinish = async (values) => {
    try {
      if (!avatarFile) {
        toast.error("Avatar is required");
        return;
      }

      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("company", values.company);
      formData.append("avatar", avatarFile);

      dispatch(setButtonLoading(true));
      const response = await RegisterUser(formData);
      dispatch(setButtonLoading(false));

      if (response.success) {
        toast.success(response.message);
        navigate("/login");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setButtonLoading(false));
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="ath-header-text mb-3">
              Team Work
              <br />
              Make The Dream Work <small>✅</small>
            </h1>
            <p className="col-lg-10 ath-desc-text">
              Create Your ATH Account, 
              Build, Connect & Grow 
              <br /> With Your team!
            </p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <Form
              name="register-form"
              className="p-4 p-md-5 border rounded-3 bg-body-transparent bg-transparent shadow"
              onFinish={onFinish}
            >
              <div className="form-floating mb-3">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={getAntdFormInputRules}
                >
                  <Input placeholder="John" />
                </Form.Item>
              </div>

              <div className="form-floating mb-3">
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={getAntdFormInputRules}
                >
                  <Input placeholder="Doe" />
                </Form.Item>
              </div>

              <div className="form-floating mb-3">
                <Form.Item
                  name="company"
                  label="Company"
                  rules={getAntdFormInputRules}
                >
                  <Input placeholder="brand name .CO" />
                </Form.Item>
              </div>

              <div className="form-floating mb-3">
                <Form.Item
                  name="email"
                  label="Email address"
                  rules={getAntdFormInputRules}
                >
                  <Input placeholder="name@example.com" />
                </Form.Item>
              </div>

              <div className="form-floating mb-3">
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={getAntdFormInputRules}
                >
                  <Input placeholder="+234 0000 000 00" />
                </Form.Item>
              </div>

              <div className="form-floating mb-3">
                <Form.Item
                  name="password"
                  label="Password"
                  rules={getAntdFormInputRules}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </div>

              <div className="form-floating mb-3">
                <Form.Item
                  name="avatar"
                  label="Avatar"
                >
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={handleAvatarChange}
                  >
                    {preview ? (
                      <Avatar src={preview} size={100} />
                    ) : (
                      <div>
                        <UploadOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
              </div>

              <Form.Item>
                <div className="d-flex justify-content-center">
                  <Button
                    type="primary"
                    htmlType="submit" 
                    className="btn-lg ath-auth-button shadow"
                    loading={buttonLoading}
                  >
                    {buttonLoading ? "loading" : "Register"}
                  </Button>
                </div>
              </Form.Item>

              <hr className="my-4" />
              <div className="text-center mx-auto">
                <small className="text-body-secondary">
                  By clicking Sign up, you agree to the terms of use.
                </small>
              </div>
              <div className="text-center mx-auto">
                <small className="text-body-secondary">
                  Already registered ?{" "}
                  <span>
                    {" "}
                    <NavLink to="/login">Login</NavLink>{" "}
                  </span>
                </small>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
